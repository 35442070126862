// External Libraries and Packages
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// Internal Styles
// Internal Components
import { getwatchLaterListAction } from "../../Redux/Actions/apiActions";
import LayoutLanguageSwiper from "../../components/ViewAll/Source/LayoutLanguageSwiper";
import PageLoader from "../../components/Others/PageLoader";
import PageError from "../../components/Others/PageError";
import { useTranslation } from "react-i18next";

const Watchlater = ({ settings, isLoading, getwatchlaterData, error, themeMode }) => {
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    headers: { "Access-Control-Allow-Origin": "*" },
    Authorization: "Bearer " + access_token,
  };
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    if (settings && Object.keys(settings)?.length > 0) {
      if (!user_role) {
        try { dispatch(getwatchLaterListAction(null, 9, "web", headers)); }
        catch (error) {
          console.log(error)
        }
      } else {
        try {
          dispatch(getwatchLaterListAction(user_id, user_role, "web", headers));
        }
        catch (error) {
          console.error("Error:", error);
        }
      }
    }

  }, [settings, user_role, user_id, dispatch, location]);


  function capitalizeText(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  return (
    isLoading ? <PageLoader /> : error ? <PageError /> :
      <div className="paddingTop px-md-4 px-2 paddingBottom" style={{ overflowX: "hidden" }}>
        <h2 className="viewAllTitle py-md-4 py-2 text-center mb-0  theme-text-color ">{t(capitalizeText('Watch later'))} </h2>
        {getwatchlaterData?.videos?.length !== 0 && <LayoutLanguageSwiper mapData={getwatchlaterData.videos} heading="Videos" sectionClass="pb-4" path="videos" hoverCard={themeMode?.hoverCard} />}
        {getwatchlaterData?.series?.length !== 0 && <LayoutLanguageSwiper mapData={getwatchlaterData?.series} heading="Series" sectionClass="pb-4" path="shows" hoverCard={themeMode?.hoverCard} />}
        {getwatchlaterData?.live_stream_videos?.length !== 0 && <LayoutLanguageSwiper mapData={getwatchlaterData?.live_stream_videos} heading="Live Streams" sectionClass="pb-4" path="live" hoverCard={themeMode?.hoverCard} />}
        {getwatchlaterData?.Audios?.length !== 0 && <LayoutLanguageSwiper mapData={getwatchlaterData?.Audios} heading="Audios" sectionClass="pb-4" path="audios" hoverCard={themeMode?.hoverCard} />}
      </div>
  )
};

const mapStateToProps = (state) => ({
  getwatchlaterData: state.get_watchlaterList_Reducer.getwatchlaterData,
  isLoading: state.get_watchlaterList_Reducer.isLoading,
  error: state.get_watchlaterList_Reducer.error,
  settings: state.get_setting_Reducer.settings,
  themeMode: state.get_headerIndex_Reducer.themeMode,
});
export default connect(mapStateToProps)(Watchlater);
