import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import { useTranslation } from "react-i18next";

const AddManageVideoCategory = () => {

  const { lang } = useParams();
  const { t } = useTranslation();
  const [home_genre, setHome_genre] = useState("");
  const [in_home, setIn_home] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [parent_id, setParent_id] = useState("");
  const [banner, setBanner] = useState("");

  const [data, setData] = useState([]);

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
  });

  const navigate = useNavigate();

  const access_token_channel = localStorage.getItem("access_token_channel");

  // let access_token, access_token_channel;

  // if (localStorage.getItem("username")) {
  //   access_token = localStorage.getItem("access_token");
  // } else {
  //   access_token_channel = localStorage.getItem("access_token_channel");
  // }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  // if (access_token) {
  //   headers.Authorization = "Bearer " + access_token;
  // } else if (access_token_channel) {
  //   headers.Authorization = "Bearer " + access_token_channel;
  // }

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    // setValidationMessageslug("");
    // setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    setIn_home({ ...in_home, [e.target.name]: e.target.value });
    setIn_menu({ ...in_menu, [e.target.name]: e.target.value });
    setBanner({ ...banner, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setIn_home({ ...in_home, [e.target.name]: 1 });
      setIn_menu({ ...in_menu, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
    } else {
      setIn_home({ ...in_home, [e.target.name]: 0 });
      setIn_menu({ ...in_menu, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
    }
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (setSelectedFile1, setSelectedFile2) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };

  const handleAutoFillChangetitle = (event) => {
    // setValidationMessageslug("");
    // setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const titleInputRef = useRef(null);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function AddManagevideocategories() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const editInputin_home = in_home?.in_home;
    var datain_home = JSON.stringify(editInputin_home);
    var in_homedata = datain_home;

    const editInputin_menu = in_menu?.in_menu;
    var datain_menu = JSON.stringify(editInputin_menu);
    var in_menudata = datain_menu;

    const editInputbanner = banner?.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const formData = new FormData();

    formData.append("name", editUser?.title);
    formData.append("slug", autoFillValuetitle);
    formData.append("home_genre", home_genre);
    formData.append("in_home", in_homedata ? parseInt(in_homedata, 10) : 0);
    formData.append("in_menu", in_menudata ? parseInt(in_menudata, 10) : 0);
    formData.append("parent_id", parent_id ? parseInt(parent_id, 10) : 0);
    formData.append("banner", bannerdata ? parseInt(bannerdata, 10) : 0);


    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-category/store`,
        formData,
        { headers: headers }
      );

      if (response.data.status == true) {
        firstApiStatus = true;

        resultapi = response?.data;
        var VideoCategory_id = response.data.VideoCategory.id;

        const imageFormData = new FormData();
        imageFormData.append("VideoCategory_id", VideoCategory_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("banner_image", selectedFile2);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-category/image`,
          imageFormData,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
      } else if (response.data.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
      }
    } catch (error) { }

    if (firstApiStatus && secondApiStatus) {
      setValidationMessagetitle("");
      // setValidationMessageslug("");
      toast.success(t(resultapi?.message));
      setTimeout(async () => {
        await navigate(`/${lang}/channel/video-categories-list`);
      }, 3000);
    } else {
      toast.error(t(resultapifalse?.message));
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  useEffect(async () => {
    await axios
      .get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-category/create`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        var result = response?.data?.Video_Category;
        setData(result);
      })
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      <div className=" m-0 p-0">
        <section className="container-fluid  theme-bg-color-secondary  ">
          <div className="col-lg-12 row py-3">
            <div className="col-6 col-sm-6 col-lg-6">
              <h3 className=" theme-text-color">Add New Category</h3>
            </div>
            <div className="col-6 col-sm-6 col-lg-6">
              <div className="text-end">
                <button
                  onClick={AddManagevideocategories}
                  className="btn btn-primary"
                >
                  Save Category
                </button>
              </div>
            </div>
          </div>

          <div>
            {showOverlay && (<ResponseLoader />)}
          </div>

          <div className="row col-lg-12 ">
            <div className="col-6 col-sm-6 col-md-6 col-lg-6 ">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Create Category</h5>
                <hr className=" theme-text-color"></hr>
                <div className=" text-start">
                  <label className="m-0 theme-text-color">
                    Name<span className="mandatory">*</span>
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      id="title"
                      name="title"
                      placeholder="Enter Name"
                      onChange={handleInput}
                      value={editUser?.title}
                      onKeyDown={handleInputKeyDowntitle}
                      ref={titleInputRef}
                      onBlur={handleBlurtitle}
                    />
                  </div>
                  <span className="errorred">
                    {!isValidationHiddentitle && (
                      <p>{validationMessagetitle}</p>
                    )}
                  </span>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0 theme-text-color">Slug</label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      id="slug"
                      name="slug"
                      placeholder="Enter Slug"
                      value={autoFillValuetitle}
                      onChange={handleAutoFillChangetitle}
                    />
                  </div>
                </div>
                <div className="mt-2 text-start">
                  <label className="m-0 theme-text-color">
                    Home Page Genre Name
                  </label>

                  <div className="mt-2">
                    <input
                      type="text"
                      className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                      id="home_genre"
                      name="home_genre"
                      onChange={(e) => setHome_genre(e.target.value)}
                      placeholder="Enter Home Page Genre Name"
                    />
                  </div>
                </div>

                <div className="row mt-2 text-start">
                  <div className="col-12">
                    <label className="m-0 theme-text-color">Category</label>

                    <div className="mt-2">
                      <select
                        className="form-select   theme-bg-color-secondary  theme-text-color border-0"
                        name="parent_id"
                        id="parent_id"
                        onChange={(e) => setParent_id(e.target.value)}
                      >
                        <option value="0">Choose an option </option>
                        {data?.map((item) => (
                          <option value={item?.id}>{item?.name}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Status Settings</h5>
                <hr className=" theme-text-color"></hr>

                <div className="col-sm-12 row ">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2 theme-text-color">Display In Home page</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="in_home"
                        onChange={handleInput}
                        className="r-input"
                        defaultChecked={in_home?.in_home == 1 ? true : false}
                        checked={in_home?.in_home == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_home"
                        onChange={handleInput}
                        value={in_home?.in_home == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>

                <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2 theme-text-color">Display In Menu</p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="in_menu"
                        onChange={handleInput}
                        defaultChecked={in_menu?.in_menu == 1 ? true : false}
                        checked={in_menu?.in_menu == 1 ? true : false}
                        className="rs-input"
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="in_menu"
                        onChange={handleInput}
                        value={in_menu?.in_menu == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>

                <div className="col-sm-12 row mt-2">
                  <div className="col-9 col-sm-9 col-lg-9">
                    <p className="p2 theme-text-color">
                      Display In Home Banner
                    </p>
                  </div>
                  <div className="col-3 col-sm-3 col-lg-3">
                    <label className="switch">
                      <input
                        name="banner"
                        id="banner"
                        onChange={handleInput}
                        className="r-input"
                        defaultChecked={banner?.banner == 1 ? true : false}
                        checked={banner?.banner == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="banner"
                        id="banner"
                        onChange={handleInput}
                        value={banner?.banner == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                </div>
              </div>

              <div className="text-start mb-3">
                <button
                  onClick={AddManagevideocategories}
                  className="btn btn-primary"
                >
                  Save Category
                </button>
              </div>
            </div>

            {/* Second Part of Section  */}

            <div className="col-6 col-sm-6 col-md-6 col-lg-6">
              <div className="rounded-3 mb-4 border-0 theme-bg-color  p-3">
                <h5 className=" theme-text-color">Thumbnails</h5>
                <hr className=" theme-text-color"></hr>

                <div className=" text-start mt-2">
                  <label className="m-0 theme-text-color">Image</label>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef1.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef1}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef1,
                                setSelectedFile1
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label className=" theme-text-color">
                            <CameraRetroIcon className=" theme-text-color" />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile1 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile1)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() =>
                                      handleDelete(setSelectedFile1)
                                    }
                                    type="button"
                                    className="btn-close theme-text-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" text-start mt-2">
                  <label className="m-0">Banner</label>

                  <div className="mt-2">
                    <div className="col-lg-12 row">
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div
                          className="imagedrop"
                          onClick={() => fileInputRef2.current.click()}
                        >
                          <input
                            type="file"
                            ref={fileInputRef2}
                            onChange={(event) =>
                              handleFileChange(
                                event,
                                fileInputRef2,
                                setSelectedFile2
                              )
                            }
                            accept="image/*"
                            style={{ display: "none" }}
                          />
                          <label
                            className=" theme-text-color"
                            onClick={() => fileInputRef2.current.click()}
                          >
                            <CameraRetroIcon className=" theme-text-color" />
                          </label>
                        </div>
                      </div>
                      <div className="col-6 col-sm-6 col-lg-6">
                        <div>
                          {selectedFile2 && (
                            <div className="imagedropcopy text-end">
                              <div className="col-lg-12 row p-0 m-0">
                                <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                  <img
                                    src={URL.createObjectURL(selectedFile2)}
                                    alt="Uploaded"
                                    className="thumbnails-upload-images"
                                  />
                                </div>
                                <div
                                  className="col-sm-2 col-lg-2 p-0 m-0"
                                  style={{ alignSelf: "center" }}
                                >
                                  <button
                                    onClick={() =>
                                      handleDelete(setSelectedFile2)
                                    }
                                    type="button"
                                    className="btn-close  theme-text-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                  ></button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default AddManageVideoCategory;
