import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FaEllipsisVertical } from "react-icons/fa6";
import { toast } from "react-toastify";

import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminDeleteModal from "../../../components/Others/adminCommonComponents/AdminDeleteModal";
import AdminNavbar from "../../../components/Others/adminCommonComponents/AdminNavbar";
import NoRecordTable from "../../../components/Others/adminCommonComponents/NoRecordTable";
import { CiShare1 } from "react-icons/ci";
import MessageBox from "../../../components/Others/MessageBox";

function SeriesCategory() {
  const { lang } = useParams();
  const [getSeriesCategory, setSeriesCategory] = useState([]);
  const [itemToDeleteSeriesCategory, setItemToDeleteSeriesCategory] = useState(
    []
  );
  const [selectAllSeriesCategory, setSelectAllSeriesCategory] = useState(false);
  const [modalCloseSeriesCategory, setModalCloseSeriesCategory] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);


  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/List`,
        { headers: headers }
      );
      const seriesCategoryData = response?.data?.SeriesGenre;
      setSeriesCategory(seriesCategoryData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedItems = Array.from(getSeriesCategory);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setSeriesCategory(reorderedItems);
    saveShuffleOrder(reorderedItems);
  };

  const saveShuffleOrder = async (updatedItems) => {
    setProcessing(true);
    setShowOverlay(true);

    if (!updatedItems || updatedItems?.length == 0) {
      console.error("Menu items are empty");
      return;
    }

    const positions = updatedItems.map((menuItem) => menuItem.id);
    const ids = updatedItems.map((_, index) => index + 1);

    const requestData = {
      id: JSON.stringify(positions),
      position: JSON.stringify(ids),
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/series-category/order-series-category`,
        requestData,
        { headers: headers }
      );

      const result = response.data;
      if (result.status == true) {
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  const handleSelectAllSeriesCategory = () => {
    setSelectAllSeriesCategory(!selectAllSeriesCategory);
    setItemToDeleteSeriesCategory(
      selectAllSeriesCategory ? [] : getSeriesCategory.map((item) => item.id)
    );
  };

  const handleMultiSelectSeriesCategory = (itemId) => {
    if (itemToDeleteSeriesCategory.includes(itemId)) {
      setItemToDeleteSeriesCategory(
        itemToDeleteSeriesCategory.filter((id) => id !== itemId)
      );
    } else {
      setItemToDeleteSeriesCategory([...itemToDeleteSeriesCategory, itemId]);
    }
  };

  const singleDeleteSeriesCategory = (id) => {
    setItemToDeleteSeriesCategory([id]);
    setModalCloseSeriesCategory(true);
    setSelectAllSeriesCategory(false);
  };

  const closeSeriesCategoryModal = () => {
    setModalCloseSeriesCategory(!modalCloseSeriesCategory);
  };

  const openModalMultiDeleteSeriesCategory = () => {
    setModalCloseSeriesCategory(true);
  };

  const handleDeleteSeriesCategoryData = () => {
    closeSeriesCategoryModal();
    seriesCategoryDeleteOperation();
  };

  const seriesCategoryDeleteOperation = async () => {
    setProcessing(true);
    setShowOverlay(true);
    const formData = new FormData();
    formData.append("id", "[" + itemToDeleteSeriesCategory + "]");

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/series-category/multi-delete`,
        formData,
        {
          headers: headers,
        }
      );
      const result = response.data;
      if (result.status == true) {
        toast.success(result?.message);
        setProcessing(false);
        setShowOverlay(false);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (err) {
      console.error(err);
      var errorMessage = err.response.data.message;
      toast.error({ status: false, message: errorMessage });
      setProcessing(false);
      setShowOverlay(false);
    }
  };

  return (
    <div className="channel_series_category">
      {showOverlay && <ResponseLoader />}
      {modalCloseSeriesCategory && <AdminDeleteModal modelClose={modalCloseSeriesCategory} deleteClose={closeSeriesCategoryModal} deleteAction={handleDeleteSeriesCategoryData} buttonText={itemToDeleteSeriesCategory?.length} />}
      <AdminNavbar heading="All series genre" totalDataLength={getSeriesCategory?.length} deleteCount={itemToDeleteSeriesCategory?.length} navigation={`/channel/add-series-category`} deleteMethod={openModalMultiDeleteSeriesCategory} navText="Add Series Genre" />
      <div className="bootstrapTable theme-bg-color p-3 rounded-2">
        <div className="">
          <div className="row m-0 theme-border-bottom-secondary pb-3">
            <div className="rowCheckboxDrag"> <label><input type="checkbox" checked={selectAllSeriesCategory} onChange={handleSelectAllSeriesCategory} /> </label></div>
            <div className="col-lg-4 col-md-4 col-sm-4 col-4 data"> <label className="theme-text-color fw-bold fs-6"> Category </label> </div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 data"><label className="theme-text-color fw-bold fs-6">Uploaded by</label></div>
            <div className="col-lg-3 col-md-3 col-sm-3 col-3 data"><label className="theme-text-color fw-bold fs-6"> Actions </label> </div>
          </div>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="menu-items" className="theme-bg-color"  >
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef} className="pb-5 px-0 theme-bg-color"   >
                  <NoRecordTable result={getSeriesCategory?.length} loader={loading} />

                  {getSeriesCategory?.map((item, index) => (
                    <Draggable
                      key={item?.id.toString()}
                      draggableId={item?.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={`theme-bg-color  orderborder ${item.order
                            } index ${index + 1} w-100 theme-border-bottom-secondary`}
                        >
                          <div className="row m-0 p-0 align-items-center">
                            <div className="rowCheckboxDrag  category-order-title">
                              <input
                                type="checkbox"
                                checked={itemToDeleteSeriesCategory.includes(
                                  item.id
                                )}
                                onChange={() =>
                                  handleMultiSelectSeriesCategory(
                                    item.id
                                  )
                                }
                              />
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-4 col-4  category-order-title">
                              <div className="d-flex justify-content-between align-items-center ">
                                <img src={item.image_url} className="profile" alt="image_all" />
                                <div className="rightVideoTitle">
                                  <Link className="theme-text-color table-heading d-flex  align-items-center" target="_blank" to={`/shows/${item?.slug}`}> <span title={`${item.slug}`}>{`${item?.name.slice(0, 15)}...`} </span><CiShare1 style={{ width: "15px", height: "15px", strokeWidth: '1px', marginLeft: "6px" }} /> </Link>
                                  <span className="dateText table-subheading "> {item?.upload_on}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 category-order-title">
                              <div className=" theme-text-color">{item?.uploaded_by == "1" ? "Admin" : item?.uploaded_by == "5" ? "Content Partner" : item?.uploaded_by == "4" ? "Channel Partner" : ""} </div>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-3 col-3 category-order-title">
                              <div className="editdropdown">
                                <span className="editdropdown-button">
                                  <span>
                                    <FaEllipsisVertical className="theme-text-color" />
                                  </span>
                                </span>
                                <div className="editdropdown-menu theme-bg-color">
                                  <Link to={`/${lang}/channel/edit-series-category/${item?.id}`} className="theme-text-color  theme-bg-color">
                                    <span>
                                      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                        <g>
                                          <path class="svgIcon" d="M10.3,18.5l-4.74.64A.49.49,0,0,1,5,18.59l.64-4.74a.42.42,0,0,1,.14-.28L17.31,2a.59.59,0,0,1,.85,0L22.11,6a.6.6,0,0,1,0,.84L10.58,18.36A.53.53,0,0,1,10.3,18.5Z" />
                                          <line class="svgIcon" x1="1.01" y1="21.58" x2="18.89" y2="21.58" />
                                        </g>
                                      </svg>
                                      <MessageBox text="Edit" classname='ms-2 theme-text-color'></MessageBox>
                                    </span>
                                  </Link>
                                  <div
                                    onClick={() => singleDeleteSeriesCategory(item?.id)}
                                    className="commonActionPadding theme-bg-color"
                                  >
                                    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                      <g>
                                        <rect class="svgIcon" x="4.38" y="5.54" width="14.49" height="17.71" />
                                        <line class="svgIcon" x1="1.16" y1="5.54" x2="22.09" y2="5.54" />
                                        <g>
                                          <line class="svgIcon" x1="9.21" y1="9.56" x2="9.21" y2="19.06" />
                                          <line class="svgIcon" x1="14.04" y1="9.56" x2="14.04" y2="19.06" />
                                        </g>
                                        <path class="svgIcon" d="M7.45,5.22a2.72,2.72,0,0,1,0-.28,4.19,4.19,0,1,1,8.37,0c0,.09,0,.19,0,.29" />
                                      </g>
                                    </svg>
                                    <MessageBox text="Delete" classname='ms-2 theme-text-color'></MessageBox>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {/* {provided.placeholder} */}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </div>
  );
}

export default SeriesCategory;
