import React from 'react'
import { connect } from 'react-redux';
import MusicFormatViewAll from './MusicFormatViewAll';
import { useParams } from 'react-router-dom';
import EmptyLoaderStatic from '../../CommonComponents/EmptyLoaderStatic';
import Error404 from '../../../../Error404/Error404';
import { URLDashRemover } from '../../../CommonMethods/CommonMethods';
import Result from '../../../Result';

const MusicViewAllIndex = ({ getSource, isLoading }) => {
    const { source } = useParams();
    return (
        <div className='px-sm-2 px-0 px-md-4' >
            {isLoading ? <EmptyLoaderStatic />
                : getSource.includes(source == "Audio_Genre" ? "audio-genre" : source) ? (source == "latest-audios" || source == "audio-genre" || source == "audio-albums" || source == "artists" || source == "musician" || source == "song-writer" || source == "playlist" || source == "music-station") ? <MusicFormatViewAll />
                    : <Result text={`Not Available! ${URLDashRemover(source)} `} /> : <Error404 />}

        </div>
    )
}
const mapStateToProps = state => ({
    getSource: state.get_Music_Home_Page_Reducer.getSource,
    isLoading: state.get_Music_Home_Page_Reducer.isLoading,
});

export default connect(mapStateToProps)(MusicViewAllIndex)