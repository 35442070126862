import "./footer.css";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { footerAction } from "../../Redux/Actions/apiActions.js";
import { Link, NavLink, useLocation } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import appstore from "../../components/Images/appstore.png";
import playstore from "../../components/Images/playstore.png";
import { useTranslation } from "react-i18next";
import LazyLoadImage from "../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage.js";

const Footer = ({ themeMode, footerlink, sociallink, companyname, isLoading, activeyear, error, props, languageDetailsData }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    dispatch(footerAction());
  }, []);
  if (isLoading) {
    return <div className="text-white text-center fs-2">Loading...</div>;
  }

  return (
    <>
      <section className="container-fluid py-4 px-3  footerContainer theme-button-top-border mt-4">
        <div className="d-flex flex-wrap mb-3">
          <div className="col-lg-3 col-sm-6 col-md-4 col-12 ">
            <Link to="/" className="py-1 position-relative homeNavbarBrand d-block" style={{ width: "171px", height: "42px", objectFit: "contain" }}>
              <LazyLoadImage src={themeMode?.logoUrl} alt="header-logo" imageSize="loaderMain object-fit-contain " classNameOptional="rounded-2" />
            </Link>
          </div>
          <div className="col-lg-3 col-sm-6 col-md-4 col-12">
            <p className="footerHeading mt-3 mt-md-0 theme-text-color" >{t('Services')}</p>
            {footerlink
              .filter((item) => item?.column_position == 2)
              .map((item) => (
                <NavLink
                  key={item?.order}
                  to={`/${languageDetailsData.Language_Code}${item?.link}`}
                  className={`footerLinks theme-text-color order-${item?.order} d-block`}
                >
                  {t(item?.name)}
                </NavLink>
              ))}
          </div>
          <div className="col-lg-3 col-sm-6 col-md-4 col-12">
            <p className="footerHeading mt-3 mt-md-0 theme-text-color">{t('About')}</p>
            {footerlink
              .filter((item) => item.column_position == 3)
              .map((item) => (
                <NavLink
                  key={item.order}
                  to={`/${languageDetailsData.Language_Code}${item?.link}`}
                  className={`footerLinks theme-text-color order-${item.order} d-block`}
                >
                  {t(item.name)}
                </NavLink>
              ))}
          </div>
          <div className="col-lg-3 col-sm-6 col-md-12 col-12">
            {/* <p className="footerHeading text-md-center text-lg-start mt-3 mt-lg-0 theme-text-color">
              {t('Download App')}
            </p>
            <div className="d-flex align-items-center gap-4 my-3 flex-wrap justify-content-center justify-content-lg-start ">
              <Link to='https://play.google.com/store/apps/details?id=com.webnexs.flicknexs' target="_blank"><img
                src={playstore}
                className="footerAppDownload"
                width="244"
                height="75"
                alt="playStore"
              /></Link>
              <Link to='https://play.google.com/store/apps/details?id=com.webnexs.flicknexs' target="_blank"><img
                src={appstore}
                className="footerAppDownload"
                width="223"
                height="75"
                alt="appStore"
              />
              </Link>
            </div> */}
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-center p-3">


          <Link to={sociallink?.facebook_url} target="_blank"> <FaFacebookF className="footerSocialLink theme-text-color" /></Link>
          <Link to={sociallink?.twitter_url} target="_blank"><FaXTwitter className="footerSocialLink theme-text-color" /></Link>
          <Link to={sociallink?.instagram_url} target="_blank"><FaInstagram className="footerSocialLink theme-text-color" /></Link>
          <Link to={sociallink?.linkedin_url} target="_blank"><FaLinkedinIn className="footerSocialLink theme-text-color" /></Link>
        </div>
        <p className="copyRight text-center theme-text-color">

          {`${t('Copyright')} ${activeyear} ${t('Networks Pvt.Ltd')}.`}
        </p>

      </section>
    </>
  );
};
const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  settings: state.get_setting_Reducer.settings,
  themeMode: state.get_headerIndex_Reducer.themeMode,
  footerlink: state.get_Footer_Reducer.footerlink,
  isLoading: state.get_Footer_Reducer.isLoading,
  sociallink: state.get_Footer_Reducer.sociallink,
  companyname: state.get_Footer_Reducer.companyname,
  activeyear: state.get_Footer_Reducer.activeyear,
  error: state.get_Footer_Reducer.error,
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData

});
export default connect(mapStateToProps)(Footer);
