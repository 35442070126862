import React, { useEffect, useState } from 'react'
import { MdClose } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { connect, useDispatch } from 'react-redux';
import { musicAddPlaylist, musicPlaylistImageApi, musicPlaylistPageList, musicPlaylistStore } from '../../components/Api/MusicApi';
import axios from 'axios';
import ButtonWithLoader from './CommonComponents/ButtonWithLoader';
import { toast } from 'react-toastify';
import { CiImageOn } from "react-icons/ci";
import MessageBox from '../../components/Others/MessageBox';
import { useTranslation } from 'react-i18next';
import { MusicViewAllPageAction } from '../../Redux/Actions/MusicAction';
import { getItem, getItemToken } from '../../Utils/localStorageUtils';

const PlaylistModal = ({ createModal, currentAudio, playlistMusicId }) => {
    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessTokeOnly = getItem('access_token')
    const userAccessToken = getItemToken('access_token');

    const { t } = useTranslation();
    const [playlistData, setPlaylistData] = useState({
        playlistName: null,
        playlistImage: null,
        playlistStore: [],
        createResponse: null,
        playlistMoveName: null,
    })
    const [formDataError, setFormDataError] = useState({})
    const [loading, setLoading] = useState(false)
    const handleInput = (e) => {
        const { name, value } = e.target;
        setPlaylistData((prev) => ({
            ...prev,
            [name]: value
        }))
        setFormDataError((prevFormData) => ({
            ...prevFormData,
            [name]: "",
        }));
    }

    const handleFile = (event) => {
        const selectedFile = event.target.files;
        if (selectedFile.length > 0) {
            const newFile = selectedFile[0];
            const reader = new FileReader();
            reader.onload = () => {
                setPlaylistData((prev) => ({
                    ...prev,
                    playlistImage: newFile
                }))
            };
            reader.readAsDataURL(newFile);
        } else {
            return null
        }
        setFormDataError((prevFormData) => ({
            ...prevFormData,
            playlistImage: "",
        }));
    }
    const handleImageUpload = async (id) => {
        try {
            const formData = new FormData();
            formData.append("Playlist_id", id);
            formData.append("image", playlistData?.playlistImage);
            const response = await axios.post(`${musicPlaylistImageApi}`, formData, { headers: userAccessToken });
            if (!response?.data?.status) {
                toast.error(t(response?.data?.message))
            }
        } catch (err) {
            console.error(err)
        }
    }

    const createPlaylist = async () => {
        setLoading(true);
        try {
            const formDataAdd = new FormData();
            formDataAdd.append("Login_user_type", userRoleLocal || 9)
            formDataAdd.append("Login_user_id", userIdLocal || "")
            formDataAdd.append("Login_device_type", "web");
            formDataAdd.append("source_type", 5);
            formDataAdd.append("title", playlistData?.playlistName);

            const response = await axios.post(`${musicPlaylistStore}`, formDataAdd, { headers: userAccessToken });
            const result = response?.data;

            if (result?.status) {
                if (playlistData?.playlistImage) {
                    await handleImageUpload(result?.Playlist_id)
                }
                setPlaylistData((prev) => ({
                    ...prev,
                    createResponse: response?.data,
                    playlistImage: null,
                    playlistName: '',
                }))
                setLoading(false);
                toast.success(t(result?.message))
                dispatch({ type: "OPEN_CREATE_MODAL", payload: false })
            }
            else {
                setLoading(false);
                toast.error(t(result?.message))
            }
        }
        catch (err) {
            setLoading(false);
            const errorMessage = err?.response?.data?.message
            toast.error(t(errorMessage))
            console.error("error", err)
        }
    }

    const addAudioPlaylist = async () => {
        setLoading(true);
        try {
            const formDataAdd = new FormData();
            formDataAdd.append("Login_user_type", userRoleLocal || 9)
            formDataAdd.append("Login_user_id", userIdLocal || null)
            formDataAdd.append("Login_device_type", "web");
            formDataAdd.append("source_type", 5);
            formDataAdd.append("source_id", playlistMusicId !== null ? playlistMusicId : currentAudio ? currentAudio?.id : 0);
            formDataAdd.append("playlist_id", playlistData?.playlistMoveName);

            const response = await axios.post(`${musicAddPlaylist}`, formDataAdd, { headers: userAccessToken });
            const result = response?.data;
            if (result?.status) {
                setLoading(false);
                dispatch({ type: "OPEN_INITIAL_MODAL", payload: false })
                toast.success(t(result?.message))
            }
            else {
                setLoading(false);
                toast.error(t(result?.message))
            }
        }
        catch (err) {
            setLoading(false);
            const errorMessage = err?.response?.data?.message
            toast.error(t(errorMessage))
            console.error("error", err)
        }
    }

    const handleStore = () => {
        if (formValidation()) {
            createPlaylist();
        } else {
            console.log("Form validation failed ");
        }
    }

    const handleAudioToPlayListStore = () => {
        let formIsValid = true;
        if (!playlistData?.playlistMoveName) {
            setFormDataError((prevFormData) => ({
                ...prevFormData,
                playlistMoveName: "Please choose the playlist name",
            }));
            formIsValid = false;
        }
        if (formIsValid) {
            addAudioPlaylist()
        }
        else {
            console.log("Form validation failed ");
        }
    }

    const formValidation = () => {
        let formIsValid = true;
        if (!playlistData?.playlistName) {
            setFormDataError((prevFormData) => ({
                ...prevFormData,
                playlistName: "Please give the playlist name",
            }));
            formIsValid = false;
        }
        if (!playlistData?.playlistImage) {
            setFormDataError((prevFormData) => ({
                ...prevFormData,
                playlistImage: "Please give the playlist image",
            }));
            formIsValid = false;
        }

        return formIsValid;
    }


    const dispatch = useDispatch()
    const closeModal = () => {
        dispatch({ type: "OPEN_INITIAL_MODAL", payload: false })
        dispatch({ type: "OPEN_CREATE_MODAL", payload: false })
    }
    const createPlaylistModal = () => {
        dispatch({ type: "OPEN_CREATE_MODAL", payload: !createModal })
    }

    useEffect(() => {
        const PlaylistGet = async () => {
            try {
                const formDataAdd = new FormData();
                formDataAdd.append("Login_user_type", userRoleLocal || 9)
                formDataAdd.append("Login_user_id", userIdLocal || null)
                formDataAdd.append("Login_device_type", "web");
                const response = await axios.post(`${musicPlaylistPageList}`, formDataAdd, { headers: userAccessToken });
                const result = response?.data?.data;
                setPlaylistData((prev) => ({
                    ...prev,
                    playlistStore: result
                }))
                if (response?.data?.status && response?.data?.data?.length <= 0) {
                    dispatch({ type: "OPEN_CREATE_MODAL", payload: true })
                }
            }
            catch (err) {
                console.error("error", err)
            }
        }
        PlaylistGet()
        if (playlistData?.createResponse !== null) {
            dispatch(MusicViewAllPageAction("PLAYLIST", musicPlaylistPageList, "playlist"))
        }
    }, [playlistData?.createResponse])


    return (
        <div className='playlistModal w-100 h-100 d-flex align-items-center justify-content-center position-fixed'>
            <div className='theme-bg-color playlistWrapper'>
                <div className='d-flex align-items-center justify-content-between'>
                    <MessageBox classname="p-0" text={createModal ? "Create Playlist" : "Choose Playlist"} />
                    <button className='bg-transparent p-0 theme-text-color' type='button' onClick={closeModal}><MdClose className='playlistIcon' /></button>
                </div>
                {createModal ?
                    <>
                        <input type="text" name="playlistName" value={playlistData?.playlistName} onChange={handleInput} placeholder={t("Playlist name")} className="rounded-2 playlistInput w-100 theme-border-body-color  border-0 theme-bg-color-secondary theme-text-color  my-3" />
                        <MessageBox classname='text-danger-custom' text={formDataError?.playlistName} />
                        <div className='position-relative musicDropZone  my-3 rounded-2 theme-border-color'>
                            <input type="file" onChange={handleFile} className="rounded-2 h-100 opacity-0 playlistInput w-100 theme-border-body-color border-0 theme-bg-color theme-text-color " accept="image/*" />
                            <CiImageOn className='musicDropZoneIcon' />
                        </div>
                        <MessageBox classname='theme-text-color fileNameSize' text={playlistData?.playlistImage?.name} />
                        <MessageBox classname='text-danger-custom' text={formDataError?.playlistImage} />
                    </>
                    :
                    <> <select className="playlistInput w-100 mt-3 rounded-2 mb-4 theme-border-body-color theme-bg-color-secondary theme-text-color border-0" onChange={handleInput} name="playlistMoveName" >
                        <option value="0" className="theme-bg-color theme-text-color" >{t("Choose an playlist name")}</option>
                        {playlistData?.playlistStore?.map((data, index) => { return <option value={data?.id} className="theme-bg-color-secondary theme-text-color">{data?.title}</option> })}
                    </select>
                        <MessageBox classname='text-danger-custom' text={formDataError?.playlistMoveName} />
                    </>}
                <div className='d-flex align-items-center justify-content-between'>
                    <button className='bg-transparent p-0 theme-text-color' type='button' onClick={createPlaylistModal}>  {createModal ? playlistData?.playlistStore?.length > 0 && <><IoIosArrowBack /><MessageBox text="Go back" /> </> : <><MessageBox text="Create" /><IoIosArrowForward style={{ marginLeft: "-4px" }} /></>} </button>
                    <div className='d-flex align-itepms-center gap-2'>
                        <button className='bg-transparent py-2 rounded-2 theme-text-color ' type='button' onClick={closeModal} ><MessageBox text="Close" /></button>
                        {createModal ? <ButtonWithLoader text="Upload" loading={loading} action={handleStore} /> : <ButtonWithLoader text="Add" loading={loading} action={handleAudioToPlayListStore} />}
                    </div>
                </div>

            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    createModal: state.get_Playlist_Modal.createModal,
    currentAudio: state.get_current_Reducers.currentAudio,
    playlistMusicId: state.get_current_Reducers.playlistMusicId
});
export default connect(mapStateToProps)(PlaylistModal)

