// External Libraries and Packages
import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { BiError } from 'react-icons/bi';
// Internal Styles
// Internal Components
import { seriesDetailsAction } from '../../../Redux/Actions/apiActions';
import Error404 from '../../../Error404/Error404';
import CommonViewDetail from '../../Others/CommonViewDetail';
import EpisodeDetails from './EpisodeDetails';
import LoaderPage from '../../../Layout/LoaderPage';
import outlineImage from "../../Images/Rectangl.png"
import HelmetDetails from '../../Others/HelmetDetails';

const SeriesDetails = ({ seriesDetailsData, isLoading, error, settings }) => {
  const { slug } = useParams()
  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");

  const dispatch = useDispatch();
  const [domReload, setDomReload] = useState(false)
  const currentPageURL = window.location.href


  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free === 1
        ? dispatch(seriesDetailsAction(null, 9, "web", slug))
        : user_role && dispatch(seriesDetailsAction(user_id, user_role, "web", slug));
    }
  }, [settings, user_role, domReload, dispatch, slug, user_id]);


  const handleDOM = () => {
    setDomReload(!domReload)
  }


  return (
    <div className='paddingTopone'>
      {error ? <Error404 /> : isLoading ? <LoaderPage /> :
        seriesDetailsData !== null ?
          <>
            <HelmetDetails metaURL={currentPageURL} metaTitle={seriesDetailsData?.title ? seriesDetailsData.title?.toUpperCase() : seriesDetailsData?.name?.toUpperCase()} metaDescription={seriesDetailsData?.description?.toString().substring(0, 100)} metaImage={seriesDetailsData?.Player_thumbnail} pageTitle={seriesDetailsData?.title ? seriesDetailsData.title?.toUpperCase() : seriesDetailsData?.name?.toUpperCase()} />
            <CommonViewDetail data={seriesDetailsData} navigationName="Series" categoryUrl="/series-categories" playerUrl="series" reloadAction={handleDOM} codeNumber="3" />
            <img src={outlineImage} alt={outlineImage} className="w-100" />
            <EpisodeDetails seriesDetailsData={seriesDetailsData} />
          </> :
          <div className="py-5 text-center"><BiError style={{ fontSize: "50px" }} /><h3> NO DATA FOUND</h3></div>
      }
    </div>
  )
}

const mapStateToProps = state => ({
  seriesDetailsData: state.get_seriesDetails_Reducer.seriesDetailsData,
  isLoading: state.get_seriesDetails_Reducer.isLoading,
  error: state.get_seriesDetails_Reducer.error,
  settings: state.get_setting_Reducer.settings
});
export default connect(mapStateToProps)(SeriesDetails)
