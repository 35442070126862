import React, { useState, useEffect } from 'react';
import HelmetDetails from '../Others/HelmetDetails';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { videoDetailsAction } from '../../Redux/Actions/apiActions';
import { useTranslation } from 'react-i18next';
import { FaArrowLeft } from "react-icons/fa";
import LazyLoadImage from '../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage';
import axios from 'axios';
import usePpvGlobalImageConfig from "../Api/Rentppv_global_change";
import { toast } from "react-toastify";

function PurchaseModal({ videoDetailsData, settings, profileDetails }) {
  const { lang, slug } = useParams();
  const user_role = localStorage.getItem("role");
  const username = localStorage.getItem("username");
  const user_id = localStorage.getItem("user_id");
  const access_token = localStorage.getItem("access_token");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    Accept: "application/json",
  };
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [domReload, setDomReload] = useState(false)
  const currentPageURL = window.location.href;
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (settings && Object.keys(settings).length > 0) {
      !user_role && settings.access_free == 1
        ? dispatch(videoDetailsAction(null, 9, "web", slug, headers))
        : user_role && dispatch(videoDetailsAction(user_id, user_role, "web", slug, headers));
    }
  }, [settings, user_role, domReload, location])

  // console.log(videoDetailsData, "video")
  const closeButton = () => {
    navigate(`/${lang}/videos/${slug}`);
  }




  //   geo ip location integration 
  //   geo ip location integration 
  //   geo ip location integration 
  const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
  const [convertedPrice, setConvertedPrice] = useState(null);

  useEffect(() => {
    const fetchConversionRate = async () => {
      if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
        const data = response.data;
        const conversionRate = data.rates[userCurrency];
        if (conversionRate) {
          const convertedPPVPrice = videoDetailsData?.ppv_price * conversionRate;
          setConvertedPrice(convertedPPVPrice);
        } else {
          console.error('Conversion rate not found for currency:', userCurrency);
        }
      } catch (error) {
        console.error('Error fetching conversion rate:', error);
      } finally {
      }
    };

    fetchConversionRate();
  }, [userCurrency, currency?.code, currency?.enable_multi_currency, videoDetailsData?.ppv_price]);

  //   geo ip location integration 
  //   geo ip location integration 

  //    Iyzico payment integration 
  //    Iyzico payment integration 
  const successpage = window.location.href;
  const ppv_priceppv_price = videoDetailsData?.ppv_price
  const [formDataiyzicopayment, setFormDataiyzicopayment] = useState({
    locale: '',
    conversationId: '',
    price: ppv_priceppv_price, // Initialize with an empty value
    paidPrice: ppv_priceppv_price, // Initialize with an empty value
    successpage: successpage, // Initialize with an empty value
    currency: '',
    basketId: '',
    paymentGroup: 'PRODUCT',
    callbackUrl: '',
    enabledInstallments: [2, 3, 6, 9],
    buyer: {
      id: '',
      name: '',
      surname: '',
      gsmNumber: '',
      email: '',
      identityNumber: '',
      lastLoginDate: '',
      registrationDate: '',
      registrationAddress: '',
      ip: '',
      city: '',
      country: '',
      zipCode: ''
    },
    shippingAddress: {
      contactName: 'Jane Doe',
      city: 'Istanbul',
      country: 'Turkey',
      address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
      zipCode: '34742'
    },
    billingAddress: {
      contactName: 'Jane Doe',
      city: 'Istanbul',
      country: 'Turkey',
      address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
      zipCode: '34742'
    },
    basketItems: [
      {
        id: 'BI101',
        name: 'Binocular',
        category1: 'Collectibles',
        category2: 'Accessories',
        itemType: 'PHYSICAL',
        price: ppv_priceppv_price,
      }
    ]
  });


  useEffect(() => {
    let value;
    if (currency?.code === null || currency?.code === "") {
      value = videoDetailsData?.ppv_price !== null ? videoDetailsData?.ppv_price : '';
    } else {
      if (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0) {
        value = convertedPrice !== null ? convertedPrice : '';
      } else {
        value = videoDetailsData?.ContentPriceRate !== null
          ? videoDetailsData?.ContentPriceRate?.ChangedPrice
          : convertedPrice !== null
            ? convertedPrice
            : '';
      }
    }

    const getCurrentDateTime = () => {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const seconds = String(now.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const currentDateTime = getCurrentDateTime();
    const locale = lang === "tr" ? "tr" : "en";
    const conversationId = user_id;
    const currencyfinal = currency?.code;
    const basketId = profileDetails?.Profile_details?.id;
    const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
    // const successpage = window.location.href;
    const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failerpage";
    const successpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
    const pageredirectionslug = `${process.env.REACT_APP_API_PATHss}/` + lang + "/" + "videos" + "/" + videoDetailsData?.slug;
    const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
    const usernames = profileDetails?.Profile_details?.username;
    const last_name = profileDetails?.Profile_details?.last_name;
    const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
    const user_ids = user_id;
    const emailemail = profileDetails?.Profile_details?.email;
    const city = locationResponselocationResponse?.city;
    const country = locationResponselocationResponse?.country;
    const zipCode = locationResponselocationResponse?.asn;
    const ip = locationResponselocationResponse?.ip;
    const basketItemsname = videoDetailsData?.title || videoDetailsData?.name;
    setFormDataiyzicopayment(prevState => ({
      ...prevState,
      locale: locale,
      price: ppv_priceppv_price,
      conversationId: conversationId,
      Login_user_id: user_id,
      Login_device_type: "web",
      source_type: 1,
      source_id: videoDetailsData?.id,
      regionname: country,
      countryname: country,
      cityname: city,
      successpage: successpage,
      pageredirectionslug: pageredirectionslug,
      failerpage: failerpage,
      paidPrice: ppv_priceppv_price,
      currency: currencyfinal,
      basketId: basketId,
      callbackUrl: callbackUrl,
      buyer: {
        id: user_ids,
        name: usernames,
        surname: last_name,
        gsmNumber: payment_mobile_number,
        email: emailemail,
        identityNumber: gsmNumber,
        lastLoginDate: currentDateTime,
        registrationDate: currentDateTime,
        registrationAddress: country,
        ip: ip,
        city: city,
        country: country,
        zipCode: zipCode
      },
      shippingAddress: {
        contactName: usernames,
        city: city,
        country: country,
        district: city,
        address: country,
        zipCode: zipCode
      },
      billingAddress: {
        contactName: usernames,
        city: city,
        country: country,
        address: country,
        zipCode: zipCode
      },
      basketItems: [
        {
          id: user_ids,
          name: basketItemsname,
          category1: 'Videos',
          itemType: 'PHYSICAL',
          price: ppv_priceppv_price
        }
      ]
    }));



  }, [videoDetailsData, currency, settings, convertedPrice]);

  const initializeCheckout = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, formDataiyzicopayment);
      if (response.data.status === "success") {
        const paymentPageUrl = response?.data?.paymentPageUrl;
        window.location.href = paymentPageUrl;
      }
      else if (response.data.status === "failure") {
        toast.error(`${response.data.errorMessage}`)
      }
    } catch (error) {
      console.error('Error initializing checkout:', error);
    }
  };


  // useEffect(() => {
  //   let showAlert = true;

  //   const retrievePaymentDetails = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/retrieve-payment-details`);

  //       if (response.data.status === 'success' && showAlert) {
  //         showAlert = false;

  //         const result = response.data;
  //         const user_id = localStorage.getItem("user_id");
  //         const videoDetailsDataslug = videoDetailsData?.slug;
  //         const videoDetailsDataid = videoDetailsData?.id;

  //         await axios.post('http://localhost:7001/payment/details', {
  //           ...result,
  //           user_id,
  //           videoDetailsDataslug,
  //           videoDetailsDataid,
  //         });
  //       }
  //     } catch (error) {
  //       console.error('Error retrieving payment details:', error);
  //     }
  //   };

  //   retrievePaymentDetails();
  // }, []);



  const [paymentGateways, setPaymentGateways] = useState([]);

  const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null

  const fetchPaymentGateways = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
        { headers: headers }
      );
      const paymentGateways = response?.data?.Payment_Gateways;
      setPaymentGateways(paymentGateways);
      // Set the active gateway to the first one by default
      setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGatewayClick = (gatewayName) => {
    setActiveGateway(gatewayName);
  };


  useEffect(() => {
    // fetchOptions();
    fetchPaymentGateways();
  }, []);


  const staticContent = {
    Stripe: (
      <>
        <div>
          <button className='btn w-100'>Stripe Pay to the {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</button>
        </div>
      </>
    ),
    PayPal: (
      <>
        <div>
          <button className='btn w-100'>PayPal Pay to the {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</button>
        </div>
      </>
    ),
    Razorpay: (
      <>
        <div>
          <button className='btn w-100'>Razorpay Pay to the {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</button>
        </div>
      </>
    ),
    Iyzico: (
      <>
        <div>
          <button className='btn w-100' onClick={() => initializeCheckout()}>Iyzico Pay to the {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</button>
        </div>
      </>
    ),
  };


  //    Iyzico payment integration 
  //    Iyzico payment integration 
  return (
    <>

      {/* { PPV Payment Modal} */}
      {/* { PPV Payment Modal} */}
      <div className="modal fade " id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content theme-bg-color">
            <div className="modal-header">
              <h5 className="modal-title theme-text-color" id="staticBackdropLabel">Upgrade to <span>{videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</span> video pack by just paying the difference </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body rent_video_page_ul_li">
              <div className="row">
                {/* <div className="col-3 col-md-3 col-lg-3"></div> */}

                <div className='row col-lg-12'>
                  <div className='col-lg-5 col-md-5 col-sm-5 col-5'>
                    <img alt={videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name} src={videoDetailsData?.Thumbnail} className='w-100 h-100' />
                  </div>
                  <div className='col-lg-7 col-md-7 col-sm-7 col-7 rounded-3 border-3'>
                    <div className='p-2'>
                      <p className='fw-bold fs-5  theme-text-color'>BUY {videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name}</p>

                      <hr></hr>

                      <div className='mt-4'>
                        <div>
                          <img alt={videoDetailsData?.title ? videoDetailsData.title : videoDetailsData?.name} src={videoDetailsData?.Player_thumbnail} className='w-50' />
                        </div>
                        <div className='mt-3'>
                          <ul>
                            <li className=' theme-text-color'>
                              Available to watch for <span className='fw-bold'>30 Days</span> after renting
                            </li>
                            <li className=' theme-text-color'>
                              Finish within <span className='fw-bold'>48 Hours</span> once started streaming
                            </li>
                          </ul>
                        </div>
                      </div>

                      <hr></hr>

                      <div className='mt-2'>
                        <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
                          {paymentGateways?.map((gateway, index) => (
                            <li
                              className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                              role="presentation"
                              key={index}
                            >
                              <input
                                type="radio"
                                className="nav-link"
                                id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                                name="tab-radio"
                                value={gateway?.Payment_Gateway_name}
                                checked={activeGateway == gateway?.Payment_Gateway_name}
                                onChange={() =>
                                  handleGatewayClick(gateway?.Payment_Gateway_name)
                                }
                              />
                              <label
                                className="ms-2 theme-text-color"
                                htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                              >
                                {gateway?.Payment_Gateway_name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div className='mt-2'>
                        <div className="row">
                          <div className="col-12 col-md-12 col-lg-12 offset-md-0">
                            <div className="tab-content text-start" id="pills-tabContent">
                              {paymentGateways?.map((gateway, index) => (
                                <div
                                  className={`tab-pane theme-text-color ${activeGateway == gateway?.Payment_Gateway_name
                                    ? "show active"
                                    : "fade"
                                    }`}
                                  id={`pills-${gateway?.Payment_Gateway_name}`}
                                  role="tabpanel"
                                  key={index}
                                >
                                  <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* { PPV Payment Modal} */}
      {/* { PPV Payment Modal} */}


      <div className='paddingTopone mt-3'>
        <HelmetDetails metaURL={currentPageURL} metaTitle={videoDetailsData?.title ? videoDetailsData.title?.toUpperCase() : videoDetailsData?.name?.toUpperCase()} metaDescription={videoDetailsData?.description?.toString().substring(0, 100)} metaImage={videoDetailsData?.Player_thumbnail} pageTitle={videoDetailsData?.title ? videoDetailsData.title?.toUpperCase() : videoDetailsData?.name?.toUpperCase()} />
        <div style={{
          backgroundImage: `url(${videoDetailsData?.Player_thumbnail})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover', width: '100%', height: '100%',
        }} className='px-md-5 px-4 py-md-3 py-1 linearShadow'>
          <div className='position-relative'>
            <div className='pageWrapper2'>

              <div className="row w-100">

                <div className="col-sm-4 col-lg-4 col-md-4 d-flex justify-content-around">
                  <div>
                    <button className='bg-transparent' onClick={closeButton}><FaArrowLeft style={{ "fontSize": "25px" }} className='m-3' /></button>
                  </div>
                  <LazyLoadImage
                    imageSize="loaderMain object-fit-cover" classNameOptional="rounded-3 w-100 "
                    loading="lazy"
                    src={videoDetailsData?.Player_thumbnail} alt={videoDetailsData?.slug}
                  />
                </div>
                <div className="col-sm-8 col-lg-4 col-md-8">
                  <div>
                    <h3 className="midHeadingText mb-2 text-break">{t(videoDetailsData?.title)} {t(videoDetailsData?.name)} </h3>
                  </div>
                  <div className="py-3">
                    {/* <h5 className='midHeadingMessage'>
                    {t(videoDetailsData?.users_video_visibility_status_message)}</h5> */}
                    <h5 className='midHeadingMessage'>
                      {t(videoDetailsData?.users_free_duration_status_message)}</h5>
                  </div>
                  <button className="theme-button-bg-color px-4 py-3 rounded-2 d-inline-block" data-bs-toggle="modal" data-bs-target="#staticBackdrop" type='button'>
                    <span className='midHeadingbtn'>
                      {currency?.code === null || currency?.code === ""
                        ? `Rent Now ${videoDetailsData?.ppv_price !== null ? videoDetailsData?.ppv_price : 'Loading...'}`
                        : (settings?.PPV_Individual_Contents === "0" || settings?.PPV_Individual_Contents === 0
                          ? `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`
                          : (videoDetailsData?.ContentPriceRate !== null
                            ? `Rent Now ${videoDetailsData?.ContentPriceRate?.Symbol} ${videoDetailsData?.ContentPriceRate?.ChangedPrice}`
                            : `Rent Now ${currencySymbol} ${convertedPrice !== null ? convertedPrice : 'Loading...'}`
                          )
                        )}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
const mapStateToProps = state => ({
  videoDetailsData: state.get_videoDetails_Reducer.videoDetailsData,
  isLoading: state.get_videoDetails_Reducer.isLoading,
  error: state.get_videoDetails_Reducer.error,
  settings: state.get_setting_Reducer.settings,
  profileDetails: state.get_profileDetails_Reducer.profileDetails,
});
export default connect(mapStateToProps)(PurchaseModal)