// External Libraries and Packages
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Internal Styles
// Internal Components
import { viewAllPageCategoryIdAction } from "../../Redux/Actions/apiActions";
import PageLoader from "../Others/PageLoader";
import LayoutViewAll from "./Source/LayoutViewAll";
import { useTranslation } from "react-i18next";
import Error404 from "../../Error404/Error404";

const CategoryBasedVideoViewAll = ({ viewallPageCatId, isLoading, error, settings }) => {
  const { slug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const user_role = localStorage.getItem("role");
  const user_id = localStorage.getItem("user_id");

  const [videoCategoriesData, setVideoCategoriesData] = useState([]);

  useEffect(() => {
    const requestApi = `${process.env.REACT_APP_Baseurl}/Front-End/videoCategories-Page-List`;
    const VideoBasedApi = `${process.env.REACT_APP_Baseurl}/Front-End/videos-based-Categories-Page-List`;
    if (settings && Object.keys(settings)?.length > 0) {
      dispatch(viewAllPageCategoryIdAction(slug, VideoBasedApi));
    }
  }, [settings, user_role, dispatch, user_id, location]);

  useEffect(() => {
    setVideoCategoriesData(
      (viewallPageCatId?.data || []).flatMap(
        (item) => item.category_videos || []
      )
    );
  }, [viewallPageCatId]);

  function capitalizeText(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
  return isLoading ? <PageLoader /> : error ? <Error404 /> : <div className="paddingTop">
    <LayoutViewAll mapData={videoCategoriesData} source={`${t('Video Category')} ${t(capitalizeText(slug))}`} path='videos' headingClass='pb-4 text-center' filterSettings={true} />
  </div>
};
const mapStateToProps = (state) => ({
  viewallPageCatId: state.get_viewAll_CatId_Reducer.viewallPageCatId,
  isLoading: state.get_viewAll_CatId_Reducer.isLoading,
  error: state.get_viewAll_CatId_Reducer.error,
  settings: state.get_setting_Reducer.settings,
});

export default connect(mapStateToProps)(CategoryBasedVideoViewAll);
