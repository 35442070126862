// External Libraries and Packages
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// Internal Styles
// Internal Components


import { getWishListAction } from "../../../Redux/Actions/apiActions";
import PageLoader from "../../Others/PageLoader";
import PageError from "../../Others/PageError";
import LayoutViewAll from "./LayoutViewAll";


const WishListViewall = ({ settings, isLoading, getWishListData, error }) => {
    const user_role = localStorage.getItem("role");
    const user_id = localStorage.getItem("user_id");
    const access_token = localStorage.getItem("access_token");
    const { type } = useParams();
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        const headers = {
            "Content-Type": "application/json",
            headers: { "Access-Control-Allow-Origin": "*" },
            Authorization: "Bearer " + access_token,
        };

        if (settings && Object.keys(settings)?.length > 0) {
            if (!user_role) {
                try {
                    dispatch(getWishListAction(null, 9, "web", headers));
                } catch (error) {
                    console.log(error);
                }
            } else {
                try {
                    dispatch(getWishListAction(user_id, user_role, "web", headers));
                } catch (error) {
                    console.error("Error:", error);
                }
            }
        }
    }, [settings, user_role, user_id, dispatch, access_token, location]);  // Notice 'access_token' is now in the dependency array



    return (
        isLoading ? <PageLoader /> : error ? <PageError /> :
            <div className="paddingTop" style={{ overflowX: "hidden" }}>
                {type === "videos" && getWishListData?.videos.length !== 0 && (
                    <LayoutViewAll mapData={getWishListData?.videos} path={type} source="Videos" headingClass='pb-4 text-center' />
                )}
                {type === "shows" && getWishListData?.series.length !== 0 && (
                    <LayoutViewAll mapData={getWishListData?.series} path={type} source="Series" headingClass='pb-4 text-center' />
                )}
                {type === "live" && getWishListData?.live_stream_videos.length !== 0 && (
                    <LayoutViewAll mapData={getWishListData?.Livestream} path={type} source="Lives" headingClass='pb-4 text-center' />
                )}
                {type === "music" && getWishListData?.Audios.length !== 0 && (
                    <LayoutViewAll mapData={getWishListData?.Audios} path={type} source="Audios" headingClass='pb-4 text-center' />
                )}
            </div>

    )
};

const mapStateToProps = (state) => ({
    getWishListData: state.get_wishList_Reducer.getWishListData,
    isLoading: state.get_wishList_Reducer.isLoading,
    error: state.get_wishList_Reducer.error,
    settings: state.get_setting_Reducer.settings,
});
export default connect(mapStateToProps)(WishListViewall);