import { connect, useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify"
// Internal Styles
import "../../Pages/Audio/musicPlayer/musicPlayer.css";
import "../../Pages/Audio/musicPlayer/progressBar.css";
// Internal Components
import defaultImage from '../../Images/default-music.png'
import ToolTip from "../../components/Others/ToolTip";
import { Link, useNavigate, useParams } from "react-router-dom";
import MiniPrograssBar from "./MiniPrograssBar";
import { LikeAction, disLikeAction, wishListAction } from "../CommonMethods/CommonMethods";
import { musicDetailsUpdate } from "../../Redux/Actions/MusicAction";
import MessageBox from "../../components/Others/MessageBox";
import LazyLoadImage from "../HomePage/HomeSwiperComponents/LazyLoadImage";
import { musicViewsCount } from "../../components/Api/MusicApi";
import { getItem, getItemToken } from "../../Utils/localStorageUtils";
import axios from "axios";
import { useMyContext } from "../../components/Layout/CommonProvider";
import { useTranslation } from "react-i18next";
import { IconMyAddPlaylist, IconMyDisLike, IconMyDisLikeFill, IconMyHeart, IconMyHeartFill, IconMyLike, IconMyLikeFill, IconMyLoop, IconMyMic, IconMyMini, IconMyPause, IconMyPlay, IconMyPlayPrev, IconMyShuffle, IconMyVolumn, IconMyVolumnMute } from "../../Images/MyIcons";

const MusicMiniTrack = ({ playlistAudio, currentAudio, currentToggle, currentPlaying, currentIndex, currentRepeat, getMusicData, toggleAction, playlistShow, homepage, currentUserInteract, languageDetailsData }) => {

  const { shufflePlaylist, handlePlayPause, handleVolume, handlePlayNext, handlePlayPrev, handleSeek, handleEnableLoop, minimizePlayer, prograssBarPercentage, duration, currentTime, readyState, volume } = useMyContext();
  const { t } = useTranslation();
  const userRoleLocal = getItem('role');
  const userIdLocal = getItem('user_id');
  const userAccessToken = getItemToken('access_token');

  const { lang } = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [audioCalled, setAudioCalled] = useState(false);


  const formatTime = (time) => {
    const minutes = Math.floor(Number(time) / 60);
    const seconds = Math.floor(Number(time) % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleClickWishList = async () => {
    try {
      await wishListAction(null, null, null, currentAudio?.id, 5, null);
      await dispatch(musicDetailsUpdate('AUDIO', currentUserInteract?.slug))
    } catch (error) {
      console.error(error);
    }
  }

  const handleLike = async () => {
    if (currentAudio?.id) {
      try {
        const likeStatus = currentUserInteract?.like ? 0 : 1;
        await LikeAction(userIdLocal || null, userRoleLocal || 9, 'web', currentUserInteract?.id, 5, likeStatus, userAccessToken || null);
        await dispatch(musicDetailsUpdate('AUDIO', currentUserInteract?.slug))
      } catch (error) {
        console.error(error);
      }
    }
    else {
      toast.info(t("Please select the audio"));
    }
  };

  const handleDisLike = async () => {
    if (currentAudio?.id) {
      try {
        const likeStatus = currentUserInteract?.like ? 1 : 0;
        await disLikeAction(userIdLocal || null, userRoleLocal || 9, 'web', currentUserInteract?.id, 5, likeStatus, userAccessToken || null);
        await dispatch(musicDetailsUpdate('AUDIO', currentUserInteract?.slug))

      } catch (error) {
        console.error(error);
      }
    }
    else {
      toast.info(t("Please select the audio"));
    }

  };

  const handleLyrics = () => {
    dispatch({
      type: "GET_TOGGLE_SUCCESS",
      toggle: !currentToggle,
    });
    if (currentToggle && window.location.pathname !== `${languageDetailsData?.Language_Code}/music`) {
      navigate(-1);
    } else {
      navigate(`/${languageDetailsData?.Language_Code}/music/lyrics`);
    }
  };

  const handlePlaylistModal = () => {
    if (currentAudio?.id) {
      dispatch({ type: "OPEN_INITIAL_MODAL", payload: true })
      dispatch({ type: "GET_PLAYLIST_STORE_ID", payload: null })
    }
    else {
      toast.info(t(`Please log in after adding audio to the playlist.`));
    }
  };


  useEffect(() => {
    const roundedTime = Math.floor(currentTime);
    if (roundedTime >= 30000) {
      setAudioCalled(true);
    }
    else {
      setAudioCalled(false);
    }
    if (roundedTime >= 30000 && !audioCalled) {
      const data = {
        Login_user_type: userRoleLocal || 9,
        Login_device_type: "web",
        Login_user_id: userIdLocal || null,
        source_type: 5,
        source_id: currentAudio?.id
      };
      axios.post(musicViewsCount, data)
        .then(response => {
          console.log('API call success:', response);
          setAudioCalled(true);
        })
        .catch(error => {
          console.error('API call error:', error);
        });
    } else if (roundedTime < 30000) {
      console.log("error audio content");
    }
  }, [currentTime, audioCalled]);


  return (
    <div className="p-2 mini-wrapper  theme-bg-color-secondary ">
      <div className="music-player-mini theme-bg-color w-100 rounded-3 overflow-hidden " style={{ backgroundImage: `url(${currentAudio?.Player_thumbnail})` }}>
        <div className="row m-0 p-3 zIndexOne position-relative  justify-content-between h-100">
          <div className="d-flex align-items-center p-0 trackStop">
            {getMusicData?.enable_track_image ? <div className="col-2 ps-0">
              <LazyLoadImage src={currentAudio?.image_url ? currentAudio?.image_url : currentAudio?.Thumbnail ? currentAudio?.Thumbnail : defaultImage} alt={currentAudio?.title} title={currentAudio?.title?.slice(0, 1)} imageSize="rounded-2 overflow-hidden miniPlayerImage object-fit-cover" classNameOptional="rounded-2 miniPlayerImage" />
            </div> : null}
            {getMusicData?.enable_track_title || getMusicData?.enable_track_artists ? <div className={`col-9 ps-0 overflow-hidden`}>
              {getMusicData?.enable_track_title ? <Link to={`/${lang}/music/track/${currentAudio?.slug}`}><MessageBox classname="miniPlayerHead theme-text-color" text={currentAudio?.title && currentAudio?.title} /></Link> : null}
              {getMusicData?.enable_track_artists ? <div className="miniPlayerText" >{currentAudio?.artists && currentAudio?.artists.map((item, index) => <><MessageBox text={item.name} classname='audioLink theme-text-color audioListNameTrack pe-1' />{index + 1 !== currentAudio?.artists?.length ? "," : ""} </>)} </div> : null}
            </div> : null}
            {getMusicData?.enable_volume ? <div className={`${getMusicData?.enable_track_image ? 'col-1' : getMusicData?.enable_track_title || getMusicData?.enable_track_artists ? 'col-3' : 'col-12'}  ps-0 d-flex justify-content-end align-self-start`}>
              <button className="playButton toolTipParent bg-transparent p-0 theme-text-color" onClick={handleVolume} >
                {volume ? <IconMyVolumn styled={{ width: "18px", height: "18px", className: "" }} />
                  : <IconMyVolumnMute styled={{ width: "18px", height: "18px", className: "" }} />}
                <ToolTip bgColor="#000" text={volume == 0 ? 'Muted' : 'Un muted'} top="-6px" left="-95px" className="px-3 py-2 rounded-2 position-absolute d-none" />
              </button>
            </div> : null}
          </div>
          <div className="col-12 pt-3 px-0">
            <div className="d-flex align-items-center justify-content-between">
              <span className="audioTime theme-text-color text-start" >{formatTime(currentTime / 1000)} </span>
              <span className="audioTime theme-text-color text-end">{formatTime(duration)}</span>
            </div>
            <MiniPrograssBar handleTime={handleSeek} progressValue={prograssBarPercentage} duration={duration} readyState={readyState} />
          </div>
          {currentAudio?.mp3_url &&
            <div className="col-12 p-0">
              <div className="d-flex align-items-center justify-content-between mt-3">
                <div>
                  <button className={`${currentIndex == 0 ? 'opacity-75' : ''} bg-transparent theme-text-color playButton  playerPrev  p-0 ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handlePlayPrev} disabled={currentIndex == 0 || !getMusicData?.enable_track_controls} >
                    <IconMyPlayPrev styled={{ width: "18px", height: "18px", className: "" }} />
                  </button>
                  {!currentPlaying ? (
                    <button className="theme-button-bg-color playbtnembed p-0 mx-2 rounded-circle bg-transparent" onClick={handlePlayPause}><IconMyPlay styled={{ width: "15px", height: "15px", className: "" }} /></button>
                  ) : (
                    <button className="theme-button-bg-color playbtnembed p-0 mx-2 rounded-circle bg-transparent" onClick={handlePlayPause}><IconMyPause styled={{ width: "15px", height: "15px", className: "" }} /></button>
                  )}
                  <button className={`${currentIndex + 1 >= playlistAudio?.length ? 'opacity-75' : ''} bg-transparent  playerPrev theme-text-color playButton p-0`} onClick={handlePlayNext} disabled={currentIndex + 1 >= playlistAudio?.length || !getMusicData?.enable_track_controls}>
                    <IconMyPlayPrev styled={{ width: "18px", height: "18px", className: " rotate " }} />
                  </button>
                </div>
                <div className={` d-sm-block d-none theme-text-color p-0 ${currentUserInteract ? "" : 'opacity-75'}`}>
                  <button type="button" onClick={handleClickWishList} className="bg-transparent theme-text-color">
                    {currentUserInteract?.wishlist ? <IconMyHeartFill styled={{ width: "16px", height: "16px", className: "musicSideBarIcons" }} /> : <IconMyHeart styled={{ width: "16px", height: "16px", className: "musicSideBarIcons" }} />}
                  </button>
                </div>

              </div>
            </div>}


          <div className="col-12 p-0">
            <div className="d-flex align-items-center justify-content-between mt-4" >

              <div className="d-flex align-items-center gap-3">

                {getMusicData?.enable_shuffle ? <button className={`playButton p-0 toolTipParent bg-transparent position-relative  `} onClick={shufflePlaylist} >
                  <IconMyShuffle styled={{ width: "18px", height: "18px", className: "theme-text-color" }} />
                  <ToolTip bgColor="#000" text="Shuffle" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
                </button> : null}

                {getMusicData?.enable_loop ? <button className={`bg-transparent theme-text-color playButton  p-0 toolTipParent ${currentRepeat == "enabled" || currentRepeat == "currentloop" ? "yes " : "no"} ${currentRepeat == "currentloop" && 'one'}  ${currentAudio?.id ? "" : 'opacity-75'} d-none d-md-block`} onClick={handleEnableLoop}>
                  <IconMyLoop styled={{ width: "18px", height: "18px", className: "" }} />
                  <ToolTip bgColor="#000" text="Loop" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
                </button> : null}

                <button type="button" className={`bg-transparent theme-text-color playButton p-0 toolTipParent  ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handlePlaylistModal}>
                  <IconMyAddPlaylist styled={{ width: "18px", height: "18px", className: "" }} />
                  <ToolTip bgColor="#000" text="Playlist" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
                </button>
              </div>

              <div className="d-flex align-items-center gap-3">
                <button className={`bg-transparent theme-text-color  playButton border-0 p-0  toolTipParent ${currentToggle ? "yes " : "no"}  ${currentAudio?.id ? "" : 'opacity-75'}`} onClick={handleLyrics} >
                  <IconMyMic styled={{ width: "18px", height: "18px", className: "" }} className={`likeIcon`} />
                  <ToolTip bgColor="#000" text="Lyrics" top="-43px" left="-14px" className="px-3 py-2 rounded-2 position-absolute d-none" />
                </button>
                <button type="button" className={`playButton bg-transparent  d-none d-md-block theme-text-color p-0  toolTipParent ${currentUserInteract?.id ? '' : 'opacity-75'}  `} onClick={handleLike}>
                  {currentUserInteract?.like !== 1 ? <IconMyLike styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />
                    : <IconMyLikeFill styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />}
                </button>

                <button type="button" className={` bg-transparent theme-text-color playButton d-none d-md-block p-0   toolTipParent  ${currentUserInteract?.id ? "" : 'opacity-75'}`} onClick={handleDisLike}>
                  {currentUserInteract?.dislike !== 1 ? <IconMyDisLike styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />
                    : <IconMyDisLikeFill styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} />}
                </button>

                {getMusicData?.enable_minimize_button ? <button type="button" className={`bg-transparent theme-text-color playButton  p-0 toolTipParent`} onClick={() => minimizePlayer()}>
                  <IconMyMini styled={{ width: "15px", height: "15px", className: "" }} />
                </button> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  playlistAudio: state.get_playlist_Reducer.playlistAudio,
  error: state.get_playlist_Reducer.error,
  currentIndex: state.get_current_Reducers.currentIndex,
  currentAudio: state.get_current_Reducers.currentAudio,
  currentTimes: state.get_current_Reducers.currentTime,
  currentToggle: state.get_current_Reducers.currentToggle,
  currentPlaying: state.get_current_Reducers.currentPlaying,
  currentRepeat: state.get_current_Reducers.currentRepeat,
  currentUserInteract: state.get_current_Reducers.currentUserInteract,
  getMusicData: state.get_Music_Settings.getMusicData,
  playlistShow: state.get_Playlist_Modal.playlistShow,
  homepage: state.get_Playlist_Modal.homepage,
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
});
export default connect(mapStateToProps)(MusicMiniTrack)