import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import "./MyChannelSettings.css";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";

import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import loadings from "../../../components/Images/loading/loading.gif";
import Dropzone from "react-dropzone";
import { VscEyeClosed } from "react-icons/vsc";
import { RxEyeOpen } from "react-icons/rx";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import FormContainer from "../../CommonComponents/FormContainer";
import LeftWrapper from "../../CommonComponents/LeftWrapper";
import RightWrapper from "../../CommonComponents/RightWrapper";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageHeading from "../../CommonComponents/MessageHeading";
import MessageLabel from "../../CommonComponents/MessageLabel";
import MessageBox from "../../../components/Others/MessageBox";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import AdminCustomButton from "../../../components/Others/adminCommonComponents/AdminCustomButton";
import { IoClose } from "react-icons/io5";
import deleteitem from "../../../components/Images/deleteitem.svg";
import { useTranslation } from "react-i18next";

const MyChannelSettings = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState([]);
  const navigate = useNavigate();
  const Channel_Partner_id = localStorage.getItem("Channel_Partner_id");
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [editUser, setEditUser] = useState({
    email: "",
    password: "",
    channel_name: "",
    channel_slug: "",
    ccode: "",
    mobile_number: "",
    channel_about: "",
    activation_code: "",
    bank_name: "",
    branch_name: "",
    account_number: "",
    IFSC_Code: "",
    cancelled_cheque: "",
    upi_id: "",
    upi_mobile_number: "",
    channel_image: "",
    channel_banner: "",
    status: "",
    channel_logo_url: "",
    channel_banner_url: "",
    channel_image_url: "",
    intro_video_url: "",
    cancelled_cheque_url: "",
  });

  console.log(editUser.intro_video_url, "editUsereditUser")
  useEffect(async () => {
    await axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/About`, {
        headers: headers,
      })
      .then((response) => {
        var result = response?.data?.users_list;
        var resultssetEditUser = response?.data?.Channel;
        setEditUser(resultssetEditUser);
        var results = response?.data?.country_code;
        setNumber(results);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  const getFirstNElements = (arr, n) => {
    return arr.slice(0, n);
  };
  const [data, setData] = useState([]);
  const firstNElements = getFirstNElements(data, 3);
  const [autoFillValuechannel_name, setAutoFillValuechannel_name] =
    useState("");
  const handleInputKeyDownchannel_name = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.channel_name);
    }
  };
  const handleBlurchannel_name = () => {
    generateSlug(editUser?.channel_name);
  };

  const handleAutoFillChangechannel_name = (event) => {
    setAutoFillValuechannel_name(event.target.value);
  };

  const generateSlug = (channel_name) => {
    channel_name = channel_name.trim();
    const slug = channel_name.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuechannel_name(slug);
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);

  const handleFileChange = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
  };

  const handleChangeFirstchannel_about = (e) => {
    setEditUser({ ...editUser, channel_about: e });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageemail("");
    setIsValidationHiddenemail(true);
    setValidationMessagechannel_name("");
    setIsValidationHiddenchannel_name(true);
    setValidationMessagepassword("");
    setIsValidationHiddenpassword(true);
    setValidationMessagemobile_number("");
    setIsValidationHiddenmobile_number(true);
    setValidationMessageccode("");
    setIsValidationHiddenccode(true);
    setValidationMessagerole("");
    setIsValidationHiddenrole(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };

  const [videosintro, setVideosintro] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);

  const handleDropintro = (acceptedFiles) => {
    setVideosintro(acceptedFiles);
  };

  const [validationMessagechannel_name, setValidationMessagechannel_name] =
    useState("");
  const [isValidationHiddenchannel_name, setIsValidationHiddenchannel_name] =
    useState(true);

  const [validationMessageemail, setValidationMessageemail] = useState("");
  const [isValidationHiddenemail, setIsValidationHiddenemail] = useState(true);
  const [focusInputRef, setFocusInputRef] = useState(null); // Initialize focusInputRef to null

  const [validationMessagemobile_number, setValidationMessagemobile_number] =
    useState("");
  const [isValidationHiddenmobile_number, setIsValidationHiddenmobile_number] =
    useState(true);

  const [validationMessageccode, setValidationMessageccode] = useState("");
  const [isValidationHiddenccode, setIsValidationHiddenccode] = useState(true);

  const [validationMessagepassword, setValidationMessagepassword] =
    useState("");
  const [isValidationHiddenpassword, setIsValidationHiddenpassword] =
    useState(true);
  const [validationMessagerole, setValidationMessagerole] = useState("");
  const [isValidationHiddenrole, setIsValidationHiddenrole] = useState(true);

  const channel_nameInputRef = useRef(null);
  const emailInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const mobile_numberInputRef = useRef(null);
  const ccodeInputRef = useRef(null);
  const roleInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function saveUser() {
    setValidationMessageemail("");
    setIsValidationHiddenemail(true);

    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    let focusInputRef = null;

    if (editUser?.channel_name == "") {
      setValidationMessagechannel_name("Name cannot be empty.");
      setIsValidationHiddenchannel_name(false);
      focusInputRef = channel_nameInputRef;
    }

    if (editUser?.ccode == "") {
      setValidationMessageccode("");
      setIsValidationHiddenccode(false);
      focusInputRef = ccodeInputRef;
    }
    if (editUser?.mobile_number == "") {
      setValidationMessagemobile_number(
        "Code and Mobile Number cannot be empty."
      );
      setIsValidationHiddenmobile_number(false);
      focusInputRef = mobile_numberInputRef;
    }
    if (!editUser?.email || !emailPattern.test(editUser?.email)) {
      setValidationMessageemail("Invalid email format.");
      setIsValidationHiddenemail(false);
      emailInputRef.current.focus();
      return;
    }
    if (editUser?.role == "") {
      setValidationMessagerole("User Role cannot be empty.");
      setIsValidationHiddenrole(false);
      focusInputRef = roleInputRef;
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const formData = new FormData();

    formData.append("Channel_Partner_id", Channel_Partner_id);
    formData.append("channel_name", editUser?.channel_name);
    formData.append("channel_slug", editUser?.channel_slug);
    formData.append("email", editUser?.email);
    formData.append("ccode", editUser?.ccode);
    formData.append("password", editUser?.password || "");
    formData.append("mobile_number", editUser?.mobile_number);
    formData.append("channel_about", editUser?.channel_about);
    const enableactivation_codedata = Number.isNaN(parseInt(editUser?.activation_code, 10)) ? 0 : parseInt(editUser?.activation_code, 10);
    formData.append("activation_code", enableactivation_codedata);
    formData.append("bank_name", editUser?.bank_name);
    formData.append("branch_name", editUser?.branch_name);
    formData.append("account_number", editUser?.account_number);
    formData.append("IFSC_Code", editUser?.IFSC_Code);
    formData.append("cancelled_cheque", editUser?.cancelled_cheque);
    formData.append("upi_id", editUser?.upi_id);
    formData.append("upi_mobile_number", editUser?.upi_mobile_number);
    formData.append("channel_image", editUser?.channel_image);
    formData.append("channel_banner", editUser?.channel_banner);
    const enablestatusdata = Number.isNaN(parseInt(editUser?.status, 10)) ? 0 : parseInt(editUser?.status, 10);
    formData.append("status", enablestatusdata);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let thirdApiStatus = false;
    let forthApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/About-update`,
        formData,
        { headers: headers }
      );

      if (response.data.status == true) {
        firstApiStatus = true;

        resultapi = response.data;

        const formData = new FormData();

        // formData.append("video_id", id);
        if (selectedFile1) {
          formData.append("channel_image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("channel_banner", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("channel_logo", selectedFile3);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Images-update`,
          formData,
          { headers: headers }
        );


        const formData1 = new FormData();

        // Function to fetch existing video from URL as a Blob
        const fetchVideoAsBlob = async (videoUrl) => {
          const response = await fetch(videoUrl);
          const blob = await response.blob(); // Get the Blob from the video URL
          return blob;
        };

        // Handle intro video logic
        if (videosintro && videosintro.length > 0) {
          // Append newly selected videos
          videosintro.forEach((video) => {
            formData1.append("intro_video", video); // Append each video file selected
          });
        } else if (editUser?.intro_video_url) {
          // If no new video is selected, append the existing video as .mp4 format
          try {
            const existingVideoBlob = await fetchVideoAsBlob(editUser.intro_video_url);
            formData1.append("intro_video", existingVideoBlob, "existing_intro.mp4"); // Append Blob with name 'existing_intro.mp4'
          } catch (error) {
            console.error("Failed to fetch the existing video:", error);
          }
        }

        // Send formData via axios
        try {
          const formData1response = await axios.post(
            `${process.env.REACT_APP_Baseurl}/Channel/Backend/Intro-video-update`,
            formData1,
            { headers: headers }
          );
          console.log("Form submitted successfully:", formData1response.data);
        } catch (error) {
          console.error("Failed to submit the form:", error);
        }


        // const formData1 = new FormData();

        // videosintro.map((video, index) => {
        //   formData1.append("intro_video", video);
        // });

        // for (const [index, video] of videosintro.entries()) {
        //   if (video) {
        //     formData1.append("intro_video", video);
        //   } else if (editUser?.intro_video_url) {
        //     try {
        //       const response = await fetch(editUser?.intro_video_url);
        //       const blob = await response.blob();
        //       formData1.append("intro_video", blob, 'intro_video_url.mp4'); // Specify the filename
        //     } catch (error) {
        //       console.error("Error fetching the intro video:", error);
        //     }
        //   }
        // }

        const formData1response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/Intro-video-update`,
          formData1,
          { headers: headers }
        );

        // const formDatacheque = new FormData();
        // if (selectedFile4) {
        //   formDatacheque.append("cancelled_cheque_image", selectedFile4);
        // }

        // if (selectedFile4) {
        //   // Append the selected file
        //   formDatacheque.append("cancelled_cheque_image", selectedFile4);
        // } else if (editUser?.cancelled_cheque_url) {
        //   // Fetch the existing file and append it as a Blob
        //   const response = await fetch(editUser?.cancelled_cheque_url);
        //   const blob = await response?.blob();
        //   formDatacheque.append("cancelled_cheque_url", blob, 'cancelled_cheque_url.jpg'); // Specify the filename
        // }

        // const formDatachequeresponse = await axios.post(
        //   `${process.env.REACT_APP_Baseurl}/Channel/Backend/Cancelled-Cheque-Image-update`,
        //   formDatacheque,
        //   { headers: headers }
        // );




        const formDatacheque = new FormData();

        // Handle cancelled cheque logic
        if (selectedFile4) {
          // Append the selected cheque file
          formDatacheque.append("cancelled_cheque_image", selectedFile4);
        } else if (editUser?.cancelled_cheque_url) {
          // If no new cheque image is selected but the existing cheque URL is present
          try {
            const response = await fetch(editUser?.cancelled_cheque_url);
            const blob = await response.blob();
            formDatacheque.append("cancelled_cheque_image", blob, 'cancelled_cheque_url.jpg'); // Specify the filename
          } catch (error) {
            console.error("Error fetching the cancelled cheque image:", error);
          }
        }

        // Only send the API request if there is cheque data to send
        let formDatachequeresponse;
        if (formDatacheque.has('cancelled_cheque_image')) {
          try {
            formDatachequeresponse = await axios.post(
              `${process.env.REACT_APP_Baseurl}/Channel/Backend/Cancelled-Cheque-Image-update`,
              formDatacheque,
              { headers: headers }
            );
          } catch (error) {
          }
        }

        // Assuming imageResponse is available
        // Check statuses and update flags accordingly

        if (imageResponse?.data?.status === true) {
          secondApiStatus = true;
        }

        if (formData1response?.data?.status === true) {
          thirdApiStatus = true;
        }

        if (formDatachequeresponse?.data?.status === true) {
          forthApiStatus = true;
        }
      } else if (response.data.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = response.data;
      }
    } catch (error) {
      console.error("Error:", error);
    }
    if (firstApiStatus && secondApiStatus && thirdApiStatus && forthApiStatus) {
      setValidationMessagechannel_name("");
      setValidationMessageemail("");
      setValidationMessagepassword("");
      setValidationMessagemobile_number("");
      setValidationMessageccode("");
      setValidationMessagerole("");
      toast.success(t(resultapi?.message));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      toast.error(t(resultapifalse?.message));
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddenchannel_name(true);
      setIsValidationHiddenpassword(true);
      setIsValidationHiddenmobile_number(true);
      setIsValidationHiddenccode(true);
      setIsValidationHiddenrole(true);
    }
  }

  const [passwordType, setPasswordType] = useState(true);
  const togglePassword = () => {
    setPasswordType(!passwordType)
  };


  const deleteOperationvideos = (id) => {

    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/Channel-Introvideo-delete`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          // props.setApiresponsealert(responseData);
          // props.setShowalert(true);
          toast.success(t(responseData?.message));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData == false) {
            var resultError = responseData;
            // props.setApiresponsealert(resultError);
            // props.setShowalert(true);
            toast.error(t(resultError?.message));
          }
        }
      });
  };

  return (
    <>
      {showOverlay && (<ResponseLoader />)}
      <AdminSaveHeader heading="My profile" saveText="Update profile" saveMethod={saveUser} />
      <FormContainer>
        <LeftWrapper>
          <Wrapper>
            <MessageHeading text="Profile Details" />
            <MessageLabel text="User Name" className="mandatorySimple" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="channel_name"
              name="channel_name"
              placeholder=""
              onChange={handleInput}
              value={editUser?.channel_name}
              onKeyDown={handleInputKeyDownchannel_name}
              onBlur={handleBlurchannel_name}
              ref={channel_nameInputRef}
            />
            {!isValidationHiddenchannel_name && (<MessageBox text={`${validationMessagechannel_name}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="User Slug" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="channel_slug"
              name="channel_slug"
              placeholder=""
              value={editUser?.channel_slug}
              onChange={handleInput}
            />
            <MessageLabel text="User Email Address" className="mandatorySimple" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="email"
              name="email"
              placeholder="Enter Email"
              value={editUser?.email}
              onChange={handleInput}
              ref={emailInputRef}
            />
            {!isValidationHiddenemail && (<MessageBox text={`${validationMessageemail}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="User Mobile Number" className="mandatorySimple" />
            <div className="row mx-0">
              <div className="col-4 ps-0">
                <select
                  className="form-select  theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0 pe-5"
                  name="ccode"
                  id="ccode"
                  value={editUser?.ccode}
                  onChange={handleInput}
                  ref={ccodeInputRef}
                >
                  {number?.map((item) => (
                    <option key={item?.id} value={item?.id} className="theme-bg-color theme-text-color">
                      {item?.country_name} ({item?.phonecode})
                    </option>
                  ))}
                </select>
                {!isValidationHiddenccode && (<MessageBox text={`${validationMessageccode}`} classname='errorred mb-2 d-block' />)}
              </div>
              <div className="col-8 pe-0">
                <input
                  type="number"
                  style={{ height: "50px" }}
                  className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
                  id="mobile_number"
                  name="mobile_number"
                  placeholder="Enter Mobile"
                  value={editUser?.mobile_number}
                  onChange={handleInput}
                  ref={mobile_numberInputRef}
                />
                {!isValidationHiddenmobile_number && (<MessageBox text={`${validationMessagemobile_number}`} classname='errorred mb-2 d-block' />)}
              </div>
            </div>
            <MessageLabel text="User Password" className="" />
            <div className="position-relative">
              <input
                type={passwordType ? "password" : "text"}
                className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0 pe-5 overflow-hidden"
                id="password"
                name="password"
                placeholder=""
                autoComplete="new-password"
                onChange={handleInput}
                ref={passwordInputRef}
              />
              <span className="eyepasswordusers theme-bg-color" aria-hidden="true" onClick={togglePassword} >
                {passwordType ? (
                  <VscEyeClosed className="theme-text-color" style={{ width: "25px", height: "25px" }} />
                ) : (
                  <RxEyeOpen className="theme-text-color" style={{ width: "25px", height: "25px" }} />
                )}
              </span>
            </div>
            {!isValidationHiddenpassword && (<MessageBox text={`${validationMessagepassword}`} classname='errorred mb-2 d-block' />)}
            <MessageLabel text="Channel About" />
            <JoditEditor
              className="rs-input theme-bg-color-secondary custom-placeholder theme-text-color border-0"
              value={editUser?.channel_about}
              onChange={(e) => {
                handleChangeFirstchannel_about(e);
              }}
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Thumbnails" />
            <MessageLabel text="Profile Image" />
            {loading ? (
              <img src={loadings} className="thumbnails-images" />
            ) : (
              <img
                src={editUser?.channel_image_url}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="row mx-0 my-3">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef1.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef1}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef1,
                        setSelectedFile1
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile1 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className=" col-10 p-0">
                          <img
                            src={URL.createObjectURL(selectedFile1)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile1)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Banner Image" />
            {loading ? (
              <img src={loadings} className="thumbnails-images" />
            ) : (
              <img
                src={editUser?.channel_banner_url}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="row mx-0 my-3">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef2.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef2}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef2,
                        setSelectedFile2
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className=" col-6 pe-0">
                <div>
                  {selectedFile2 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className=" col-10 p-0 ">
                          <img
                            src={URL.createObjectURL(selectedFile2)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile2)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <MessageLabel text="Channel Logo" />
            {loading ? (
              <img src={loadings} className="thumbnails-images" />
            ) : (
              <img
                src={editUser?.channel_logo_url}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="row mx-0 mt-3">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef3.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef3}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef3,
                        setSelectedFile3
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className="theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile3 && (
                    <div className="imagedropcopy  theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0">
                          <img
                            src={URL.createObjectURL(selectedFile3)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile3)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
        </LeftWrapper >
        <RightWrapper>
          <Wrapper>
            <MessageHeading text="Bank Details" />
            <MessageLabel text="Bank Name" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="bank_name"
              name="bank_name"
              placeholder=""
              value={editUser?.bank_name}
              onChange={handleInput}
            />
            <MessageLabel text="Branch Name" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="branch_name"
              name="branch_name"
              placeholder=""
              value={editUser?.branch_name}
              onChange={handleInput}
            />
            <MessageLabel text="Account Number" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="account_number"
              name="account_number"
              placeholder=""
              value={editUser?.account_number}
              onChange={handleInput}
            />
            <MessageLabel text="IFSC Code" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="IFSC_Code"
              name="IFSC_Code"
              placeholder=""
              value={editUser?.IFSC_Code}
              onChange={handleInput}
            />
            <MessageLabel text="UPI ID" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder mb-3 theme-text-color border-0"
              id="upi_id"
              name="upi_id"
              placeholder=""
              value={editUser?.upi_id}
              onChange={handleInput}
            />
            <MessageLabel text="UPI Mobile Number" />
            <input
              type="text"
              className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder  theme-text-color border-0"
              id="upi_mobile_number"
              name="upi_mobile_number"
              placeholder=""
              value={editUser?.upi_mobile_number}
              onChange={handleInput}
            />
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Cheque Details" />
            <MessageLabel text="Cancelled Cheque" />
            {!loading && editUser?.cancelled_cheque_url !== null && (
              <img
                src={editUser?.cancelled_cheque_url}
                alt="Image"
                className="thumbnails-images"
              />
            )}
            <div className="row mx-0 mt-3">
              <div className="col-6 ps-0">
                <div
                  className="imagedrop theme-border-color"
                  onClick={() => fileInputRef4.current.click()}
                >
                  <input
                    type="file"
                    ref={fileInputRef4}
                    onChange={(event) =>
                      handleFileChange(
                        event,
                        fileInputRef4,
                        setSelectedFile4
                      )
                    }
                    accept="image/*"
                    style={{ display: "none" }}
                  />
                  <label className=" theme-text-color">
                    <CameraRetroIcon className=" theme-text-color" />
                  </label>
                </div>
              </div>
              <div className="col-6 pe-0">
                <div>
                  {selectedFile4 && (
                    <div className="imagedropcopy theme-border-color text-end">
                      <div className="row mx-0">
                        <div className="col-10 p-0">
                          <img
                            src={URL.createObjectURL(selectedFile4)}
                            alt="Uploaded"
                            className="thumbnails-upload-images"
                          />
                        </div>
                        <div
                          className="col-2 p-0"
                          style={{ alignSelf: "center" }}
                        >
                          <button
                            onClick={() =>
                              handleDelete(setSelectedFile4)
                            }
                            type="button"
                            className=" theme-text-color bg-transparent "
                          ><IoClose className="custom-close-btn" /></button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Intro Video" />
            <div className="overflow-hidden rounded-3 ">
              {/* <media-player
                title={editUser?.intro_video_url}
                src={editUser?.intro_video_url}
                poster=""
                controls
              >
                <media-outlet></media-outlet>
              </media-player> */}

              {loading ? (
                <img
                  src={loadings}
                  className="thumbnails-images"
                  alt="Loading"
                />
              ) : (
                <div>
                  {editUser?.intro_video_url ? (
                    <div>
                      <div className="col-lg-12 row">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                          {/* Assuming this is a custom media player component */}
                          <media-player
                            title={editUser?.intro_video_url}
                            src={editUser?.intro_video_url}
                            poster=""
                            controls
                          >
                            <media-outlet></media-outlet>
                          </media-player>
                        </div>
                        <div className="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                          <Link className="delete ms-1">
                            <span
                              onClick={() =>
                                deleteOperationvideos(editUser?.id)
                              }
                            >
                              <img
                                src={deleteitem}
                                alt="Delete"
                                width={20}
                                height={20}
                              />
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )}

            </div>
            <div className="mt-3  theme-border-color file-draganddrop">
              <Dropzone
                onDrop={handleDropintro}
                accept="video/*"
                multiple={false}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="dropzone">
                    <input {...getInputProps()} />
                    <MessageBox text="Drag and drop Intro videos here" />
                    {videosintro?.length > 0 && (
                      <p className=" theme-text-color">
                        {videosintro?.length}{" "}
                        {videosintro?.length == 1 ? "file" : "files"}{" "}
                        selected
                      </p>
                    )}
                  </div>
                )}
              </Dropzone>
            </div>
          </Wrapper>
          <Wrapper>
            <MessageHeading text="Status Settings" />
            <div className="mx-0 row">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this User Status" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="status"
                    onChange={handleInputenable}
                    className="rs-input"
                    defaultChecked={editUser?.status == 1 ? true : false}
                    checked={editUser?.status == 1 ? true : false}
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="status"
                    onChange={handleInputenable}
                    value={editUser?.status == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
            <div className="row mx-0">
              <div className="col-9 ps-0">
                <MessageLabel text="Is this User Activation Code" />
              </div>
              <div className="col-3 pe-0 text-end">
                <label className="switch">
                  <input
                    name="activation_code"
                    onChange={handleInputenable}
                    defaultChecked={
                      editUser?.activation_code == 1 ? true : false
                    }
                    checked={editUser?.activation_code == 1 ? true : false}
                    className="rs-input"
                    type="checkbox"
                  />
                  <span
                    className="slider round"
                    name="activation_code"
                    onChange={handleInputenable}
                    value={editUser?.activation_code == 1 ? "1" : "0"}
                  ></span>
                </label>
              </div>
            </div>
          </Wrapper>
        </RightWrapper>
        <AdminCustomButton saveMethod={saveUser} saveText="Update profile" className="ms-auto mb-3" />
      </FormContainer>
    </>
  );
};

export default MyChannelSettings;
