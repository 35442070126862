import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { connect } from 'react-redux';
import MusicCard from './MusicCard';
import HomePageNav from './CommonComponents/HomePageNav';
import MessageBox from "../../components/Others/MessageBox";

const MusicSuggestion = ({ topArtists, swiperOptions, otherAudios, languageDetailsData, PropsType, songDetailsData, otherPlaylist }) => {
    return (
        <>
            {PropsType !== "PLAYLIST" && PropsType !== "ALBUM" && PropsType !== "STATION" && PropsType !== "GENRE" && <div className='mb-4 theme-border-color p-4 rounded-4' style={{ borderStyle: "solid", borderWidth: "1px" }}>
                <HomePageNav link={"musician"} headerName={"Top Artists"} className="musicDetailsNav" store={[1, 2]} seg={`${languageDetailsData?.Language_Code}/`} />
                {topArtists?.length == 0 ? <MessageBox text="No artist found !" classname="theme-text-color" /> :
                    <Swiper {...swiperOptions} className="musicDetailsSwiper">
                        {topArtists?.map((value, index) => (
                            <SwiperSlide key={value?.id} >
                                <MusicCard data={value} PropsType={"ARTIST"} />
                            </SwiperSlide>
                        ))}
                    </Swiper>}
            </div>}
            <div className='mb-4 theme-border-color p-4 rounded-4' style={{ borderStyle: "solid", borderWidth: "1px" }}>
                <p className='audioArtists pb-3 theme-text-color'>{`${PropsType !== "PLAYLIST" && PropsType !== "ARTIST" && PropsType !== "ALBUM" && PropsType !== "STATION" && PropsType !== "GENRE" ? "Recommended " : songDetailsData?.title ? songDetailsData?.title : songDetailsData?.artist_name ? songDetailsData?.artist_name : songDetailsData?.albumname ? songDetailsData?.albumname : songDetailsData?.name} ${PropsType == "ALBUM" ? "Album" : ""} Songs `}</p>
                <div className='listenCard '>
                    {otherAudios?.length == 0 ? <MessageBox text="No audio found !" classname="theme-text-color" /> :
                        <Swiper {...swiperOptions} className="musicDetailsSwiper">
                            {otherAudios?.map((value, index) => (
                                <SwiperSlide key={value?.id} >
                                    <MusicCard data={value} PropsType={"AUDIO"} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </div >
            </div >
            {PropsType == "PLAYLIST" && <div className='mb-4 theme-border-color p-4 rounded-4' style={{ borderStyle: "solid", borderWidth: "1px" }}>
                <HomePageNav link={"playlist"} headerName={"Other Playlist"} className="audioArtists" store={[1, 2]} seg={`${languageDetailsData?.Language_Code}/`} />
                <div className='listenCard'>
                    {otherPlaylist?.length == 0 ? <MessageBox text="No playlist found !" classname="theme-text-color" /> :
                        <Swiper {...swiperOptions} className="musicDetailsSwiper">
                            {otherPlaylist?.map((value, index) => (
                                <SwiperSlide key={value?.id} >
                                    <MusicCard data={value} PropsType={"PLAYLIST"} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </div >
            </div >}
            {PropsType == "ALBUM" && <div className='mb-4 theme-border-color p-4 rounded-4' style={{ borderStyle: "solid", borderWidth: "1px" }}>
                <HomePageNav link={"audio-albums"} headerName={"Other albums"} className="audioArtists" store={[1, 2]} seg={`${languageDetailsData?.Language_Code}/`} />
                <div className=' listenCard'>
                    {otherPlaylist?.length == 0 ? <MessageBox text="No album found !" classname="theme-text-color" /> :
                        <Swiper {...swiperOptions} className="musicDetailsSwiper">
                            {otherPlaylist?.map((value, index) => (
                                <SwiperSlide key={value?.id} >
                                    <MusicCard data={value} PropsType={"ALBUM"} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </div >
            </div >}
            {PropsType == "STATION" && <div className='mb-4 theme-border-color p-md-4 p-2  rounded-4' style={{ borderStyle: "solid", borderWidth: "1px" }}>
                <HomePageNav link={"music-station"} headerName={"Other Station"} className="audioArtists" store={[1, 2]} seg={`${languageDetailsData?.Language_Code}/`} />
                <div className=' listenCard'>
                    {otherPlaylist?.length == 0 ? <MessageBox text="No station found !" classname="theme-text-color" /> :
                        <Swiper {...swiperOptions} className="musicDetailsSwiper">
                            {otherPlaylist?.map((value, index) => (
                                <SwiperSlide key={value?.id} >
                                    <MusicCard data={value} PropsType={"STATION"} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </div >
            </div >}

            {PropsType == "GENRE" && <div className='mb-4 theme-border-color p-md-4 p-2  rounded-4' style={{ borderStyle: "solid", borderWidth: "1px" }}>
                <HomePageNav link={"/audio-genre"} headerName={"Other Genre"} className="audioArtists" store={[1, 2]} seg={`${languageDetailsData?.Language_Code}/music`} />
                <div className=' listenCard'>
                    {otherPlaylist?.length == 0 ? <MessageBox text="No genre found !" classname="theme-text-color" /> :
                        <Swiper {...swiperOptions} className="musicDetailsSwiper">
                            {otherPlaylist?.map((value, index) => (
                                <SwiperSlide key={value?.id} >
                                    <MusicCard data={value} PropsType={"GENRE"} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    }
                </div >
            </div >}
        </>

    )
}
const mapStateToProps = state => ({
    currentIndex: state.get_current_Reducers.currentIndex,
    otherAudios: state.get_songDetails_Reducer.otherAudios,
    topArtists: state.get_songDetails_Reducer.topArtists,
    songDetailsData: state.get_songDetails_Reducer.songDetailsData,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    otherPlaylist: state.get_songDetails_Reducer.otherPlaylist,
})
export default connect(mapStateToProps)(MusicSuggestion)