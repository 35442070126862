import { useParams } from "react-router-dom";

const useCommonApiUrl = () => {
    const { lang } = useParams();
    const defaultDomain = `${process.env.REACT_APP_Baseurl}`;
    const defaultPreFix = 'Channel/Backend';
    const defaultFrontEndRoute = '/channel';
    const finalRouteApi = `${defaultDomain}/${defaultPreFix}`;

    const access_token_channel = localStorage.getItem("access_token_channel");
    const channelApiHeader = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + access_token_channel,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
    };

    const videoDefaultURL = {
        listApi: `${finalRouteApi}/videos-List`,
        MultiDeleteApi: `${finalRouteApi}/videos-Multi-delete`,
        sliderApi: `${finalRouteApi}/video-slider-update`,
        addRouteLink: `${defaultFrontEndRoute}/add-video`,
        editRouteLink: `/${lang}${defaultFrontEndRoute}/edit-video/`,
        viewTab: 'videos',
        headingText: "All Videos",
        buttonText: "Add Video",
        exportFileName: "VIDEO-LIST",
        fetchArrayName: "videos",
        reqBodyName: {
            sliderName: "video_id",
            deleteName: "video_id",
        },
    };

    const seriesDefaultURL = {
        listApi: `${finalRouteApi}/series/list`,
        MultiDeleteApi: `${finalRouteApi}/series/multi-delete`,
        sliderApi: `${finalRouteApi}/series/slider-update`,
        addRouteLink: `${defaultFrontEndRoute}/add-series`,
        editRouteLink: `/${lang}${defaultFrontEndRoute}/edit-series/`,
        viewTab: 'shows',
        headingText: "All Series",
        buttonText: "Add Series",
        exportFileName: "SERIES-LIST",
        fetchArrayName: "Series_list",
        reqBodyName: {
            sliderName: "series_id",
            deleteName: "id",
        },
    };

    return { videoDefaultURL, seriesDefaultURL, channelApiHeader };
};

export default useCommonApiUrl;
