import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { connect } from 'react-redux';
import "./Register_Payments.css";
import { toast } from 'react-toastify';
import { useParams, Link, useNavigate } from "react-router-dom";
import sunnxt from "../../components/Images/Payment_image/sunnxt.png";
import zee5 from "../../components/Images/Payment_image/zee5.png";
import sonylive from "../../components/Images/Payment_image/sonylive.png";
import fuseplus from "../../components/Images/Payment_image/fuseplus.png";
import amazonprime from "../../components/Images/Payment_image/amazonprime.png";
import { useTranslation } from "react-i18next";
import usePpvGlobalImageConfig from "../Api/Rentppv_global_change";

function Register_Payments({ profileDetails }) {
  const { lang } = useParams();
  const { t } = useTranslation();
  const access_token = localStorage.getItem("access_token");
  const signup_username = localStorage.getItem("signup_username");
  const usernamelogin = localStorage.getItem("username");
  const user_id_signup = localStorage.getItem("signup_user_id");
  const user_id = localStorage.getItem("user_id");

  const signup_user_id = user_id_signup || user_id || '';
  // const username = signup_username || usernamelogin || '';
  // const username = profileDetails?.username + profileDetails?.last_name || '';

  const capitalizeFirstLetter = (str) => {
    return str ? str.charAt(0).toUpperCase() + str.slice(1) : "";
  };

  const navigate = useNavigate();

  const handleSkipClick = () => {
    if (access_token) {
      // console.log("Token available, redirecting to /");
      window.location.href = "/";
      localStorage.clear();
    } else {
      // console.log("Token not available, redirecting to /login");
      window.location.href = "/login";
      localStorage.clear();
    }
  };

  // Get the username and last_name with fallback to empty string if undefined
  const username = profileDetails?.Profile_details?.username || "";
  const lastName = profileDetails?.Profile_details?.last_name || "";

  // Concatenate and capitalize the first letter of the combined string
  const capitalizedUsername = `${(username + " " + lastName).trim()}`.replace(/^./, str => str.toUpperCase());

  // const capitalizedUsername = capitalizeFirstLetter(username);

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  // const formattedDatatype = convertToTitleCase(signup_username);

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token,
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const [stripeids, setStripeids] = useState([]);
  const [paymentGateways, setPaymentGateways] = useState([]);

  useEffect(() => {
    fetchOptions();
    fetchPaymentGateways();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Subscription-Plans`,
        { headers: headers }
      );
      const StripeSubscriptionPlans = response?.data?.Stripe_Subscription_Plans;
      setStripeids(StripeSubscriptionPlans);

      // console.log(StripeSubscriptionPlans);
    } catch (error) {
      console.error(error);
    }
  };

  const [activeGateway, setActiveGateway] = useState(null); // Initialize activeGateway as null

  const fetchPaymentGateways = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Front-End/Payment-Gateway-list`,
        { headers: headers }
      );
      const paymentGateways = response?.data?.Payment_Gateways;
      setPaymentGateways(paymentGateways);
      // Set the active gateway to the first one by default
      setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
    } catch (error) {
      console.error(error);
    }
  };

  // const handleGatewayClick = (gatewayName) => {
  //   setActiveGateway(gatewayName);
  // };

  // const fetchPaymentGatewaysiyzico = async () => {
  //   try {
  //     const response = await axios.get(
  //       `${process.env.REACT_APP_Baseurl}/Front-End/Subscription-Plans`,
  //       { headers: headers }
  //     );

  //     const paymentGateways = response?.data?.Iyzico_Subscription_Plans;

  //     if (!Array.isArray(paymentGateways)) {
  //       throw new Error('Payment gateways data is not an array');
  //     }

  //     // const baseurl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}`
  //     const baseurl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`
  //     const successpagesuccesspage = `${process.env.REACT_APP_API_PATHss}`
  //     const buyername = "Pradeepraj"
  //     const buyerid = "45"
  //     const locale = "en"
  //     const currency = "TRY"
  //     const buyerGsmNumber = "+919876543210"
  //     const buyerEmail = "pradeep@webnexs.in"
  //     const buyerIdentityNumber = "+919876543210"
  //     const citybuyer = "Istanbul"
  //     const countrybuyer = "Turkey"
  //     const ipbuyer = "85.34.78.112"

  //     const formattedGateways = paymentGateways?.map(gateway => ({
  //       locale: locale,
  //       conversationId: gateway.plan_id,
  //       price: gateway.price,
  //       paidPrice: gateway.price,
  //       currency: currency,
  //       basketId: buyerid,
  //       paymentGroup: 'PRODUCT',
  //       callbackUrl: baseurl, // Ensure this matches your server configuration
  //       successpage: successpagesuccesspage, // Ensure this matches your server configuration
  //       enabledInstallments: [2, 3, 6, 9, 12],
  //       buyer: {
  //         id: 'BY789',
  //         name: buyername,
  //         surname: buyername,
  //         gsmNumber: buyerGsmNumber,
  //         email: buyerEmail,
  //         identityNumber: buyerIdentityNumber,
  //         lastLoginDate: '2015-10-05 12:43:35',
  //         registrationDate: '2013-04-21 15:12:09',
  //         registrationAddress: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
  //         ip: '85.34.78.112',
  //         city: citybuyer,
  //         country: countrybuyer,
  //         zipCode: '34732'
  //       },
  //       shippingAddress: {
  //         contactName: 'Jane Doe',
  //         city: 'Istanbul',
  //         country: 'Turkey',
  //         address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
  //         zipCode: '34742'
  //       },
  //       billingAddress: {
  //         contactName: 'Jane Doe',
  //         city: citybuyer,
  //         country: countrybuyer,
  //         address: 'Nidakule Göztepe, Merdivenköy Mah. Bora Sok. No:1',
  //         zipCode: '34742'
  //       },
  //       basketItems: [
  //         {
  //           id: buyerid,
  //           name: gateway.plans_name,
  //           category1: 'Collectibles',
  //           category2: 'Accessories',
  //           itemType: 'PHYSICAL',
  //           price: gateway.price
  //         }
  //       ]
  //     }));

  //     setFormDataiyzico(formattedGateways);

  //     setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
  //   } catch (error) {
  //     console.error('Error fetching payment gateways:', error);
  //   }
  // };


  // iyzico payment subcription payment 
  // iyzico payment subcription payment 
  // iyzico payment subcription payment 


  // const toastShownRef = useRef(false); // Track if toast has been shown

  // useEffect(() => {
  //   const retrievePaymentDetails = async () => {
  //     try {
  //       const response = await axios.get(`http://localhost:7004/retrieve-payment-details-subcription`);

  //       if (response.data.status === 'success' && !toastShownRef.current) {
  //         toast.success(`PAYMENT ${response?.data?.paymentStatus}`);
  //         toastShownRef.current = true; // Set the ref to true after showing the toast

  //         const result = response.data;
  //         const user_id = localStorage.getItem("user_id");
  //         const web = "web";

  //         // Destructure the location data
  //         const { city, country, organization_name } = locationResponselocationResponse || {};

  //         // Proceed only if all necessary data is available
  //         if (city && country && organization_name) {
  //           const formDataiyzico = new FormData();
  //           formDataiyzico.append("Login_user_id", user_id);
  //           formDataiyzico.append("Login_device_type", web);
  //           formDataiyzico.append("Subscription_reference_id", result.token);
  //           formDataiyzico.append("Subscription_payment_Id", result.paymentId);
  //           formDataiyzico.append("regionname", organization_name);
  //           formDataiyzico.append("countryname", country);
  //           formDataiyzico.append("cityname", city);

  //           console.log("FormDataiyzico:", Array.from(formDataiyzico.entries())); // To check the content of FormData

  //           await axios.post(
  //             `${process.env.REACT_APP_Baseurl}/Front-End/iyzico-subscription-verify-store`,
  //             formDataiyzico, // Send the FormData object directly
  //           );
  //         } else {
  //           console.error('City, country, or organization_name is undefined');
  //         }
  //       }
  //     } catch (error) {
  //       if (!toastShownRef.current) {
  //         toast.error(`PAYMENT ${error?.response?.data?.paymentStatus || 'Error'}`);
  //         toastShownRef.current = true; // Set the ref to true after showing the error toast
  //       }
  //       console.error('Error retrieving payment details:', error);
  //     }
  //   };

  //   // Trigger the effect only if the necessary location data is available
  //   if (locationResponselocationResponse) {
  //     retrievePaymentDetails();
  //   }
  // }, [locationResponselocationResponse]);

  // useEffect(() => {
  //   let showAlert = true;

  //   const retrievePaymentDetails = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/retrieve-payment-details-subcription`);

  //       if (response.data.status === 'success' && showAlert) {
  //         showAlert = false;

  //         const result = response.data;
  //         const user_id = localStorage.getItem("user_id");

  //         await axios.post('http://localhost:7001/payment/details', {
  //           ...result,
  //           user_id
  //         });
  //       }
  //     } catch (error) {
  //       console.error('Error retrieving payment details:', error);
  //     }
  //   };

  //   retrievePaymentDetails();
  // }, []);

  // const [formDataiyzico, setFormDataiyzico] = useState([]);

  // let showAlert = true; // Flag to track if alert has been shown

  // const retrievePaymentDetails = async () => {
  //   try {
  //     const response = await axios.get('http://localhost:7004/retrieve-payment-details');

  //     if (response.data.status === 'success' && showAlert) {
  //       // alert(response.data.paymentStatus);
  //       // toast.success(`PAYMENT ${response?.data?.paymentStatus}`)

  //       showAlert = false; // Set flag to false after showing alert
  //       const result = response.data;
  //       await axios.post('http://localhost:7001/payment/details', result);
  //     } else {
  //       // alert(response.data.paymentStatus);
  //       // toast.error(`PAYMENT ${response?.data?.paymentStatus}`)
  //     }
  //   } catch (error) {
  //     console.error('Error retrieving payment details:', error);
  //   }
  // };




  // const [name, setName] = useState('New Test');
  // const [description, setDescription] = useState('New Test');
  // const [price, setPrice] = useState('567');
  // const [paidPrice, setPaidPrice] = useState('567');
  // const [response, setResponse] = useState(null);

  // const [subscriptionId, setSubscriptionId] = useState('adfbbd15-34ae-4a03-8595-2e831c5f127f');
  // const [callbackUrl, setCallbackUrl] = useState('http://localhost:7004');
  // const [paymentUrl, setPaymentUrl] = useState('');


  // const initiatePaymenthandleSubmit = async () => {
  //   try {
  //     const response = await fetch('http://localhost:7004/initiate-subscription-payment', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ subscriptionId, callbackUrl })
  //     });
  //     const data = await response.json();
  //     if (response.ok) {
  //       setPaymentUrl(data.paymentUrl); // URL to redirect the user for payment
  //     } else {
  //       console.error('Failed to initiate payment:', data.error);
  //     }
  //   } catch (error) {
  //     console.error('Error initiating payment:', error);
  //   }
  // };


  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   try {
  //     const result = await axios.post('http://localhost:7004/create-subscription', {
  //       subscriptionData: {
  //         locale: 'EN',
  //         conversationId: 'adfbbd15-34ae-4a03-8595-2e831c5f127f', // Generate or use a unique value
  //         name,
  //         description
  //       },
  //       paymentData: {
  //         locale: 'EN',
  //         conversationId: 'adfbbd15-34ae-4a03-8595-2e831c5f127f', // Same unique value
  //         price,
  //         paidPrice,
  //         currency: 'TRY',
  //         basketId: 'adfbbd15-34ae-4a03-8595-2e831c5f127f', // Generate or use a unique value
  //         callbackUrl
  //       }
  //     });

  //     // Redirect to the payment page
  //     if (result.data.paymentResult && result.data.paymentResult.checkoutFormContent) {
  //       window.location.href = result.data.paymentResult.checkoutFormContent;
  //     }

  //     setResponse(result.data);
  //     setError(null);
  //   } catch (err) {
  //     setError(err.response?.data || err.message);
  //     setResponse(null);
  //   }
  // };



  // const initializeCheckout = async (itemIndex) => {

  //   const selectedItem = formDataiyzico[itemIndex];

  //   try {
  //     const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/initialize-checkout`, selectedItem);
  //     const paymentPageUrl = response.data.paymentPageUrl;
  //     window.location.href = paymentPageUrl;
  //   } catch (error) {
  //     console.error('Error initializing checkout:', error);
  //   }
  // };

  // useEffect(() => {
  //   let showAlert = true; // Flag to track if alert has been shown

  //   const retrievePaymentDetails = async () => {
  //     try {
  //       const response = await axios.get(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/retrieve-payment-details`);

  //       if (response.data.status === 'success' && showAlert) {
  //         // alert(response.data.paymentStatus);
  //         // toast.success(`PAYMENT ${response?.data?.paymentStatus}`)
  //         showAlert = false; // Set flag to false after showing alert
  //         const result = response.data;
  //         await axios.post('http://localhost:7001/payment/details', result);
  //       } else {
  //         // alert(response.data.paymentStatus);
  //         // toast.error(`PAYMENT ${response?.data?.paymentStatus}`)
  //       }
  //     } catch (error) {
  //       console.error('Error retrieving payment details:', error);
  //     }
  //   };
  //   retrievePaymentDetails();
  // }, []);


  const stripeid = [
    {
      id: 1,
      src: amazonprime,
      price: 93,
      title: "Basic Plan",
      video_categories: [
        {
          id: 1,
          src: amazonprime,
          price: 93,
          title: "Basic Plan",
        },
        {
          id: 2,
          src: fuseplus,
          price: 355,
          title: "Pro Plan",
        },
        {
          id: 3,
          src: sunnxt,
          price: 213,
          title: "Bussiness Plan",
        },
        {
          id: 4,
          src: sonylive,
          price: 990,
          title: "Super Plan",
        },
        {
          id: 5,
          src: zee5,
          price: 5000,
          title: "Premium Plan",
        },
      ],
    },
    {
      id: 2,
      src: fuseplus,
      price: 355,
      title: "Pro Plan",
      video_categories: [
        {
          id: 1,
          src: amazonprime,
          price: 93,
          title: "Basic Plan",
        },
        {
          id: 2,
          src: fuseplus,
          price: 355,
          title: "Pro Plan",
        },
        {
          id: 3,
          src: sunnxt,
          price: 213,
          title: "Bussiness Plan",
        },
        {
          id: 4,
          src: sonylive,
          price: 990,
          title: "Super Plan",
        },
        {
          id: 5,
          src: zee5,
          price: 5000,
          title: "Premium Plan",
        },
      ],
    },
    {
      id: 3,
      src: sunnxt,
      price: 213,
      title: "Bussiness Plan",
      video_categories: [
        {
          id: 1,
          src: amazonprime,
          price: 93,
          title: "Basic Plan",
        },
        {
          id: 2,
          src: fuseplus,
          price: 355,
          title: "Pro Plan",
        },
        {
          id: 3,
          src: sunnxt,
          price: 213,
          title: "Bussiness Plan",
        },
        {
          id: 4,
          src: sonylive,
          price: 990,
          title: "Super Plan",
        },
        {
          id: 5,
          src: zee5,
          price: 5000,
          title: "Premium Plan",
        },
      ],
    },
    {
      id: 4,
      src: sonylive,
      price: 990,
      title: "Super Plan",
      video_categories: [
        {
          id: 1,
          src: amazonprime,
          price: 93,
          title: "Basic Plan",
        },
        {
          id: 2,
          src: fuseplus,
          price: 355,
          title: "Pro Plan",
        },
        {
          id: 3,
          src: sunnxt,
          price: 213,
          title: "Bussiness Plan",
        },
        {
          id: 4,
          src: sonylive,
          price: 990,
          title: "Super Plan",
        },
        {
          id: 5,
          src: zee5,
          price: 5000,
          title: "Premium Plan",
        },
      ],
    },
    {
      id: 5,
      src: zee5,
      price: 5000,
      title: "Premium Plan",
      video_categories: [
        {
          id: 1,
          src: amazonprime,
          price: 93,
          title: "Basic Plan",
        },
        {
          id: 2,
          src: fuseplus,
          price: 355,
          title: "Pro Plan",
        },
        {
          id: 3,
          src: sunnxt,
          price: 213,
          title: "Bussiness Plan",
        },
        {
          id: 4,
          src: sonylive,
          price: 990,
          title: "Super Plan",
        },
        {
          id: 5,
          src: zee5,
          price: 5000,
          title: "Premium Plan",
        },
      ],
    },
  ];

  // const checkoutstripepayment = (plan) => {
  //   fetch(`${process.env.REACT_APP_Baseurl}/userstripe/payment/api`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ plan: plan, stripeid: Channel_Partner_id }),
  //   })
  //     .then((response) => {
  //       if (response?.ok) return response?.json();
  //       console.log(response);
  //       return response?.json().then((json) => Promise.reject(json));
  //     })
  //     .then(({ session }) => {
  //       window.location = session.url;
  //     })
  //     .catch((e) => {
  //       console.log(e.error);
  //     });
  // };

  // const checkoutstripepayment = (plan_id) => {
  //   fetch(`${process.env.REACT_APP_Baseurl}/userstripe/payment/api`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ plan_id: plan_id, user_id: user_id }),
  //   })
  //     .then((response) => {
  //       if (response?.ok) return response?.json();
  //       console.log(response);
  //       return response?.json().then((json) => Promise.reject(json));
  //     })
  //     .then(({ session }) => {
  //       if (session?.status == true) {
  //         // Send data to another API
  //         fetch(`${process.env.REACT_APP_Baseurl}/another/api`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ id: session?.id }), // Change this as needed
  //         })
  //           .then((response) => {
  //             if (response?.ok) return response?.json();
  //             console.log(response);
  //             return response?.json().then((json) => Promise.reject(json));
  //           })
  //           .then((data) => {
  //             // Handle the response from the second API call
  //             console.log("Data from another API:", data);
  //           })
  //           .catch((e) => {
  //             console.log("Error from another API:", e.error);
  //           });

  //         window.location = session.url;
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.error);
  //     });
  // };

  // const checkoutstripepayment = (user_id, plan_id) => {
  //   // Construct the data to send to your API
  //   const requestData = {
  //     user_id,
  //     plan_id,
  //     quantity: 1,
  //     // Include other data from the stripeids array that you need to send
  //     // For example, if you want to send the price from the stripeids array, you can do:
  //     // price: stripeids.find(tab => tab.plan_id == plan_id)?.price,
  //   };

  //   fetch(`${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Payment-Link`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(requestData),
  //   })
  //     .then((response) => {
  //       if (response?.ok) return response?.json();
  //       // console.log(response);
  //       return response?.json().then((json) => Promise.reject(json));
  //     })
  //     .then(({ session }) => {
  //       if (session.status == true) {
  //         // Send data to another API
  //         fetch(`${process.env.REACT_APP_Baseurl}/another/api`, {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({ id: session.id }),
  //         })
  //           .then((response) => {
  //             if (response?.ok) return response?.json();
  //             console.log(response);
  //             return response?.json().then((json) => Promise.reject(json));
  //           })
  //           .then((data) => {
  //             // Handle the response from the second API call
  //             console.log("Data from another API:", data);
  //           })
  //           .catch((e) => {
  //             console.log("Error from another API:", e.error);
  //           });

  //         window.location = session.url;
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e.error);
  //     });
  // };

  //   const checkoutstripepayment = (user_id, plan_id) => {
  //     const requestData = {
  //       user_id,
  //       plan_id,
  //       quantity: 1,
  //     };

  //     fetch(`${process.env.REACT_APP_Baseurl}/Front-End/Stripe-Payment-Link`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestData),
  //     })
  //       .then((response) => {
  //         if (response?.ok) return response?.json();
  //         return response?.json().then((json) => Promise.reject(json));
  //       })
  //       .then((response) => {
  //         if (response?.status == true) {
  //           const paymentLinkURL = response?.Payment_Link_URL;
  //           // console.log(paymentLinkURL , "paymentLinkURL")

  //           // Open a new tab or window with the payment link URL
  //           const newTab = window.open(paymentLinkURL, "_self");
  //           if (newTab) {
  //             newTab.focus(); // Focus on the new tab if it was successfully opened
  //           } else {
  //             // Handle cases where the browser blocked the popup
  //             console.error("Popup blocked by the browser");
  //             // You can provide a message to the user or take other actions here
  //           }
  //         } else {
  //           console.error("Payment session status is not true");
  //         }
  //       })
  //       .catch((e) => {
  //         console.error(e.error);
  //       });
  //   };

  // stripe


  const checkoutstripepayment = (plan_id) => {
    const baseUrl = process.env.REACT_APP_Baseurl;
    const successUrl = "/login";
    const failureUrl = "/login";
    const currentPageUrl = process.env.REACT_APP_API_PATHss;
    const success_url = `${currentPageUrl}/login`;
    const failure_url = `${currentPageUrl}/login`;

    const requestData = {
      user_id: signup_user_id,
      plan_id,
      quantity: 1,
      currentPageUrl,
      success_url,
      failure_url,
    };
    fetch(`${baseUrl}/Front-End/Stripe-Payment-Link`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response?.ok) return response?.json();
        return response?.json().then((json) => Promise.reject(json));
      })
      .then((response) => {
        if (response?.status == true) {
          const paymentLinkURL = response?.Payment_Link_URL;
          const newTab = window.open(paymentLinkURL, "_self");
          if (newTab) {
            newTab.focus();
            newTab.onbeforeunload = () => {
              // Redirect to the success page when the popup is closed
              // window.location.href = successUrl;
            };
            localStorage.clear();
            toast.success(t("Payment Proceed"));
          } else {
            console.error("Popup blocked by the browser");
            toast.error(t("Popup blocked by the browser"));
            // You can provide a message to the user or take other actions here
            // window.location.href = failureUrl; // Redirect to the failure URL
          }
        } else {
          console.error("Payment session status is not true");
          toast.error(t("Payment session status is not true"));
          // window.location.href = failureUrl; // Redirect to the failure URL
        }
      })
      .catch((e) => {
        console.error(e.message); // Log the error message
        toast.error(t("Payment session status is not true"));
        // window.location.href = failureUrl; // Redirect to the failure URL
      });
  };

  // const checkoutstripepayment = (plan_id) => {
  //   const baseUrl = process.env.REACT_APP_Baseurl;
  //   const successUrl = "/login";
  //   const failureUrl = "/login";
  //   const currentPageUrl = process.env.REACT_APP_API_PATHss;
  //   const success_url = `${currentPageUrl}/login`;
  //   const failure_url = `${currentPageUrl}/login`;

  //   const requestData = {
  //     user_id: signup_user_id,
  //     plan_id,
  //     quantity: 1,
  //     currentPageUrl,
  //     success_url,
  //     failure_url,
  //   };

  //   fetch(`${baseUrl}/Front-End/Stripe-Payment-Link`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(requestData),
  //   })
  //     .then((response) => {
  //       if (response?.ok) return response?.json();
  //       return response?.json().then((json) => Promise.reject(json));
  //     })
  //     .then((response) => {
  //       if (response?.status == true) {
  //         const paymentLinkURL = response?.Payment_Link_URL;
  //         const newTab = window.open(paymentLinkURL, "_self");
  //         if (newTab) {
  //           newTab.focus();
  //           newTab.onbeforeunload = () => {
  //             // Redirect to the success page when the popup is closed
  //             // window.location.href = successUrl;

  //           }; localStorage.clear();
  //         } else {
  //           console.error("Popup blocked by the browser");
  //           // You can provide a message to the user or take other actions here
  //           // window.location.href = failureUrl; // Redirect to the failure URL
  //         }
  //       } else {
  //         console.error("Payment session status is not true");
  //         // window.location.href = failureUrl; // Redirect to the failure URL
  //       }
  //     })
  //     .catch((e) => {
  //       console.error(e.message); // Log the error message
  //       // window.location.href = failureUrl; // Redirect to the failure URL
  //     });
  // };

  // razorpay

  const initPayment = (data, tag) => {
    const options = {
      key: tag?.name,
      amount: data?.amount * 100, // Amount should be in paisa
      currency: data?.currency,
      name: tag?.name,
      description: tag?.name,
      image: tag?.img,
      order_id: data?.id,
      handler: async (response) => {
        try {
          const verifyUrl = "http://localhost:7000/api/payment/verify";
          const paymentData = {
            response,
            tag,
          };
          const { data } = await axios.post(verifyUrl, paymentData);
          console.log(data);
        } catch (error) {
          console.log(error);
        }
      },
      theme: {
        color: "#3399cc",
      },
    };
    const rzp1 = new window.Razorpay(options);
    rzp1.open();
  };

  const handlePaymentrazorpay = async (tag) => {
    try {
      const orderUrl = "http://localhost:7000/api/payment/orderss";
      const { data } = await axios.post(orderUrl, {
        quantity: 1,
        user_id: tag?.id,
        amount: tag?.price,
        plan_id: tag?.id,
      });
      console.log(data);
      initPayment(data?.data, tag);
    } catch (error) {
      console.log(error);
    }
  };




  // iyzico payment subcription payment 
  // iyzico payment subcription payment 
  // iyzico payment subcription payment 

  const [error, setError] = useState(null);
  const { currency, currencySymbol, userCurrency, locationResponselocationResponse } = usePpvGlobalImageConfig();
  const [convertedPrice, setConvertedPrice] = useState([]);
  const [formDataiyzico, setFormDataiyzico] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);

  useEffect(() => {
    const fetchConversionRate = async () => {
      if (!userCurrency || !currency?.code || currency?.enable_multi_currency !== 1) return;

      try {
        const response = await axios.get(`${process.env.REACT_APP_API_Curreny_generate}/${currency?.code}`);
        const data = response.data;
        const conversionRate = data.rates[userCurrency];

        if (conversionRate) {
          const convertedPrices = formDataiyzico.map(item => ({
            ...item,
            convertedPrice: item.price * conversionRate
          }));
          setConvertedPrice(convertedPrices);
        } else {
          console.error('Conversion rate not found for currency:', userCurrency);
        }
      } catch (error) {
        console.error('Error fetching conversion rate:', error);
      }
    };

    fetchConversionRate();
  }, [userCurrency, currency?.code, currency?.enable_multi_currency, formDataiyzico]);

  const ppv_priceppv_price = convertedPrice.map(item => item.convertedPrice);


  useEffect(() => {
    // Fetch the payment gateways and store them in state
    const fetchPaymentGatewaysiyzico = async () => {
      if (!profileDetails || !locationResponselocationResponse) return;
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_Baseurl}/Front-End/Subscription-Plans`,
          { headers: headers }
        );

        const paymentGateways = response?.data?.Iyzico_Subscription_Plans;

        if (!Array.isArray(paymentGateways)) {
          throw new Error('Payment gateways data is not an array');
        }

        const getCurrentDateTime = () => {
          const now = new Date();
          const year = now.getFullYear();
          const month = String(now.getMonth() + 1).padStart(2, '0');
          const day = String(now.getDate()).padStart(2, '0');
          const hours = String(now.getHours()).padStart(2, '0');
          const minutes = String(now.getMinutes()).padStart(2, '0');
          const seconds = String(now.getSeconds()).padStart(2, '0');
          return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };

        const currentDateTime = getCurrentDateTime();
        const locale = lang === "tr" ? "tr" : "en";
        const currencyfinal = currency?.code;
        const basketId = profileDetails?.Profile_details?.id;
        const gsmNumber = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        // const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failer";
        const failerpage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/failerpage";
        const successpagesuccesspage = `${process.env.REACT_APP_API_PATHss}/` + lang + "/successpage";
        // const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/callback`;
        const callbackUrl = `${process.env.REACT_APP_API_IYZICO_PAYMENT}/payment-callback`;
        const usernames = profileDetails?.Profile_details?.username;
        const last_name = profileDetails?.Profile_details?.last_name;
        const payment_mobile_number = "+" + profileDetails?.Profile_details?.payment_mobile_number;
        const user_ids = user_id;
        const emailemail = profileDetails?.Profile_details?.email;
        const city = locationResponselocationResponse?.city;
        const country = locationResponselocationResponse?.country;
        const zipCode = locationResponselocationResponse?.asn;
        const ip = locationResponselocationResponse?.ip;
        // const successpagesuccesspage = `${process.env.REACT_APP_API_PATHss}`;
        // const buyerDetails = {
        //   name: "Pradeepraj",
        //   id: "45",
        //   gsmNumber: "+905051234567",
        //   email: "pradeep@webnexs.in",
        //   identityNumber: "+905051234567",
        //   city: "Istanbul",
        //   country: "Turkey",
        //   ip: "85.34.78.112"
        // };

        const formattedGateways = paymentGateways?.map(gateway => ({
          locale: locale,
          conversationId: gateway.plan_id,
          price: gateway.price,
          paidPrice: gateway.price,
          currency: currencyfinal,
          basketId: basketId,
          paymentGroup: 'PRODUCT',
          callbackUrl: callbackUrl,
          successpage: successpagesuccesspage,
          failerpage: failerpage,
          regionname: country,
          countryname: country,
          Login_user_id: user_ids,
          Login_device_type: "web",
          cityname: city,
          enabledInstallments: [2, 3, 6, 9, 12],
          billing_interval: gateway.billing_interval,
          plans_name: gateway.plans_name,
          billing_type: gateway.billing_type,
          days: gateway.days,
          video_quality: gateway.video_quality,
          plan_content: gateway.plan_content,
          resolution: gateway.resolution,
          ios_plan_price: gateway.ios_plan_price,
          ios_product_id: gateway.ios_product_id,
          plan_trail_days: gateway.plan_trail_days,
          created_at: gateway.created_at,
          created_at: gateway.created_at,
          buyer: {
            id: user_ids,
            name: usernames,
            surname: last_name,
            gsmNumber: payment_mobile_number,
            email: emailemail,
            identityNumber: gsmNumber,
            lastLoginDate: currentDateTime,
            registrationDate: currentDateTime,
            registrationAddress: country,
            ip: ip,
            city: city,
            country: country,
            zipCode: zipCode
          },
          shippingAddress: {
            contactName: usernames,
            city: city,
            country: country,
            district: city,
            address: country,
            zipCode: zipCode
          },
          billingAddress: {
            contactName: usernames,
            city: city,
            country: country,
            address: country,
            zipCode: zipCode
          },
          basketItems: [
            {
              id: basketId,
              name: gateway.plans_name,
              category1: 'MEMBERSHIP',
              itemType: 'VIRTUAL',
              price: gateway.price
            }
          ]
        }));

        setFormDataiyzico(formattedGateways);
        setActiveGateway(paymentGateways[0]?.Payment_Gateway_name || null);
      } catch (error) {
        console.error('Error fetching payment gateways:', error);
      }
    };

    fetchPaymentGatewaysiyzico();
    // retrievePaymentDetails();
  }, [profileDetails, locationResponselocationResponse]);

  // Handle product selection and subscription
  const handleProductClick = async (product) => {
    setSelectedProduct(product);

    const request = {
      locale: product.locale,
      conversationId: product.conversationId,
      callbackUrl: product.callbackUrl,
      pricingPlanReferenceCode: product.conversationId, // Adjust based on your data structure
      subscriptionInitialStatus: "ACTIVE",
      price: product.price,
      paidPrice: product.paidPrice,
      currency: product.currency,
      basketId: product.basketId,
      paymentGroup: 'PRODUCT',
      callbackUrl: product.callbackUrl,
      successpage: product.successpage,
      failerpage: product.failerpage,
      regionname: product.regionname,
      countryname: product.countryname,
      Login_user_id: product.Login_user_id,
      Login_device_type: "web",
      cityname: product.cityname,
      customer: {
        name: product.buyer.name,
        surname: product.buyer.surname,
        identityNumber: product.buyer.gsmNumber,
        email: product.buyer.email,
        gsmNumber: product.buyer.gsmNumber,
        billingAddress: {
          contactName: product.billingAddress.contactName,
          city: product.billingAddress.city,
          district: product.billingAddress.district,
          country: product.billingAddress.country,
          address: product.billingAddress.address,
          zipCode: product.billingAddress.zipCode
        },
        shippingAddress: {
          contactName: product.shippingAddress.contactName,
          city: product.shippingAddress.city,
          district: product.shippingAddress.district,
          country: product.shippingAddress.country,
          address: product.shippingAddress.address,
          zipCode: product.shippingAddress.zipCode
        }
      },
      pricingPlanPrice: product.price // Ensure this is set correctly
    };
    // setLoading(true);
    setError(null);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_IYZICO_PAYMENT}/create-subscription`, request);

      if (response.data.status === "success") {
        const scriptContent = response.data.checkoutFormContent;

        // Inject and initialize Iyzico script
        const scripts = scriptContent.match(/<script[^>]*>([\s\S]*?)<\/script>/g) || [];
        scripts.forEach((script) => {
          const scriptElement = document.createElement('script');
          scriptElement.textContent = script.replace(/<\/?script[^>]*>/g, '');
          document.body.appendChild(scriptElement);
        });

        if (window.iyziInit && typeof window.iyziInit.createTag === 'function') {
          window.iyziInit.createTag();
        } else if (window.iyziUcsInit && typeof window.iyziUcsInit.createTag === 'function') {
          window.iyziUcsInit.createTag();
        } else if (window.iyziSubscriptionInit && typeof window.iyziSubscriptionInit.createTag === 'function') {
          window.iyziSubscriptionInit.createTag();
        } else {
          throw new Error('Iyzico script initialization failed.');
        }
        localStorage.clear();
      }
      else if (response.data.status === "failure") {
        toast.error(`${response.data.errorMessage}`)
      }
    } catch (err) {
      console.error('Payment Error:', err);
    }
  };


  const initializeCheckoutcancel = async (product) => {
    
    setSelectedProduct(product);
    try {
      const response = await axios.post('http://localhost:7004/cancel-subscription', {
        subscriptionReferenceCode: 'bf5c5980-4eea-417d-a4df-fe8ae15976eb', // Add reference code from your subscription
        conversationId: product.conversationId // Conversation ID
      });
      console.log(response);

      if (response.data.status === 'success') {
        // Handle success (e.g., redirect or notify user)
      } else if (response.data.status === 'failure') {
        toast.error(`${response.data.errorMessage}`);
      }
    } catch (error) {
      console.error('Error initializing cancel:', error);
    }
  };


  // iyzico payment subcription payment 
  // iyzico payment subcription payment 
  // iyzico payment subcription payment 

  useEffect(() => {
    if (paymentGateways?.length > 0) {
      setActiveGateway(paymentGateways[0]?.Payment_Gateway_name);
    }
  }, [paymentGateways]);

  const handleGatewayClick = (gatewayName) => {
    const access_token = localStorage.getItem("access_token");
    if (!access_token) {
      // Redirect to login page if no access_token
      navigate(`/${lang}/login`);
    } else {
      // Otherwise, set the clicked gateway as active
      setActiveGateway(gatewayName);
    }
  };

  const staticContent = {
    Stripe: (
      <>
        <div>
          <ul className="nav nav-pills mb-3 payment_stripe_css">
            {stripeids?.map((tab, index) => (
              <>
                <li
                  className="nav-item align-items-center ms-2 paymentstripe_with_css"
                  role="presentation"
                  key={index}
                >
                  <div className="row col-lg-12">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 power_play_header_css mt-4">
                      <label className="power_play theme-text-color" htmlFor={tab?.id + "-tab"}>
                        {tab?.plans_name}
                      </label>
                      <p className="power_play_price mt-4 theme-text-color">
                        $ {tab?.price} {tab?.billing_interval}
                      </p>
                      <p className="power_play_price_save m-0 theme-text-color">
                        <div dangerouslySetInnerHTML={{ __html: tab && tab?.plan_content }} className='my-2'></div>
                      </p>
                    </div>


                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 padding_right_col">
                      <div className="row col-lg-12  mt-4">
                        <div className="justify-content-between row register_animate_text">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9"><h6>Days</h6></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.days}</div>
                        </div>
                        <div className="justify-content-between row register_animate_text mt-2">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9"><h6>Video Quality</h6></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.video_quality}</div>
                        </div>
                        <div className="justify-content-between row register_animate_text mt-2">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9"><h6>Plan Trail Days</h6></div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">{tab?.plan_trail_days}</div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-evenly free_video_plans_button">
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                      <div className="mt-2">
                        <button
                          className="btn theme-button-bg-color accessButton  bgButton"
                          onClick={() => checkoutstripepayment(tab?.plan_id)}
                        >
                          Start
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              </>

            ))}
          </ul>
        </div>
      </>
    ),
    PayPal: (
      <>
        <div>
          <ul
            className="nav nav-pills mb-3 payment_stripe_css"
            id="pills-tab"
            role="tablist"
          >
            {stripeid?.map((tab, index) => (
              <li
                className="nav-item align-items-center ms-2 paymentpaypal_with_css"
                role="presentation"
                key={index}
              >
                <div className="row col-lg-12">
                  <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
                    <label className="power_play theme-text-color" htmlFor={tab?.id + "-tab"}>
                      {tab?.title}
                    </label>

                    <p
                      className="power_play_OTTs mt-2 theme-text-color"
                      htmlFor={tab?.id + "-tab"}
                    >
                      {tab?.title}
                    </p>
                    <p className="power_play_price mt-4 theme-text-color">
                      $ {tab?.price} per yr
                    </p>
                    <p className="power_play_price_save m-0 theme-text-color">
                      Save upto <span>$ {tab?.price}</span>
                    </p>
                  </div>
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                    <div className="row col-lg-12">
                      {tab?.video_categories?.map((category) => (
                        <div
                          className="col-6 col-sm-6 col-md-6 col-lg-6"
                          key={category?.id}
                        >
                          <div className="free_video_plans_image">
                            <img
                              src={category?.src}
                              className="w-100 h-100"
                              alt={category?.title}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="row justify-content-evenly free_video_plans_button">
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                    <div className="mt-2">
                      <button
                        className="btn btn-primary bgButton"
                        onClick={() =>
                          checkoutstripepayment(Number(tab?.price))
                        }
                      >
                        Start
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    ),
    Razorpay: (
      <>
        <div>
          {" "}
          <ul
            className="nav nav-pills mb-3 payment_stripe_css"
            id="pills-tab"
            role="tablist"
          >
            {stripeid?.map((tab, index) => (
              <li
                className="nav-item align-items-center ms-2 paymentrazor_with_css"
                role="presentation"
                key={index}
              >
                <div className="row col-lg-12">
                  <div className="col-7 col-sm-7 col-md-7 col-lg-7 power_play_header_css mt-4">
                    <label className="power_play  theme-text-color" htmlFor={tab?.id + "-tab"}>
                      {tab?.title}
                    </label>

                    <p
                      className="power_play_OTTs mt-2 theme-text-color"
                      htmlFor={tab?.id + "-tab"}
                    >
                      {tab?.title}
                    </p>
                    <p className="power_play_price mt-4 theme-text-color">
                      $ {tab?.price} per yr
                    </p>
                    <p className="power_play_price_save m-0 theme-text-color">
                      Save upto <span>$ {tab?.price}</span>
                    </p>
                  </div>

                  <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                    <div className="row col-lg-12">
                      {tab?.video_categories?.map((category) => (
                        <div
                          className="col-6 col-sm-6 col-md-6 col-lg-6"
                          key={category?.id}
                        >
                          <div className="free_video_plans_image">
                            <img
                              src={category?.src}
                              className="w-100 h-100"
                              alt={category?.title}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="row justify-content-evenly free_video_plans_button">
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
                  <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                    <div className="mt-2">
                      <button
                        className="btn theme-button-bg-color accessButton  bgButton"
                        onClick={() => handlePaymentrazorpay(tab)}
                      >
                        Start
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </>
    ),
    Iyzico: (
      <>
        <div>
          <ul
            className="nav nav-pills mb-3 payment_stripe_css"
            id="pills-tab"
            role="tablist"
          >
            {formDataiyzico?.map((product, index) => {
              // Calculate the conversion rate
              // const price = ppv_priceppv_price[index] || product.price;
              // const price = (ppv_priceppv_price[index] || product.price).toFixed(2);
              const price = parseFloat(ppv_priceppv_price[index] || product.price).toFixed(2);


              return (
                <li
                  className="nav-item align-items-center ms-2 paymentiyzico_with_css"
                  role="presentation"
                  key={index}
                >
                  <div className="row col-lg-12">
                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 power_play_header_css mt-4">
                      <label className="power_play theme-text-color">
                        {product?.plans_name}
                      </label>

                      {/* <p
                        className="power_play_OTTs mt-2 theme-text-color"
                        htmlFor={`${product?.id}-tab`}
                      >
                        {product?.buyer?.name}
                      </p> */}
                      <p className="power_play_price mt-4 theme-text-color">
                        {currency?.code === null || currency?.code === ""
                          ? `${currencySymbol} ${price}`
                          : `${currencySymbol} ${price}`
                        } {product?.billing_interval}
                      </p>
                      <p
                        className="power_play_price_save m-0 theme-text-color mt-4"
                        dangerouslySetInnerHTML={{ __html: product?.plan_content }}
                      />
                    </div>

                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 padding_right_col">
                      <div className="row col-lg-12 mt-4">
                        <div className="justify-content-between row register_animate_text">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                            <h6>Days</h6>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            {product?.days}
                          </div>
                        </div>
                        <div className="justify-content-between row register_animate_text mt-2">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                            <h6>Video Quality</h6>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            {product?.video_quality}
                          </div>
                        </div>
                        <div className="justify-content-between row register_animate_text mt-2">
                          <div className="col-9 col-sm-9 col-md-9 col-lg-9">
                            <h6>Plan Trail Days</h6>
                          </div>
                          <div className="col-3 col-sm-3 col-md-3 col-lg-3">
                            {product?.plan_trail_days}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-evenly free_video_plans_button">
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5"></div>
                    <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                      <div className="mt-2">
                        <button
                          className="btn theme-button-bg-color accessButton bgButton"
                          onClick={() => handleProductClick(product)}
                        >
                          Start
                        </button>
                      </div>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>

      </>
    ),
  };

  // const staticContent = {
  //   Stripe: (
  //     <>
  //       <div>
  //         {stripeid?.map((item, index) => (
  //           <div className="col-lg-12 row" key={index}>
  //             <label>{item?.title}</label>
  //             <div className="col-lg-4">
  //               <button onClick={() => checkoutstripepayment(Number(item?.price))}>
  //                 {item?.price}
  //               </button>
  //             </div>
  //           </div>
  //         ))}
  //       </div>
  //     </>
  //   ),
  //   COD: "Static content for COD goes here.",
  //   Paypal: "Static content for Paypal goes here.",
  // };


  return (
    <section className="register_Payment  paddingTop">
      <div className="container register_content">
        <div className="row paymenttababoveheading">
          <div className="col-8">
            <Link>
              <p className="mt-3 theme-text-color">Home {">"} Subcription Plan </p>
            </Link>
          </div>
          <div className="col-12 col-sm-8 col-md-8 col-lg-8 mt-2">
            <h2 className="theme-text-color">
              Introducing bundled subscription plans at special introductory
              prices
            </h2>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="subcription_live_css plan-lineargrade my-4"></div>
          </div>
        </div>

        {/* <div className="container">
          {signup_username ? (
            <p className="signupnamecss"> Hello , {formattedDatatype}</p>
          ) : null}
        </div> */}
        <div className="container">
          <p className="signupnamecss theme-text-color"> Hello, {capitalizedUsername}</p>
        </div>
        <div className="container pt-3 "></div>

        <div className="row">
          {/* <div className="col-3 col-md-3 col-lg-3"></div> */}
          <div className="col-6 col-md-6 col-lg-8 ">
            <ul className="nav nav-pills pt-3" id="pills-tab" role="tablist">
              {paymentGateways?.map((gateway, index) => (
                <li
                  className="nav-item d-flex align-items-center ms-2 paymenttabheading"
                  role="presentation"
                  key={index}
                >
                  <input
                    type="radio"
                    className="nav-link"
                    id={`pills-${gateway?.Payment_Gateway_name}-tab`}
                    name="tab-radio"
                    value={gateway?.Payment_Gateway_name}
                    checked={activeGateway == gateway?.Payment_Gateway_name}
                    // onChange={() =>
                    //   handleGatewayClick(gateway?.Payment_Gateway_name)
                    // }
                    onClick={() => handleGatewayClick(gateway?.Payment_Gateway_name)}
                  />
                  <label
                    className="ms-2 theme-text-color"
                    htmlFor={`pills-${gateway?.Payment_Gateway_name}-tab`}
                  >
                    {gateway?.Payment_Gateway_name}
                  </label>
                </li>
              ))}
            </ul>
          </div>
          {/* <div className="col-3 col-md-3 col-lg-3"></div> */}
        </div>

        <div className="row">
          <div className="col-12 col-md-12 col-lg-12 offset-md-0">
            <div className="tab-content text-start" id="pills-tabContent">
              {paymentGateways?.map((gateway, index) => (
                <div
                  className={`tab-pane theme-text-color ${activeGateway == gateway?.Payment_Gateway_name
                    ? "show active"
                    : "fade"
                    }`}
                  id={`pills-${gateway?.Payment_Gateway_name}`}
                  role="tabpanel"
                  key={index}
                >
                  <p className="theme-text-color">{staticContent[gateway?.Payment_Gateway_name]}</p>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="text-center skiptohome">
          <div className="py-3">
            <label className=" theme-text-color">OR</label>
          </div>

          <div className="py-3">
            <Link onClick={handleSkipClick}>
              <button className="btn w-25 bgButton">Skip</button>
            </Link>
          </div>
        </div>
      </div>
    </section>

    // <section className="container">
    //   <div className="row">
    //     <div className="col-6 col-md-6 col-lg-6 offset-md-4">
    //       <ul className="nav nav-pills p-3 " id="pills-tab" role="tablist">
    //         {Listoftabheading?.map((tab, index) => (
    //           <li
    //             className="nav-item d-flex align-items-center ms-2"
    //             role="presentation"
    //             key={index}
    //           >
    //             <input
    //               type="radio"
    //               className="nav-link"
    //               id={tab?.id + "-tab"}
    //               name="tab-radio"
    //               value={tab?.id}
    //               checked={activeTab == tab.id}
    //               onChange={() => handleTabClick(tab?.id)}
    //             />
    //             <label className="ms-2" htmlFor={tab?.id + "-tab"}>
    //               {tab?.label}
    //             </label>
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //   </div>

    //   <div className="row">
    //     <div className="col-12 col-md-12 col-lg-12 offset-md-0">
    //       <div className="tab-content text-start " id="pills-tabContent">
    //         {Listoftabheading?.map((tab, index) => (
    //           <div
    //             className={`tab-pane ${
    //               activeTab == tab.id ? "show active" : "fade"
    //             } `}
    //             id={tab?.id}
    //             role="tabpanel"
    //             key={index}
    //           >
    //             <p>{staticContent[tab?.label]}</p>
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>

    //   {/* <div>
    //     <div className="tab-content text-start" id="pills-tabContent">
    //       {Listoftabheading?.map((tab, index) => (
    //         <div
    //           className={`tab-pane ${
    //             activeTab == tab.id ? "show active" : "fade"
    //           } `}
    //           id={tab?.id}
    //           role="tabpanel"
    //           key={index}
    //         >
    //           <p>{staticContent[tab?.label]}</p>
    //         </div>
    //       ))}
    //     </div>
    //   </div> */}
    // </section>
  );
}


const mapStateToProps = state => ({
  profileDetails: state.get_profileDetails_Reducer.profileDetails,
});
export default connect(mapStateToProps)(Register_Payments)

// export default Register_Payments;






// iyzico payment fo the integration done first stage ////////////////////////

// import React, { useEffect, useState } from 'react';

// const CheckoutForm = () => {
//   const [paymentUrliyzico, setPaymentUrliyzico] = useState({
//     apiKey: "YOUR_API_KEY",
//     secretKey: "YOUR_SECRET_KEY",
//     planId: "YOUR_PLAN_ID",
//     currency: "TRY",
//     price: 5.20,
//     locale: "en",
//     baseUrl: "https://sandbox-api.iyzipay.com",
//     merchantGatewayBaseUrl: "https://sandbox-merchantgw.iyzipay.com",
//     registerCardEnabled: true,
//     bkmEnabled: true,
//     bankTransferEnabled: true,
//     bankTransferTimeLimit: {"value":5,"type":"day"},
//     bankTransferRedirectUrl: "YOUR_CALLBACKURL",
//     bankTransferCustomUIProps: {},
//     campaignEnabled: false,
//     campaignMarketingUiDisplay: null,
//     paymentSourceName: "zooz",
//     plusInstallmentResponseList: null,
//     payWithIyzicoSingleTab: true,
//     payWithIyzicoOneTab: false,
//     mixPaymentEnabled: true,
//     creditCardEnabled: true,
//     userCards: [],
//     fundEnabled: true,
//     memberCheckoutOtpData: {},
//     force3Ds: false,
//     isSandbox: true,
//     storeNewCardEnabled: true,
//     paymentWithNewCardEnabled: true,
//     enabledApmTypes: ["SOFORT","IDEAL","QIWI","GIROPAY"],
//     payWithIyzicoUsed: false,
//     payWithIyzicoEnabled: true,
//     payWithIyzicoCustomUI: {},
//     buyerName: "Higher",
//     buyerSurname: "Faster",
//     merchantInfo: "",
//     merchantName: "Sandbox Merchant Name - 3389550",
//     cancelUrl: "",
//     buyerProtectionEnabled: false,
//     hide3DS: false,
//     gsmNumber: "+905555434332",
//     email: "stronger@implementation.com",
//     checkConsumerDetail: {},
//     subscriptionPaymentEnabled: false,
//     ucsEnabled: false,
//     fingerprintEnabled: false,
//     payWithIyzicoFirstTab: false,
//     creditEnabled: true,
//     payWithIyzicoLead: false,
//     goBackUrl: "",
//   });

//   useEffect(() => {
//     const script = document.createElement('script');
//     script.type = 'text/javascript';
//     script.innerHTML = `
//       if (typeof iyziInit == 'undefined') {
//         var iyziInit = {
//           apiKey: "${paymentUrliyzico?.apiKey}",
//           secretKey: "${paymentUrliyzico?.secretKey}",
//           currency: "${paymentUrliyzico?.currency}",
//           price: ${paymentUrliyzico?.price},
//           locale: "${paymentUrliyzico?.locale}",
//           baseUrl: "${paymentUrliyzico?.baseUrl}",
//           merchantGatewayBaseUrl: "${paymentUrliyzico?.merchantGatewayBaseUrl}",
//           registerCardEnabled: ${paymentUrliyzico?.registerCardEnabled},
//           bkmEnabled: ${paymentUrliyzico?.bkmEnabled},
//           bankTransferEnabled: ${paymentUrliyzico?.bankTransferEnabled},
//           bankTransferTimeLimit: ${JSON.stringify(paymentUrliyzico?.bankTransferTimeLimit)},
//           bankTransferRedirectUrl: "${paymentUrliyzico?.bankTransferRedirectUrl}",
//           bankTransferCustomUIProps: ${JSON.stringify(paymentUrliyzico?.bankTransferCustomUIProps)},
//           campaignEnabled: ${paymentUrliyzico?.campaignEnabled},
//           campaignMarketingUiDisplay: ${paymentUrliyzico?.campaignMarketingUiDisplay},
//           paymentSourceName: "${paymentUrliyzico?.paymentSourceName}",
//           plusInstallmentResponseList: ${paymentUrliyzico?.plusInstallmentResponseList},
//           payWithIyzicoSingleTab: ${paymentUrliyzico?.payWithIyzicoSingleTab},
//           payWithIyzicoOneTab: ${paymentUrliyzico?.payWithIyzicoOneTab},
//           mixPaymentEnabled: ${paymentUrliyzico?.mixPaymentEnabled},
//           creditCardEnabled: ${paymentUrliyzico?.creditCardEnabled},
//           userCards: ${JSON.stringify(paymentUrliyzico?.userCards)},
//           fundEnabled: ${paymentUrliyzico?.fundEnabled},
//           memberCheckoutOtpData: ${JSON.stringify(paymentUrliyzico?.memberCheckoutOtpData)},
//           force3Ds: ${paymentUrliyzico?.force3Ds},
//           isSandbox: ${paymentUrliyzico?.isSandbox},
//           storeNewCardEnabled: ${paymentUrliyzico?.storeNewCardEnabled},
//           paymentWithNewCardEnabled: ${paymentUrliyzico?.paymentWithNewCardEnabled},
//           enabledApmTypes: ${JSON.stringify(paymentUrliyzico?.enabledApmTypes)},
//           payWithIyzicoUsed: ${paymentUrliyzico?.payWithIyzicoUsed},
//           payWithIyzicoEnabled: ${paymentUrliyzico?.payWithIyzicoEnabled},
//           payWithIyzicoCustomUI: ${JSON.stringify(paymentUrliyzico?.payWithIyzicoCustomUI)},
//           buyerName: "${paymentUrliyzico?.buyerName}",
//           buyerSurname: "${paymentUrliyzico?.buyerSurname}",
//           merchantInfo: "${paymentUrliyzico?.merchantInfo}",
//           merchantName: "${paymentUrliyzico?.merchantName}",
//           cancelUrl: "${paymentUrliyzico?.cancelUrl}",
//           buyerProtectionEnabled: ${paymentUrliyzico?.buyerProtectionEnabled},
//           hide3DS: ${paymentUrliyzico?.hide3DS},
//           gsmNumber: "${paymentUrliyzico?.gsmNumber}",
//           email: "${paymentUrliyzico?.email}",
//           checkConsumerDetail: ${JSON.stringify(paymentUrliyzico?.checkConsumerDetail)},
//           subscriptionPaymentEnabled: ${paymentUrliyzico?.subscriptionPaymentEnabled},
//           ucsEnabled: ${paymentUrliyzico?.ucsEnabled},
//           fingerprintEnabled: ${paymentUrliyzico?.fingerprintEnabled},
//           payWithIyzicoFirstTab: ${paymentUrliyzico?.payWithIyzicoFirstTab},
//           creditEnabled: ${paymentUrliyzico?.creditEnabled},
//           payWithIyzicoLead: ${paymentUrliyzico?.payWithIyzicoLead},
//           goBackUrl: "${paymentUrliyzico?.goBackUrl}",
//           metadata : ${JSON.stringify(paymentUrliyzico?.metadata)},
//           createTag: function() {
//             var iyziJSTag = document.createElement('script');
//             iyziJSTag.setAttribute('src', 'https://sandbox-static.iyzipay.com/checkoutform/v2/bundle.js?v=1687112732810');
//             document.head.appendChild(iyziJSTag);
//           }
//         };
//         iyziInit.createTag();
//       }
//     `;
//     document.head.appendChild(script);
//   }, [paymentUrliyzico]);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setPaymentUrliyzico(prevState => ({
//       ...prevState,
//       [name]: value
//     }));
//   };

//   return (
//     <div>
//       <label>
//         API Key:
//         <input type="text" name="apiKey" value={paymentUrliyzico?.apiKey} onChange={handleChange} />
//       </label>
//       <label>
//         Secret Key:
//         <input type="text" name="secretKey" value={paymentUrliyzico?.secretKey} onChange={handleChange} />
//       </label>
//       <label>
//         Plan ID:
//         <input type="text" name="planId" value={paymentUrliyzico?.planId} onChange={handleChange} />
//       </label>
//       {/* Add other input fields as needed */}
//       <button onClick={() => window.location.reload()}>Subscribe</button>
//     </div>
//   );
// };

// export default CheckoutForm;
