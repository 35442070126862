import React, { useRef } from 'react'
import { FcBarChart, FcBookmark, FcLike } from 'react-icons/fc'
import { Link, useParams } from 'react-router-dom'
import Playbtn from "../../../../components/Images/banner_play_button.png"
import { MdOutlineArrowOutward } from "react-icons/md";
import { useTranslation } from 'react-i18next'

//page props ->  movie,show,home

const BannerOverlay = ({ data, type, page }) => {
    const overlayRef = useRef()
    const { t } = useTranslation();
    const { lang } = useParams();
    const loremText = t("Kristoff in Frozen 3. Disney CEO Bob Iger announced that a third Frozen movie was currently in development during a year-end earnings call in February 2023");

    const handleContextMenu = (e) => {
        e.preventDefault();
    };
    const handleKeyDown = (e) => {
        if (e.key) {
            e.preventDefault();
        }
        if (e.key === 'PrintScreen' || (e.ctrlKey && e.key === 'S')) {
            e.preventDefault();
        }
    };

    // overlayRef?.current?.addEventListener('contextmenu', handleContextMenu);
    // overlayRef?.current?.addEventListener('keydown', handleKeyDown);

    return (
        <div className={`${page == "movie" ? "px-2 px-md-3 px-lg-5" : ""} bannerPopContainer active`} ref={overlayRef}>
            <div className="d-flex flex-column justify-content-around w-50 h-100 py-md-4 ">
                <img src={data?.TV_image_url ? data?.TV_image_url : data?.TV_Thumbnail ? data?.TV_Thumbnail : data?.slider_image ? data?.image_url : data?.Player_image_url} className="bannerLogoImage object-fit-cover  overflow-hidden" />
                <p className="theme-text-color bannerPopTitle m-0 ">{t(data?.title)}</p>
                {!data?.trailer_link && <>
                    <span className={`theme-text-color ${page == "home" ? "bannerPopDesc" : page == "movie" ? "bannerMoviePopDesc" : ""}  d-block `}>{t(data?.description || data?.episode_description)}</span>
                    <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                            {/* <span className="bannerPopSpecial d-flex align-items-center "> <FcLike className="me-1 theme-text-color" /> <span className="me-2">{data?.rating}  </span> </span> */}
                            {data?.trailer_link && <><span className="bannerPopSpecial d-flex align-items-center "> <FcBarChart className="me-1 theme-text-color" /><span className="me-2">{data?.duration} </span> </span></>}
                            {/* <span className="bannerPopSpecial d-flex align-items-center "><FcBookmark className="me-1 theme-text-color" /> <span className="me-2">{data?.year} </span> </span> */}
                        </div>
                    </div>
                </>}
            </div>
            <div className="d-flex align-items-center justify-content-end bannerPlayBtn">
                <Link to={`${data?.source_types_id !== null ? `/${lang}/${data?.source_types_id == 1 ? 'videos' : data?.source_types_id == 2 ? 'live' : data?.source_types_id === 3 ? 'shows' : data?.source_types_id == 4 ? `series/${data?.series_slug}` : data?.source_types_id == 5 ? 'music/track' : ''}/${data?.slug}` : data?.link} `} className="homeListButton m-0 px-2 py-2 bgButton d-flex align-items-center justify-content-center bannerButton position-relative">
                    {/* {type == "video" ? <img src={Playbtn} className="homeBannerPlayImage" alt="playbutton" /> : <MdOutlineArrowOutward />} */}
                    <img src={Playbtn} className="homeBannerPlayImage" alt="playbutton" />
                </Link>
            </div>
        </div >
    )
}

export default BannerOverlay