import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import InfoBox from './CommonComponents/InfoBox';
import MessageBox from '../../components/Others/MessageBox';
import { IconMyAddPlaylist, IconMyAddStation, IconMyGrid, IconMyHome, IconMyList, IconMyLogIn, IconMyLogOut, IconMyPlaylist } from '../../Images/MyIcons';
import LazyLoadImage from '../HomePage/HomeSwiperComponents/LazyLoadImage';
import MusicLogo from "./Assets/musiclogo.png"
import { getItem, getItemToken } from '../../Utils/localStorageUtils';
import { LogOutApi } from '../../Redux/Actions/apiActions';
import { connect, useDispatch } from 'react-redux';
const MusicSideBar = ({ authAction, getMusicData, playlistShow, initialModal, stationModal, languageDetailsData, minimize, layout, getSource, className, closeSideBar }) => {
    const navigate = useNavigate();
    const location = useLocation()
    const dispatch = useDispatch()
    const { source } = useParams()

    const userRoleLocal = getItem('role')
    const userIdLocal = getItem('user_id')
    const userAccessToken = getItemToken('access_token');
    const accessOnly = getItem('access_token')


    const goBack = () => {
        navigate(-1);
    };
    const goForward = () => {
        navigate(1);
    };

    const handlePlaylistModal = () => {
        dispatch({ type: "OPEN_INITIAL_MODAL", payload: true })
        closeSideBar()
    };
    const handleShowPlaylist = () => {
        dispatch({ type: "OPEN_PLAYLIST", payload: !playlistShow })
        closeSideBar()
    }
    const handleShowMusicStation = () => {
        dispatch({ type: "OPEN_STATION", payload: !stationModal })
        closeSideBar()
    }
    const handleHomepage = () => {
        dispatch({ type: "HOME_PAGE_MUSIC", payload: "home" })
        navigate(`/${languageDetailsData?.Language_Code}/music`);
        closeSideBar()
    }
    const handleLayout = (value) => {
        dispatch({ type: "LAYOUT_SWITCHER", payload: value })
        closeSideBar()

    }
    const handleLogOut = () => {
        dispatch(LogOutApi(navigate))
    }

    useEffect(() => {
        const currentPath = location.pathname;
        if (currentPath.includes("music") && currentPath.includes("track")) {
            dispatch({ type: "HOME_PAGE_MUSIC", payload: "player" });
        } else if (currentPath.includes("music")) {
            dispatch({ type: "HOME_PAGE_MUSIC", payload: "home" });
        }
    }, [location]);

    const [isMatch, setIsMatch] = useState(false);
    useEffect(() => {
        if (getSource.includes(source)) {
            setIsMatch(true);
        } else {
            setIsMatch(false);
        }
    }, [location]);

    const handleCloseInfo = () => {
        setIsMatch(false);
    }

    return (
        <>
            <div className={`musicSideBar px-2 py-4 py-md-2  ${getMusicData?.enable_floating && !minimize ? 'activeFloat' : "inActiveFloat"} slide-left ${className}`}>
                <div className='py-lg-1 py-2 w-100 h-100 d-flex justify-content-between  flex-column px-2  rounded-3 '>
                    <Link to={`/${languageDetailsData?.Language_Code}/music`}>
                        <LazyLoadImage key={'logo'} src={MusicLogo} alt={'logo'} title={'M'} imageSize="rounded-2  overflow-hidden musicLogoImage object-fit-contain " classNameOptional="rounded-circle musicLogoImageHeight  align-items-center  justify-content-center mx-auto d-none d-md-flex " />
                    </Link>
                    <div className='d-flex align-items-center flex-column'>
                        <button type="button" onClick={handleHomepage} className={`${location.pathname == "/" + languageDetailsData?.Language_Code + "/music" && 'active'}  music-link   d-flex  justify-content-lg-center bg-transparent theme-text-color`}><IconMyHome styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /><MessageBox classname='sideBarText d-none' text="Home" /></button>
                        <button type="button" onClick={handleShowPlaylist} className={`${playlistShow && 'active'} music-link   d-flex  justify-content-lg-center bg-transparent theme-text-color`}><IconMyPlaylist styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /><MessageBox classname='sideBarText d-none' text="Playlist" /></button>
                        <button type="button" onClick={handlePlaylistModal} className={`${initialModal && 'active'} music-link  d-flex  justify-content-lg-center bg-transparent theme-text-color`} ><IconMyAddPlaylist styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /><MessageBox classname='sideBarText d-none' text="Move playlist" /></button>
                        <button type="button" onClick={handleShowMusicStation} className={`${stationModal && 'active'} music-link   d-flex  justify-content-lg-center bg-transparent theme-text-color`}><IconMyAddStation styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /><MessageBox classname='sideBarText d-none' text="Make station" /></button>
                        <div class="dropdown position-relative music-link">
                            <a class="bg-transparent border-0  p-0 dropdown-toggle " href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false"><IconMyGrid styled={{ width: "15px", height: "15px", className: "musicSideBarIcons" }} /> <MessageBox classname='sideBarText d-none' text="Layout" />  </a>
                            <ul class="dropdown-menu theme-bg-color" aria-labelledby="dropdownMenuLink">
                                <li><div onClick={() => handleLayout("grid")} className={`p-2 d-flex gap-2 align-items-center w-100 ${layout == "grid" && "theme-bg-color-secondary"}`}> <IconMyGrid styled={{ width: "12px", height: "12px", className: " theme-text-color" }} /><span className='theme-text-color'>Grid</span> </div></li>
                                <li><div onClick={() => handleLayout("list")} className={`p-2 d-flex gap-2 align-items-center w-100 ${layout == "list" && "theme-bg-color-secondary"}`}><IconMyList styled={{ width: "12px", height: "12px", className: "theme-text-color" }} /><span className='theme-text-color'>List</span></div></li>
                            </ul>
                        </div>
                    </div>

                    {userRoleLocal ?
                        <button onClick={() => handleLogOut()} className={`bg-transparent theme-text-color`}> <IconMyLogOut styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} /></button>
                        :
                        <button type="button" onClick={authAction} className={`bg-transparent theme-text-color`}><IconMyLogIn styled={{ width: "20px", height: "20px", className: "musicSideBarIcons" }} /></button>
                    }
                </div>
            </div>
        </>

    )
}

const mapStateToProps = state => ({
    getMusicData: state.get_Music_Settings.getMusicData,
    playlistShow: state.get_Playlist_Modal.playlistShow,
    stationModal: state.get_Playlist_Modal.stationModal,
    initialModal: state.get_Playlist_Modal.initialModal,
    purchaseModal: state.get_Playlist_Modal.purchaseModal,
    languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
    minimize: state.get_Playlist_Modal.minimize,
    layout: state.get_Playlist_Modal.layout,
    getSource: state.get_Music_Home_Page_Reducer.getSource,
});
export default connect(mapStateToProps)(MusicSideBar)