import { Link, useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect, useCallback } from "react";
import axios, { CancelToken, isCancel } from "axios";
import { ProgressBar } from "react-bootstrap";
import Box from "@mui/material/Box";
import Select from "react-select";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";

import Typography from "@mui/material/Typography";
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { useDropzone } from "react-dropzone";
import Dropzone from "react-dropzone";
import "./EditVideo.css";
import "vidstack/styles/defaults.css";
import { defineCustomElements } from "vidstack/elements";
import "vidstack/styles/base.css";
import "vidstack/styles/ui/buttons.css";
import "vidstack/styles/ui/buffering.css";
import "vidstack/styles/ui/captions.css";
import "vidstack/styles/ui/tooltips.css";
import "vidstack/styles/ui/live.css";
import "vidstack/styles/ui/sliders.css";
import "vidstack/styles/ui/menus.css";
import "vidstack/define/media-player.js";
import ReactPlayer from "react-player";
import "react-awesome-slider/dist/styles.css";
import loadings from "../../../components/Images/loading/loading.gif";
import deleteitem from "../../../components/Images/deleteitem.svg";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import AdminCustomButton from "../../../components/Others/adminCommonComponents/AdminCustomButton";
import { useTranslation } from "react-i18next";

export default function Editvideo() {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const { id } = useParams();

  const [videoUrl, setVideoUrl] = useState("");
  const [Subtitlesmap, setSubtitlesmap] = useState([]);
  const [reelsvideoget, setReelsvideoget] = useState([]);
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [Block_Country, setBlock_Country] = useState([]);
  const [Available_country, setAvailable_country] = useState([]);
  const [trailer, setTrailer] = useState("");
  const [Video_Title_Thumbnail, setVideo_Title_Thumbnail] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [video_tv_image, setVideo_tv_image] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [pdf_filesupload, setpdf_filesupload] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [video_title_image, setVideo_title_image] = useState("");
  const [loaderText, setLoaderText] = useState("processing")

  const [edittitle, setEdittitle] = useState({
    title: "",
  });

  const [editUser, setEditUser] = useState({
    title: "",
    slug: "",
    id: "",
    draft: "",
    user_id: "",
    type: "",
    status: "",
    active: "",
    rating: "",
    duration: "",
    description: "",
    details: "",
    image: "",
    mobile_image: "",
    tablet_image: "",
    player_image: "",
    video_tv_image: "",
    enable_video_title_image: "",
    video_title_image: "",
    android_tv: "",
    video_gif: "",
    search_tags: "",
    access: "",
    global_ppv: "",
    ppv_price: "",
    ios_ppv_price: "",
    featured: "",
    banner: "",
    enable: "",
    embed_code: "",
    mp4_url: "",
    m3u8_url: "",
    webm_url: "",
    ogg_url: "",
    disk: "",
    original_name: "",
    path: "",
    stream_path: "",
    processed_low: "",
    converted_for_streaming_at: "",
    views: "",
    year: "",
    age_restrict: "",
    Recommendation: "",
    country: "",
    publish_status: "",
    publish_type: "",
    publish_time: "",
    skip_start_time: "",
    skip_end_time: "",
    skip_start_session: "",
    recap_start_time: "",
    recap_end_time: "",
    recap_start_session: "",
    pdf_files: "",
    reels_thumbnail: "",
    reelvideo: "",
    url: "",
    url_link: "",
    url_linktym: "",
    url_linksec: "",
    urlEnd_linksec: "",
    trailer_type: "",
    trailer: "",
    trailer_description: "",
    ads_status: "",
    default_ads: "",
    pre_ads_category: "",
    mid_ads_category: "",
    post_ads_category: "",
    mid_ads: "",
    pre_ads: "",
    post_ads: "",
    ads_tag_url_id: "",
    free_duration_time: "",
    free_duration_status: "",
    time_zone: "",
    home_genre: "",
    in_menu: "",
    footer: "",
    uploaded_by: "",
    language: "",
    video_category_id: "",
    Video_reels_video_url: "",
    video_categories: "",
    video_block_countries: "",
    artists: "",
    realted_videos: "",
    Thumbnail: "",
    Player_thumbnail: "",
    TV_Thumbnail: "",
    Video_Title_Thumbnail: "",
    Video_reels_Thumbnail: "",
    Video_upload_url: "",
    website_page_title: "",
    website_URL: "",
    Meta_description: "",
    Video_reels_video: "",
    EPaper: "",
    trailer_filename: "",
  });


  const [selectedFilepdf, setSelectedFilepdf] = useState(null);

  const handleFileChangepdfupload = (event) => {
    setSelectedFilepdf(event.target.files[0]);
  };
  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };


  const [publish_type, setPublish_type] = useState("");
  const [user_access, setUser_access] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);
  const navigate = useNavigate();

  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(true);

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");

  const handleTagsChange = (newTags) => {
    setTags(newTags);
  };

  const handleInputChangetag = (input) => {
    setNewTag(input);
  };

  const handleInputKeyPress = (event) => {
    if (event.key == "Enter" || event.key == " ") {
      event.preventDefault(); // Prevent the default behavior (adding space or newline)

      // Only add the tag if it's not empty
      if (newTag.trim() !== "") {
        setTags([...tags, newTag]);
        setNewTag("");
      }
    }
  };

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
    // console.log(selectedValue5); // Selected value
  };

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
    // alert(event.target.value);
  };

  const handleChangeFirst = (e) => {
    setEditUser({ ...editUser, details: e });
    // console.log(e, "onchnageddddd");
  };
  const handleChangeFirstdes = (e) => {
    setEditUser({ ...editUser, trailer_description: e });
    // console.log(e, "onchnageddddd");
  };

  const [videosreels, setVideosreels] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);

  const handleDropreels = (acceptedFiles) => {
    setVideosreels(acceptedFiles);
  };

  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };

  const [selectedFiles, setSelectedFiles] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  const [selectedFilesreels, setSelectedFilesreels] = useState(null);

  const onDropreels = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles[0]);
  }, []);

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);

  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4,
    setSelectedFile5
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
    setSelectedFile5(null);
    if (fileInputRef5.current) {
      fileInputRef5.current.value = null;
    }
  };

  // MULTI SELECT NEW
  const [options, setOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);

  const [optionsrelated_video, setOptionsrelated_video] = useState([]);
  const [selectedValuesrelated_video, setSelectedValuesrelated_video] =
    useState([]);

  const [optionsartists, setOptionsartists] = useState([]);
  const [selectedValuesartists, setSelectedValuesartists] = useState([]);

  const [optionscategories, setOptionscategories] = useState([]);
  const [selectedValuescategories, setSelectedValuescategories] = useState([]);

  const [optionslanguages, setOptionslanguages] = useState([]);
  const [selectedValueslanguages, setSelectedValueslanguages] = useState([]);

  const [optionsavailable_country, setOptionsAvailable_country] = useState([]);
  const [selectedValuesavailable_country, setSelectedValuesAvailable_country] =
    useState([]);

  const [optionsAge_Restrict, setOptionsAge_Restrict] = useState([]);
  const [selectedValuesAge_Restrict, setSelectedValuesAge_Restrict] = useState(
    []
  );

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");
  const [formDatapublishedit, setFormDatapublishedit] = useState(
    new FormData()
  );

  const [formData, setFormData] = useState({
    // block_country: [],
    // Available_country: [],
    // related_video: [],
    // artists: [],
    // categories: [],
    // languages: [],
    // Age_Restrict: [],
    // ...editInputvalue,
    // other form data properties...
  });

  // const [languages, setLanguages] = useState([]);
  // const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setShowOverlay(true)
      setLoaderText("Data fetching")
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-edit/${id}`,
        { headers: headers }
      );
      if (response.data.status) {
        setShowOverlay(false)
      }
      const data = response?.data?.videos[0].video_block_countries;
      const test = response?.data;
      const dataavailable_country =
        response?.data?.videos[0].available_countries;
      const dataartists = response?.data?.videos[0].artists;
      const datacategories = response?.data?.videos[0].video_categories;
      const datalanguages = response?.data?.videos[0].Language;
      const dataAge_Restrict = response?.data?.selected_Age_Restrict;
      const datarelated_video = response?.data?.videos[0].realted_videos;
      // console.log(typeof data)
      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
      const subtitlesmap = response?.data?.video_subtitle;
      const reelsvideoget = response?.data?.videos[0].Video_reels_video_url;
      setSubtitlesmap(subtitlesmap);
      setReelsvideoget(reelsvideoget);
      console.log(reelsvideoget);

      var res = response?.data?.videos[0];
      var globelset = response?.data?.ppv_gobal_price;
      var age_restrictdata = response?.data?.Age_Restrict;
      setAge_restrictdata(age_restrictdata);
      // setInputValueAPI(globelset);
      // console.log(datacategories)
      setEditUser(res);
      setEdittitle(res);
      setPublishType(res.publish_type);
      setPublishTime(res.publish_time);
      // setPublishType(res);
      // setPublishTime(mockApiResponse.publish_time);

      setLoading(false);

      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item?.country_id,
        label: item?.name,
      }));

      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item?.id,
          label: item?.name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item?.artist_id,
        label: item?.name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item?.category_id,
        label: item?.name,
      }));

      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item?.language_id,
        label: item?.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item?.id,
        label: item?.name,
      }));
      const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
        value: item?.related_videos_id,
        label: item?.title,
      }));

      // Set the initial selected values
      setSelectedValues(formattedOptions);
      setOptions(formattedOptions);

      setSelectedValuesrelated_video(formattedOptionsrelated_video);
      setOptionsrelated_video(formattedOptionsrelated_video);

      setSelectedValuesAvailable_country(formattedOptionsavailable_country);
      setOptionsAvailable_country(formattedOptionsavailable_country);

      setSelectedValuesartists(formattedOptionsartists);
      setOptionsartists(formattedOptionsartists);

      setSelectedValuescategories(formattedOptionscategories);
      setOptionscategories(formattedOptionscategories);

      setSelectedValueslanguages(formattedOptionslanguages);
      setOptionslanguages(formattedOptionslanguages);

      setSelectedValuesAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      // console.log(formattedOptions);
      // console.log(formattedOptions);

      setTags(
        response.data.videos[0].search_tags.split(",").map((tag) => tag.trim())
      );


    } catch (error) {
      console.error(error);
      setLoading(false);
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`,
        { headers: headers }
      );
      const data = response?.data?.Block_Country;
      const dataavailable_country = response?.data?.Available_country;
      const datarelated_video = response?.data?.Related_videos;
      const dataartists = response?.data?.video_artist;
      const datacategories = response?.data?.video_category;
      const datalanguages = response?.data?.video_language;
      const dataAge_Restrict = response?.data?.Age_Restrict;

      // setSelectedLanguages(data)
      // console.log(data);

      const globelset = response?.data?.ppv_gobal_price;
      setInputValueAPI(globelset);
      // console.log(data);
      // console.log(subtitlesmap, "subtitlesmap");
      // Extract the selected data from the response
      const selectedData = data;
      const formattedOptions = selectedData?.map((item) => ({
        value: item.id,
        label: item.country_name,
      }));
      const formattedOptionsavailable_country = dataavailable_country?.map(
        (item) => ({
          value: item.id,
          label: item.country_name,
        })
      );
      const formattedOptionsartists = dataartists?.map((item) => ({
        value: item.id,
        label: item.artist_name,
      }));
      const formattedOptionscategories = datacategories?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      const formattedOptionslanguages = datalanguages?.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      const formattedOptionsAge_Restrict = dataAge_Restrict?.map((item) => ({
        value: item.id,
        label: item.age,
      }));
      const formattedOptionsrelated_video = datarelated_video?.map((item) => ({
        value: item.id,
        label: item.title,
      }));

      // Set the options for the multi-select component
      setOptions(formattedOptions);
      setOptionsAvailable_country(formattedOptionsavailable_country);
      setOptionsartists(formattedOptionsartists);
      setOptionscategories(formattedOptionscategories);
      setOptionslanguages(formattedOptionslanguages);
      setOptionsAge_Restrict(formattedOptionsAge_Restrict);
      setOptionsrelated_video(formattedOptionsrelated_video);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
      // console.log(formattedOptions);
    } catch (error) {
      console.error(error);
    }
  };
  // const handleLanguageChange = (event) => {
  //   const selectedOptions = Array.from(event.target.selectedOptions, option => option.value);
  //   setSelectedLanguages(selectedOptions);
  // };

  const handleSelectChange = (selectedOptions) => {
    const block_country = selectedOptions.map((option) => option.value);

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // block_country_id: block_country,
    });

    setSelectedValues(selectedOptions);
  };
  const handleSelectChangeavailable_country = (
    selectedOptionsavailable_country
  ) => {
    const available_country = selectedOptionsavailable_country?.map(
      (option) => option.value
    );

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // available_country_id: available_country,
    });

    setSelectedValuesAvailable_country(selectedOptionsavailable_country);
  };
  const handleSelectChangeartists = (selectedOptionsartists) => {
    const artists = selectedOptionsartists?.map((option) => option.value);

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // artist_id: artists,
    });

    setSelectedValuesartists(selectedOptionsartists);
  };

  const handleSelectChangecategories = (selectedOptionscategories) => {
    const categories = selectedOptionscategories?.map((option) => option.value);

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // category_id: categories,
    });
    setIsValidationHiddencategory(true);
    setSelectedValuescategories(selectedOptionscategories);
  };

  const handleSelectChangerelated_video = (selectedOptionsrelated_video) => {
    const related_video = selectedOptionsrelated_video?.map(
      (option) => option.value
    );

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // related_videos: related_video,
    });

    setSelectedValuesrelated_video(selectedOptionsrelated_video);
  };

  const handleSelectChangelanguages = (selectedOptionslanguages) => {
    const languages = selectedOptionslanguages?.map((option) => option.value);

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // languages: languages,
    });
    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionslanguages);
  };
  const handleSelectChangeAge_Restrict = (selectedOptionsAge_Restrict) => {
    const Age_Restrict = selectedOptionsAge_Restrict?.map(
      (option) => option.value
    );

    setFormData({
      // ...formData,
      // ...editInputvalue,
      // Age_Restrict: Age_Restrict,
    });

    setIsValidationHiddenlanguages(true);
    setSelectedValueslanguages(selectedOptionsAge_Restrict);
  };

  // MULTI SELECT NEW

  const convertToTitleCase = (str) => {
    return str
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const formattedDatatype = convertToTitleCase(editUser?.type);

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue == "publish_now") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Now"
      formDatapublishedit.delete("publish_time"); // Remove publish_time from FormData
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
    formDatapublishedit.set("publish_time", timeValue);
    // formDatapublishedit.set('publish_status', 'your_publish_status'); // Replace with your actual value
    formDatapublishedit.set("publish_type", publishType);
  };

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response?.json();
          // console.log(data);

          const adsPositionOptions = data?.ads_position?.map((position) => ({
            value: position?.position,
            label: position?.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response?.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`, {
        headers: headers,
      })
      .then((response) => {
        // console.log('api checks', response.data.livestream_language)
        var age_restrictdata = response?.data?.Age_Restrict;
        var ads_category = response?.data?.ads_category;
        var Block_Country = response?.data?.Block_Country;
        var Available_country = response?.data?.Available_country;
        var user_access = response?.data?.user_access;
        setAge_restrictdata(age_restrictdata);
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);
        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
        // console.log(Available_country)

        setBlock_Country(Block_Country);
        setAvailable_country(Available_country);
      })
      .catch((error) => console.log(error));
  }, [id]);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data?.Advertisement?.map((position) => ({
            value: position?.id,
            label: position?.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition])

  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-lg-12 row mt-2">
            {editUser?.trailer ? (
              <div className="">
                <label className="my-1 theme-text-color admin-input-title"> Trailer Video </label>
                <div className="col-lg-12 row mt-2">
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                    <media-player
                      // title={editUser?.Video_reels_video}
                      src={editUser?.trailer}
                      poster=""
                      controls
                    >
                      <media-outlet></media-outlet>
                    </media-player>
                  </div>
                  <div className="col-6 col-sm-6 col-md-6 col-lg-6  align-self-center">
                    <Link to="" className="delete ms-1">
                      <span onClick={() => deleteOperationtrailer(id)}>
                        <img
                          src={deleteitem}
                          alt="flogo"
                          width={20}
                          height={20}
                        />
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="">
              <label className="my-2 theme-text-color admin-input-title"> Upload Trailer </label>

              <div className="file-draganddrop theme-border-color">
                <div
                  {...getRootProps()}
                  className={`mt-3 drop-area ${isDragActive ? "active" : ""}`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className=" theme-text-color admin-input-title">Drop the video file here...</p>
                  ) : (
                    <p className=" theme-text-color admin-input-title">Drag and drop your video file here</p>
                  )}
                </div>
                {selectedFiles && (
                  <div>
                    <p className=" theme-text-color admin-input-title">{selectedFiles?.name}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              {editUser?.trailer ? (
                <div className="">
                  <label className="my-2 admin-input-title theme-text-color"> M3u8 Video </label>

                  <div>
                    <media-player
                      // title={editUser?.Video_reels_video}
                      src={editUser?.trailer}
                      poster=""
                      controls
                    >
                      <media-outlet></media-outlet>
                    </media-player>
                  </div>
                </div>
              ) : null}
              <div className="col-sm-12 mt-3 text-start">
                <div className="">
                  <label className="mb-1 theme-text-color">Trailer m3u8 URL </label>
                  <input
                    type="text"
                    className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                    name="trailer"
                    onChange={handleInput}
                    value={editUser?.trailer}
                    placeholder="m3u8 URL"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              {editUser?.trailer ? (
                <div className="">
                  <label className="my-2 admin-input-title theme-text-color"> Mp4 Video </label>

                  <div>
                    <media-player
                      // title={editUser?.Video_reels_video}
                      src={editUser?.trailer}
                      poster=""
                      controls
                    >
                      <media-outlet></media-outlet>
                    </media-player>
                  </div>
                </div>
              ) : null}
              <div className="col-sm-12 mt-3 text-start">
                <div className="">
                  <label className="mb-1 theme-text-color"> Trailer Mp4 File URL </label>
                  <input
                    type="text"
                    className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                    name="trailer"
                    onChange={handleInput}
                    value={editUser?.trailer}
                    placeholder="mp4 Trailer"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="col-lg-12 row mt-2">
              {editUser?.trailer ? (
                <div className="">
                  <label className="my-2 admin-input-title theme-text-color"> Embed Video </label>

                  <div>
                    <media-player
                      // title={editUser?.Video_reels_video}
                      src={editUser?.trailer}
                      poster=""
                      controls
                    >
                      <media-outlet></media-outlet>
                    </media-player>
                  </div>
                </div>
              ) : null}
              <div className="col-sm-12 mt-3 text-start">
                <div className="">
                  <label className="my-2 admin-input-title theme-text-color"> Trailer Embed URL </label>
                  <input
                    type="text"
                    className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                    name="trailer"
                    placeholder="Embed URL"
                    onChange={handleInput}
                    value={editUser?.trailer}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="row mt-3 mx-0 text-start ">
            <div className="col-sm-12 px-0">
              <label className="my-2 theme-text-color admin-input-title">PPV Price</label>
              <div className="row mx-0 mb-2">
                <div className="col-8 col-lg-8 ps-0">
                  <p className=" theme-text-color opacity-75 admin-input-description">Apply PPV Price from Global Settings? </p>
                </div>
                <div className="col-4 col-lg-4 ps-0 text-end ">
                  <label className="switch">
                    <input
                      name="ppv_price"
                      defaultChecked={checkboxChecked}
                      onChange={handleCheckboxChange}
                      type="checkbox"
                      className="rs-input"
                    />
                    <span
                      className="slider round"
                      name="ppv_price"
                      checked={checkboxChecked}
                      onChange={handleCheckboxChange}
                    ></span>
                  </label>
                </div>
              </div>

              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                  placeholder="PPV Price"
                  // value={editUser?.ppv_price}
                  id="ppv_price"
                  name="ppv_price"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>

            <div className="col-sm-12 mt-2 px-0">
              <label className="mt-2 theme-text-color admin-input-title"> IOS PPV Price</label>
              <p className="mt-2 theme-text-color opacity-75 admin-input-description">Apply IOS PPV Price from Global Settings?</p>
              <div className="panel-body ppv_price_ios mt-2">
                <select
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                  onChange={handleInput}
                  value={editUser?.ios_ppv_price}
                  className="form-select custom-placeholder  theme-bg-color-secondary theme-text-color border-0"
                >
                  {inapppurchase?.map((item, key) => (
                    <option value={item?.product_id} className="theme-bg-color theme-text-color">{item?.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSelectChanges = async (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    const formData = new FormData();

    formData.append("value", value);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/advertisement-tag-url`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        console.log("apiasas", response);
        if (response.data.status == true) {
          var result = response.data;

          console.log("api", result);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };

  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime == "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime == "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= editUser.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime == "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= editUser.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else if (formattedTime == editUser.skip_start_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session cannot be the same as Skip Start Time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime == "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime == "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= editUser.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime == "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= editUser.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else if (formattedTime == editUser.recap_start_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session cannot be the same as Recap Start Time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  const insertColons = (time) => {
    if (time?.length >= 6) {
      const hours = time.substr(0, 2);
      const minutes = time.substr(2, 2);
      const seconds = time.substr(4, 2);

      return `${hours}:${minutes}:${seconds}`;
    }
    return time;
  };

  // const handleChangeyear = (event) => {
  //   const value = event.target.value;
  //   if (/^\d{0,4}$/.test(value)) {
  //     setEditUser({ ...editUser, year: value });
  //   }
  // };

  const handleChangeyear = (event) => {
    // setValidationMessageyear("");
    // setIsValidationHiddenyear(true);
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevEditUser) => ({
      ...prevEditUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);
    setEditUser({ ...editUser, [e.target.name]: e.target.value });
  };

  const handleInputenable = (e) => {
    if (e.target.checked == true) {
      setEditUser({ ...editUser, [e.target.name]: 1 });
    } else {
      setEditUser({ ...editUser, [e.target.name]: 0 });
    }
  };



  const [validationMessagefree_duration_time, setValidationMessagefree_duration_time] = useState('');
  const [isValidationHiddenfree_duration_time, setIsValidationHiddenfree_duration_time] = useState(true);

  const free_duration_timeInputRef = useRef(null);

  const handleInputdatatime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };


  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguages, setValidationMessagelanguages] =
    useState("");
  const [isValidationHiddenlanguages, setIsValidationHiddenlanguages] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const handleUpdate = async (e) => {
    e.preventDefault();
    setLoaderText("Processing")
    let focusInputRef = null;
    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (!selectedValuescategories || selectedValuescategories?.length == 0) {
      setValidationMessagecategory("Please select at least one Category*.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (!selectedValueslanguages || selectedValueslanguages?.length == 0) {
      setValidationMessagelanguages("Please select at least one Language*.");
      setIsValidationHiddenlanguages(false);
      focusInputRef = languageInputRef;
    }

    // Reset validation messages
    setValidationMessagefree_duration_time("");
    setIsValidationHiddenfree_duration_time(true);

    if (editUser?.free_duration_status === 1) {
      // Validate free_duration_time only if free_duration_status is active (1)
      if (!editUser?.free_duration_time) {
        setValidationMessagefree_duration_time("If Free Duration Status is active, Free Duration Time is mandatory.");
        setIsValidationHiddenfree_duration_time(false);
        focusInputRef = free_duration_timeInputRef;
        return;
      }

      // Additional validation for free_duration_time format can be added here if needed
      // e.g., regex check for HH:MM:SS format
    }

    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }

    const updatedTagsString = tags.join(", ");

    const editInputvalue = {
      title: editUser?.title,
      type: editUser?.type,
      slug: editUser?.slug,
      id: editUser?.id,
      active: editUser?.active,
      user_id: editUser?.user_id,
      status: editUser?.status,
      draft: editUser?.draft,
      rating: editUser?.rating,
      duration: editUser?.duration,
      details: editUser?.details,
      description: editUser?.description,
      image: editUser?.image,
      mobile_image: editUser?.mobile_image,
      tablet_image: editUser?.tablet_image,
      player_image: editUser?.player_image,
      video_tv_image: editUser?.video_tv_image,
      enable_video_title_image: editUser?.enable_video_title_image,
      video_title_image: editUser?.video_title_image,
      android_tv: editUser?.android_tv,
      video_gif: editUser?.video_gif,
      search_tags: updatedTagsString,
      access: editUser?.access,
      global_ppv: editUser?.global_ppv,
      ppv_price: editUser?.ppv_price,
      ios_ppv_price: editUser?.ios_ppv_price,
      featured: editUser?.featured,
      banner: editUser?.banner,
      enable: editUser?.enable,
      embed_code: editUser?.embed_code,
      mp4_url: editUser?.mp4_url,
      m3u8_url: editUser?.m3u8_url,
      webm_url: editUser?.webm_url,
      ogg_url: editUser?.ogg_url,
      disk: editUser?.disk,
      original_name: editUser?.original_name,
      path: editUser?.path,
      stream_path: editUser?.stream_path,
      processed_low: editUser?.processed_low,
      converted_for_streaming_at: editUser?.converted_for_streaming_at,
      views: editUser?.views,
      year: editUser?.year,
      age_restrict: editUser?.age_restrict,
      Recommendation: editUser?.Recommendation,
      country: editUser?.country,
      publish_status: editUser?.publish_status,
      publish_type: publishType,
      publish_time: publishTime,
      skip_start_time: editUser?.skip_start_time,
      skip_end_time: editUser?.skip_end_time,
      skip_start_session: editUser?.skip_start_session,
      recap_start_time: editUser?.recap_start_time,
      recap_end_time: editUser?.recap_end_time,
      recap_start_session: editUser?.recap_start_session,
      pdf_files: editUser?.pdf_files,
      reels_thumbnail: editUser?.reels_thumbnail,
      reelvideo: editUser?.reelvideo,
      url: editUser?.url,
      url_link: editUser?.url_link,
      url_linktym: editUser?.url_linktym,
      url_linksec: editUser?.url_linksec,
      urlEnd_linksec: editUser?.urlEnd_linksec,
      trailer_type: editUser?.trailer_type,
      trailer: editUser?.trailer,
      trailer_description: editUser?.trailer_description,
      ads_tag_url_id: editUser?.ads_status,
      ads_status: editUser?.ads_status,
      default_ads: editUser?.default_ads,
      pre_ads_category: editUser?.pre_ads_category,
      mid_ads_category: editUser?.mid_ads_category,
      post_ads_category: editUser?.post_ads_category,
      pre_ads: editUser?.pre_ads,
      mid_ads: editUser?.mid_ads,
      post_ads: editUser?.post_ads,
      time_zone: editUser?.time_zone,
      home_genre: editUser?.home_genre,
      in_menu: editUser?.in_menu,
      footer: editUser?.footer,
      uploaded_by: editUser?.uploaded_by,
      language: editUser?.language,
      free_duration_status: editUser?.free_duration_status,
      free_duration_time: editUser?.free_duration_time,
      video_category_id: editUser?.video_category_id,
      Thumbnail: editUser?.Thumbnail,
      Player_thumbnail: editUser?.Player_thumbnail,
      TV_Thumbnail: editUser?.TV_Thumbnail,
      draft: 1,
      Video_Title_Thumbnail: editUser?.Video_Title_Thumbnail,
      Video_reels_Thumbnail: editUser?.Video_reels_Thumbnail,
      Video_reels_video_url: editUser?.Video_reels_video_url,
      Video_upload_url: editUser?.Video_upload_url,
      languages_id: selectedValueslanguages?.map((option) => option.value),
      artist_id: selectedValuesartists?.map((option) => option.value),
      block_country_id: selectedValues?.map((option) => option.value),
      category_id: selectedValuescategories?.map((option) => option.value),
      available_countries_id: selectedValuesavailable_country?.map(
        (option) => option.value
      ),
      related_videos: selectedValuesrelated_video?.map(
        (option) => option.value
      ),
      // available_countries_id: selectedValuesavailable_country?.map(
      //   (option) => option.value
      // ),
      ...formData,
      ...formDatapublishedit,
    };

    let firstApiStatus = false;
    let secondApiStatus = false;
    let thirdApiStatus = false;
    let fourthApiStatus = false;
    let fifthApiStatus = false;
    let sixthApiStatus = false;
    const epaperCondition = true;
    let seventhApiStatus = false;
    let eighthApiStatus = false;
    let ninethApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    let resjson = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const res = await fetch(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-update/${id}`,
        {
          method: "POST",
          headers: headers,
          body: JSON.stringify(editInputvalue),
        }
      );

      resjson = await res.json();

      if (resjson.status == true) {
        firstApiStatus = true;

        resultapi = resjson;

        const formData = new FormData();

        formData.append("video_id", id);
        if (selectedFile1) {
          formData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          formData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          formData.append("video_tv_image", selectedFile3);
        }
        if (selectedFile4) {
          formData.append("video_title_image", selectedFile4);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-Image-upload`,
          formData,
          { headers: headers }
        );

        const formData1 = new FormData();

        // videosreels.forEach((video, index) => {
        //   formData1.append(`reels_videos[${index}]`, video);
        // });

        formData1.append("video_id", id);


        let indexUsed = false; // Flag to track if index has been used for videosreels

        if (videosreels && videosreels.length > 0) {
          videosreels.forEach((video, index) => {
            if (video && !indexUsed) {
              formData1.append(`reels_videos[${index}]`, video);
              indexUsed = true;
            }
          });
        } else if (reelsvideoget && reelsvideoget.length > 0) {
          await Promise.all(reelsvideoget.map(async (videosurl) => {
            if (videosurl?.reels_videos_url) {
              const response = await fetch(videosurl?.reels_videos_url);
              const blob = await response?.blob();
              formData1.append(`reels_videos[${videosurl?.id}]`, blob, videosurl?.reels_videos_url);
            }
          }));
        }


        const formData1response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-reels-upload`,
          formData1,
          { headers: headers }
        );

        const formDatasubtitles = new FormData();
        formDatasubtitles.append("video_id", id);

        // Object.keys(videosubtitles).forEach((language, index) => {
        //   formDatasubtitles.append(
        //     `short_code[${index}]`,
        //     Subtitlesmap[index].short_code
        //   );
        //   formDatasubtitles.append(
        //     `language[${index}]`,
        //     Subtitlesmap[index].language
        //   );
        //   videosubtitles[language].forEach((file) => {
        //     formDatasubtitles.append(`subtitles[${index}]`, file);
        //   });
        // });


        if (videosubtitles && Object.keys(videosubtitles).length > 0) {
          // Process and send newly selected subtitle files
          Object.keys(videosubtitles).forEach((language, index) => {
            formDatasubtitles.append(`short_code[${index}]`, Subtitlesmap[index]?.short_code);
            formDatasubtitles.append(`language[${index}]`, Subtitlesmap[index]?.language);
            videosubtitles[language]?.forEach((file) => {
              formDatasubtitles.append(`subtitles[${index}]`, file);
            });
          });
        } else if (Subtitlesmap && Subtitlesmap.length > 0) {
          // Process and send existing subtitle data from Subtitlesmap if no new files are selected
          await Promise.all(Subtitlesmap.map(async (videosurl, index) => {
            if (videosurl?.subtitle_url) {
              const response = await fetch(videosurl.subtitle_url);
              const blob = await response?.blob();
              formDatasubtitles.append(`subtitles[${index}]`, blob, videosurl.subtitle_url);
              formDatasubtitles.append(`short_code[${index}]`, videosurl.short_code);
              formDatasubtitles.append(`language[${index}]`, videosurl.language);
            }
          }));
        }

        const formDatasubtitlesresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-Subtitle`,
          formDatasubtitles,
          { headers: headers }
        );

        const formDatareel = new FormData();

        formDatareel.append("video_id", id);

        if (selectedFile5) {
          // Append the selected file
          formDatareel.append("reels_thumbnail", selectedFile5);
        } else if (editUser?.Video_reels_Thumbnail) {
          // Fetch the existing file and append it as a Blob
          const response = await fetch(editUser?.Video_reels_Thumbnail);
          const blob = await response?.blob();
          formDatareel.append("reels_thumbnail", blob, 'thumbnail.jpg'); // Specify the filename
        }

        // formData1.append("reels_videos", reelvideo);
        // if (selectedFile5) {
        //   formDatareel.append("reels_thumbnail", selectedFile5);
        // }

        const formDatareelresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-reels-thumbnail`,
          formDatareel,
          { headers: headers }
        );

        const formDaEpaper = new FormData();

        formDaEpaper.append("video_id", id);
        // formDaEpaper.append("pdf_file", selectedFilepdf);
        if (pdf_files) {
          // Append the selected PDF file
          formDatareel.append("pdf_file", pdf_files);
        } else if (editUser?.EPaper) {
          // Fetch the existing PDF file and append it as a Blob
          const response = await fetch(editUser?.EPaper);
          const blob = await response?.blob();
          formDatareel.append("pdf_file", blob, 'document.pdf'); // Specify the filename
        }

        const formDaEpaperresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-Epaper`,
          formDaEpaper,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("video_id", id);
        formDataseo.append("website_page_title", editUser?.website_page_title);
        formDataseo.append("website_URL", editUser?.website_URL);
        formDataseo.append("Meta_description", editUser?.Meta_description);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDatatrailerurl = new FormData();

        formDatatrailerurl.append("video_id", id);

        formDatatrailerurl.append("mp4_url", editUser?.trailer);
        formDatatrailerurl.append("m3u8_trailer", editUser?.trailer);
        formDatatrailerurl.append("embed_url", editUser?.trailer);
        formDatatrailerurl.append("trailer_type", editUser?.trailer_type);

        const formDatatrailerurlresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-Trailer-url`,
          formDatatrailerurl,
          { headers: headers }
        );

        const formDtrailervideo = new FormData();

        formDtrailervideo.append("video_id", id);
        formDtrailervideo.append("trailer", selectedFiles);
        formDtrailervideo.append(
          "trailer_filename",
          editUser?.trailer_filename
        );

        const formDtrailervideoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-Trailer-upload`,
          formDtrailervideo,
          { headers: headers }
        );

        if (imageResponse.data.status == true) {
          secondApiStatus = true;
        }
        if (formData1response.data.status == true) {
          thirdApiStatus = true;
        }
        if (formDatasubtitlesresponse.data.status == true) {
          fourthApiStatus = true;
        }
        if (formDatareelresponse.data.status == true) {
          fifthApiStatus = true;
        }
        if (formDaEpaperresponse.data.status == true) {
          sixthApiStatus = true;
        }
        if (formDataseoresponse.data.status == true) {
          seventhApiStatus = true;
        }
        if (formDatatrailerurlresponse.data.status == true) {
          eighthApiStatus = true;
        }
        if (formDtrailervideoresponse.data.status == true) {
          ninethApiStatus = true;
        }
        // secondApiStatus = true;
      } else if (resjson.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = resjson;
      }
    } catch (error) {
      console.error("Error:", error);
    }

    if (
      firstApiStatus &&
      secondApiStatus &&
      thirdApiStatus &&
      fourthApiStatus &&
      fifthApiStatus &&
      sixthApiStatus &&
      seventhApiStatus &&
      eighthApiStatus &&
      ninethApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessageslug("");
      toast.success(t(resultapi?.message));
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else if (resultapifalse) {
      toast.error(t(resultapifalse?.message));
      setProcessing(false);
      setShowOverlay(false);
      setIsValidationHiddentitle(false);
      setValidationMessageslug(false);
    }
  };

  const deleteOperationepaper = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos/EPaper-delete/${id}`,
        { headers: headers }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          toast.success(t(responseData?.message));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData == false) {
            var resultError = responseData;
            toast.error(t(resultError?.message));
          }
        }
      });
  };

  const deleteOperationsubtitle = (subtitles_id) => {


    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos/subtitles-delete/${subtitles_id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          toast.success(t(responseData?.message));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData == false) {
            var resultError = responseData;
            toast.error(t(resultError?.message));
          }
        }
      });
  };
  const deleteOperationreels = (id) => {


    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos/Reels-delete/${id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          toast.success(t(responseData?.message));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData == false) {
            var resultError = responseData;
            toast.error(t(resultError?.message));
          }
        }
      });
  };
  const deleteOperationtrailer = (id) => {

    axios
      .delete(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-trailer-delete/${id}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (response.data.status == true) {
          var responseData = response.data;
          toast.success(t(responseData?.message));
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else {
          if (responseData == false) {
            var resultError = responseData;
            toast.error(t(resultError?.message));
          }
        }
      });
  };

  const steps = [
    {
      label: "Video",
      description: (
        <div className="">
          <>
            <section>
              <div className="row m-0">
                <div className="col-sm-6 col-md-6 ps-md-0 ">
                  <div className="rounded-3 mb-4 theme-bg-color p-3">
                    <h6 className="theme-text-color theme-border-bottom-secondary  pb-3 text-break admin-input-title">{edittitle?.title}</h6>
                    <div className="mt-3 text-start">
                      <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title">
                        Title
                      </label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">
                        Add the Video title in the textbox below:
                      </p>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="title"
                          name="title"
                          className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                          onChange={handleInput}
                          value={editUser?.title}
                          placeholder="Title"
                          ref={titleInputRef}
                        />
                      </div>
                      <span className="errorred d-block mt-2">
                        {!isValidationHiddentitle && (
                          <p>{validationMessagetitle}</p>
                        )}
                      </span>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="mt-2 admin-input-title theme-text-color">Slug</label>
                      <p className="theme-text-color admin-input-description opacity-75 mt-2">Add the Video slug</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          id="slug"
                          name="slug"
                          className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                          onChange={handleInput}
                          value={editUser?.slug}
                          placeholder="slug"
                        />
                      </div>
                      {/* <span className="errorred">
                      {!isValidationHiddenslug && (
                        <p>{validationMessageslug}</p>
                      )}
                    </span> */}
                    </div>
                    <div className="mt-2 text-start">
                      <label className="mt-2 theme-text-color admin-input-title ">Short Description</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">
                        Add a short description of the Video below
                      </p>
                      <div className="mt-2">
                        <textarea
                          className="form-select custom-placeholder  theme-bg-color-secondary theme-text-color border-0"
                          id="description"
                          name="description"
                          onChange={handleInput}
                          value={editUser?.description}
                          placeholder="Description"
                        />
                      </div>
                    </div>
                    <div className="row mt-2 text-start">
                      <div className="col-12">
                        <label className="m-0 theme-text-color admin-input-title">
                          Video Details, Links, and Info
                        </label>
                        <div className="mt-2">
                          <JoditEditor
                            className="rs-input custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                            value={editUser?.details}
                            onChange={(e) => {
                              handleChangeFirst(e);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 text-start mx-0">
                      <div className="col-12">
                        <label className="mt-2 theme-text-color admin-input-title ">Duration</label>
                        <p className="mt-2 theme-text-color opacity-75 admin-input-description">
                          Enter the Video duration in the following format
                          (Hours : Minutes : Seconds)
                        </p>
                        <div className="mt-2">
                          <input
                            type="text"
                            name="duration"
                            className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                            value={editUser?.duration}
                            maxLength="8"
                            placeholder="HH:MM:SS"
                            onChange={handleInputdata}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2 mx-0 text-start">
                      <div className="col-12">
                        <label className="mt-2 theme-text-color admin-input-title">Publish Year</label>
                        <p className="mt-2 theme-text-color opacity-75 admin-input-description">Video Released Year</p>
                        <div className="mt-2">
                          <input
                            type="date"
                            name="date"
                            id="date"
                            value={editUser?.year}
                            onChange={handleChangeyear}
                            className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                            placeholder="Year"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4  theme-bg-color p-3">
                    <h6 className="theme-text-color theme-border-bottom-secondary  pb-3">Organize</h6>
                    <div className="mt-3 text-start">
                      <label className="mt-2 theme-text-color">Video Ratings</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">IMDb Ratings 10 out of 10</p>
                      <div className="mt-2">
                        <select
                          className="form-select custom-placeholder  theme-bg-color-secondary theme-text-color border-0"
                          id="rating"
                          name="rating"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.rating}
                        >
                          {/* <option value="0">0</option> */}
                          <option value="1" className="theme-bg-color theme-text-color">1</option>
                          <option value="2" className="theme-bg-color theme-text-color">2</option>
                          <option value="3" className="theme-bg-color theme-text-color">3</option>
                          <option value="4" className="theme-bg-color theme-text-color">4</option>
                          <option value="5" className="theme-bg-color theme-text-color">5</option>
                          <option value="6" className="theme-bg-color theme-text-color">6</option>
                          <option value="7" className="theme-bg-color theme-text-color">7</option>
                          <option value="8" className="theme-bg-color theme-text-color">8</option>
                          <option value="9" className="theme-bg-color theme-text-color">9</option>
                          <option value="10" className="theme-bg-color theme-text-color">10</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-2 text-start">
                      <label className="mt-2 theme-text-color admin-input-title">Age Restrict</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">Select a Video Age Below</p>
                      <div className="mt-2">
                        <select
                          className="form-select custom-placeholder  theme-bg-color-secondary theme-text-color border-0"
                          id="age_restrict"
                          name="age_restrict"
                          size="lg"
                          onChange={handleInput}
                          value={editUser?.age_restrict}
                        >
                          <option value="" className="theme-bg-color theme-text-color">Choose an Age</option>
                          {age_restrictdata?.map((item) => (
                            <option value={item?.id} className="theme-bg-color theme-text-color">{item?.slug}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Cast and Crew</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">Add artists for the Video below</p>

                      <div className="mt-2">
                        <Select
                          options={optionsartists}
                          isMulti
                          className="form-select custom-placeholder theme-bg-color-secondary theme-text-color"
                          onChange={handleSelectChangeartists}
                          value={selectedValuesartists}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title">
                        Category
                      </label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">Select a Video Category Below</p>

                      <div className="mt-2 mb-3">
                        <Select
                          options={optionscategories}
                          isMulti
                          className="custom-placeholder theme-bg-color-secondary theme-text-color"
                          onChange={handleSelectChangecategories}
                          value={selectedValuescategories}
                          ref={categoryInputRef}
                        />
                      </div>
                      <span className="errorred d-block mt-2">
                        {!isValidationHiddencategory && (
                          <p>{validationMessagecategory}</p>
                        )}
                      </span>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title">
                        Language
                      </label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">Select a Video Language Below</p>
                      <div className="">
                        <Select
                          options={optionslanguages}
                          isMulti
                          className="custom-placeholder theme-bg-color-secondary theme-text-color"
                          onChange={handleSelectChangelanguages}
                          value={selectedValueslanguages}
                          ref={languageInputRef}
                        />
                      </div>
                      <span className="errorred  d-block mt-2">
                        {!isValidationHiddenlanguages && (
                          <p>{validationMessagelanguages}</p>
                        )}
                      </span>
                    </div>

                    <div className="text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Block Country</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">
                        Block the Video for Selected Country
                      </p>
                      <div className="">
                        <Select
                          options={options}
                          isMulti
                          className="custom-placeholder theme-bg-color-secondary theme-text-color"
                          onChange={handleSelectChange}
                          value={selectedValues}
                        />
                      </div>
                    </div>

                    <div className="text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Available Country</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">
                        Available the Video for Selected Country
                      </p>
                      <div className="">
                        <Select
                          options={optionsavailable_country}
                          isMulti
                          className="form-select custom-placeholder theme-bg-color-secondary theme-text-color"
                          onChange={handleSelectChangeavailable_country}
                          value={selectedValuesavailable_country}
                        />
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Related Videos</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">Select a Related Video Below</p>
                      <div className="">
                        <Select
                          options={optionsrelated_video}
                          isMulti
                          className=" custom-placeholder theme-bg-color-secondary theme-text-color"
                          onChange={handleSelectChangerelated_video}
                          value={selectedValuesrelated_video}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4 theme-bg-color p-3">
                    <h6 className="theme-text-color theme-border-bottom-secondary pb-3">
                      Intro Time <small className="opacity-75">(Optional)</small>
                    </h6>
                    <div className="col-lg-12 row">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="mt-2 admin-input-title theme-text-color">
                            Skip Start Time{" "}
                            <small className="d-block opacity-75 my-2 ">(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                              name="skip_start_time"
                              id="skip_start_time"
                              maxLength="8"
                              placeholder="HH:MM:SS"
                              value={editUser?.skip_start_time}
                              onChange={handleInputdata}
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="mt-2 admin-input-title theme-text-color">
                            Recap Start Time{" "}
                            <small className="d-block opacity-75 my-2 ">(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.recap_start_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                              name="recap_start_time"
                              id="recap_start_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        {" "}
                        <div className="text-start">
                          <label className="mt-2 theme-text-color admin-input-title">
                            Skip End Time{" "}
                            <small className="d-block opacity-75 my-2">(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.skip_end_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                              name="skip_end_time"
                              id="skip_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_end_time}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="mt-2 theme-text-color admin-input-title">
                            Recap End Time{" "}
                            <small className="d-block opacity-75 my-2" >(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.recap_end_time}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                              name="recap_end_time"
                              id="recap_end_time"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_end_time}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-12 row mt-2">
                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="mt-2 theme-text-color admin-input-title">
                            Skip Start Session{" "}
                            <small className="d-block opacity-75 my-2">(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.skip_start_session}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                              name="skip_start_session"
                              id="skip_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_skip_start_session}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="text-start">
                          <label className="mt-2 theme-text-color admin-input-title">
                            Recap Start Session{" "}
                            <small className="d-block opacity-75 my-2">(Please Give In Seconds)</small>
                          </label>

                          <div className="panel-body">
                            <input
                              type="text"
                              maxLength="8"
                              value={editUser?.recap_start_session}
                              onChange={handleInputdata}
                              placeholder="HH:MM:SS"
                              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                              name="recap_start_session"
                              id="recap_start_session"
                            />
                          </div>
                          <div>
                            <span className="intro_time_error">
                              {errors?.error_recap_start_session}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4  theme-bg-color p-3">
                    <h6 className="theme-text-color theme-border-bottom-secondary  pb-3">Information</h6>
                    <div className=" text-start">
                      <label className="mt-2 theme-text-color admin-input-title">E-Paper</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">(Upload your PDF file)</p>
                      <div className="row">
                        {editUser?.EPaper ? (
                          <>
                            <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                              <Link
                                to={editUser?.EPaper}
                                target="_blank"
                                download
                              >
                                <div className="text-center file-e-paper p-2 mt-2  theme-text-color">
                                  <i
                                    className="fa fa-file-pdf-o"
                                    aria-hidden="true"
                                  ></i>
                                  <div className=" theme-text-color admin-input-title" >Download</div>
                                </div>
                              </Link>
                            </div>
                            <div className="col-5 col-sm-5 col-md-5 col-lg-5  align-self-center">
                              <Link to="" className="delete ms-1">
                                <span onClick={() => deleteOperationepaper(id)}>
                                  <img
                                    src={deleteitem}
                                    alt="flogo"
                                    width={20}
                                    height={20}
                                  />
                                </span>
                              </Link>
                            </div>
                          </>
                        ) : null}
                      </div>

                      <div className="mt-3">
                        <input
                          type="file"
                          className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                          name="pdf_file"
                          accept="application/pdf"
                          onChange={(e) => setPdf_files(e.target.files[0])}
                          // onChange={handleFileChangepdfupload}
                          multiple
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">URL Link</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">Select the URL Link</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                          name="url_link"
                          onChange={handleInput}
                          value={editUser?.url_link}
                          id="url_link"
                        />
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title" >URL Start Time Format </label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">(HH:MM:SS)</p>
                      <div className="mt-2">
                        <input
                          type="text"
                          className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                          name="url_linktym"
                          maxLength="8"
                          onChange={handleInputdata}
                          value={editUser?.url_linktym}
                          id="url_linktym"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4  theme-bg-color p-3">
                    <h6 className=" theme-text-color theme-border-bottom-secondary  pb-3">SEO</h6>
                    <div className="col-sm-12">
                      <label className="mt-2 theme-text-color admin-input-title">Website Page Title</label>
                      <div className=" mt-2">
                        <input
                          type="text"
                          value={editUser?.website_page_title}
                          name="website_page_title"
                          className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                          onChange={handleInput}
                          placeholder=""
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Website URL</label>
                      <div className="mt-2">
                        <input
                          type="text"
                          value={editUser?.website_URL}
                          name="website_URL"
                          className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                          onChange={handleInput}
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="col-sm-12 mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Meta Description</label>

                      <div className=" mt-2">
                        <textarea
                          type="text"
                          value={editUser?.Meta_description}
                          name="Meta_description"
                          className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                          onChange={handleInput}
                          placeholder=""
                        ></textarea>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4  theme-bg-color p-3">
                    <h6 className=" theme-text-color theme-border-bottom-secondary  pb-3">Search Tags</h6>
                    <div className="col-sm-12">
                      <p className="mt-2 theme-text-color admin-input-title">You don't have any search keywords.</p>
                      <div className="mt-2">
                        <TagsInput
                          value={tags}
                          className="form-select custom-placeholder  theme-bg-color-secondary theme-text-color border-0"
                          onChange={handleTagsChange}
                          inputValue={newTag}
                          onChangeInput={handleInputChangetag}
                          inputProps={{ onKeyPress: handleInputKeyPress }} // Adding key press event handler
                        />
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4 theme-bg-color p-3">
                    <h6 className="theme-text-color theme-border-bottom-secondary  pb-3">Status Settings</h6>
                    <div className="row mx-0 mt-4 ">
                      <div className="col-9 col-sm-9 col-lg-9 ps-0">
                        <p className="theme-text-color admin-input-title">Enable this video as Featured</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 ps-0 text-end">
                        <label className="switch">
                          <input
                            name="featured"
                            id="featured"
                            onChange={handleInputenable}
                            className="rs-input"
                            defaultChecked={
                              editUser?.featured == 1 ? true : false
                            }
                            checked={editUser?.featured == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="featured"
                            id="featured"
                            onChange={handleInputenable}
                            value={editUser?.featured == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    {/* <div className="row mt-2 mx-0">
                      <div className="col-9 col-sm-9 col-lg-9 ps-0">
                        <p className="theme-text-color admin-input-title">Enable this Video Active</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 ps-0 text-end">
                        <label className="switch">
                          <input
                            name="active"
                            id="active"
                            onChange={handleInputenable}
                            defaultChecked={
                              editUser?.active == 1 ? true : false
                            }
                            checked={editUser?.active == 1 ? true : false}
                            className="rs-input"
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="active"
                            id="active"
                            onChange={handleInputenable}
                            value={editUser?.active == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div> */}

                    <div className="row mt-2 mx-0">
                      <div className="col-9 col-sm-9 col-lg-9 ps-0">
                        <p className="theme-text-color admin-input-title">Enable this Video as Slider</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          <input
                            name="banner"
                            id="banner"
                            onChange={handleInputenable}
                            className="r-input"
                            defaultChecked={
                              editUser?.banner == 1 ? true : false
                            }
                            checked={editUser?.banner == 1 ? true : false}
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            name="banner"
                            id="banner"
                            onChange={handleInputenable}
                            value={editUser?.banner == 1 ? "1" : "0"}
                          ></span>
                        </label>
                      </div>
                    </div>

                    <div className="row mt-2 mx-0">
                      <div className="col-9 col-sm-9 col-lg-9 ps-0">
                        <p className="theme-text-color admin-input-title">Enable Video Title Thumbnail</p>
                      </div>
                      <div className="col-3 col-sm-3 col-lg-3 text-end">
                        <label className="switch">
                          <input
                            name="enable_video_title_image"
                            id="enable_video_title_image"
                            onChange={handleInputenable}
                            defaultChecked={
                              editUser?.enable_video_title_image == 1
                                ? true
                                : false
                            }
                            checked={
                              editUser?.enable_video_title_image == 1
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                          <span
                            className="slider round"
                            id="enable_video_title_image"
                            name="enable_video_title_image"
                            onChange={handleInput}
                            value={
                              editUser?.enable_video_title_image == 1
                                ? "1"
                                : "0"
                            }
                          ></span>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4 theme-bg-color p-3">
                    <h6 className=" theme-text-color theme-border-bottom-secondary  pb-3">Free Duration</h6>
                    <div className="col-sm-12">
                      <div className="row mt-3 mx-0">
                        <div className="col-9 col-sm-9 col-lg-9 ps-0">
                          <p className="theme-text-color admin-input-title">Enable Free Duration Status</p>
                        </div>
                        <div className="col-3 col-sm-3 col-lg-3 ps-0">
                          <label className="switch">
                            <input
                              name="free_duration_status"
                              id="free_duration_status"
                              onChange={handleInputenable}
                              defaultChecked={
                                editUser?.free_duration_status == 1
                                  ? true
                                  : false
                              }
                              checked={
                                editUser?.free_duration_status == 1
                                  ? true
                                  : false
                              }
                              type="checkbox"
                            />
                            <span
                              className="slider round"
                              id="free_duration_status"
                              name="free_duration_status"
                              onChange={handleInputenable}
                              value={
                                editUser?.free_duration_status == 1 ? "1" : "0"
                              }
                            ></span>
                          </label>
                        </div>
                      </div>

                      <div className="row mt-2 text-start">
                        <div className="col-12">
                          <label className="mt-2 theme-text-color admin-input-title">Free Duration Time</label>
                          <div className="mt-2">
                            <input
                              type="text"
                              name="free_duration_time"
                              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                              value={editUser?.free_duration_time}
                              maxLength="8"
                              placeholder="HH:MM:SS"
                              onChange={handleInputdatatime}
                              ref={free_duration_timeInputRef}
                            />
                            {!isValidationHiddenfree_duration_time && (
                              <div className="mandatory">
                                {validationMessagefree_duration_time}
                              </div>
                            )}
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Second Part of Section  */}

                <div className=" col-md-6 col-12 ">
                  {editUser?.videos_url ? (
                    <div className="rounded-3 mb-4 theme-bg-color p-3">
                      <h6 className=" theme-text-color theme-border-bottom-secondary  pb-3">Videos {formattedDatatype}</h6>
                      <div className="text-start mt-3">
                        <ReactPlayer
                          url={editUser?.videos_url}
                          controls={true} // Enable player controls
                          width="100%"
                          height="230px"
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="rounded-3 mb-4 theme-bg-color p-3">
                    <h6 className=" theme-text-color theme-border-bottom-secondary  pb-3">Thumbnails</h6>
                    <div className=" text-start mt-2">
                      <label className="my-2 theme-text-color admin-input-title">Video Thumbnail</label>
                      <p className="mt-2 theme-text-color opacity-75 admin-input-description">
                        Select the Video image ( 9:16 Ratio or 1080X1920px )
                      </p>

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.Thumbnail}
                            alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>

                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className=" col-sm-6 col-lg-6">
                            <div
                              className="imagedrop theme-border-color"
                              onClick={() => fileInputRef1.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef1}
                                onChange={(event) =>
                                  handleFileChangesss(
                                    event,
                                    fileInputRef1,
                                    setSelectedFile1
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className=" col-sm-6 col-lg-6">
                            <div>
                              {selectedFile1 && (
                                <div className="imagedropcopy text-end theme-border-color">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL?.createObjectURL(
                                          selectedFile1
                                        )}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile1)
                                        }
                                        type="button"
                                        className="btn-close theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Player Thumbnail</label>
                      <p className="my-2 theme-text-color opacity-75 admin-input-description">
                        Select the Video image ( 16:9 Ratio or 1280X720px )
                      </p>

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.Player_thumbnail}
                            alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>
                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className=" col-sm-6 col-lg-6">
                            <div
                              className="imagedrop theme-border-color"
                              onClick={() => fileInputRef2.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef2}
                                onChange={(event) =>
                                  handleFileChangesss(
                                    event,
                                    fileInputRef2,
                                    setSelectedFile2
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className=" col-sm-6 col-lg-6">
                            <div>
                              {selectedFile2 && (
                                <div className="imagedropcopy theme-border-color text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL?.createObjectURL(
                                          selectedFile2
                                        )}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile2)
                                        }
                                        type="button"
                                        className="btn-close theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Video TV Thumbnail</label>
                      <p className="my-2 theme-text-color opacity-75 admin-input-description">
                        Select the Video image ( 16:9 Ratio or 1280X720px )
                      </p>

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.TV_Thumbnail}
                            alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>

                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className=" col-sm-6 col-lg-6">
                            <div
                              className="imagedrop theme-border-color"
                              onClick={() => fileInputRef3.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef3}
                                onChange={(event) =>
                                  handleFileChangesss(
                                    event,
                                    fileInputRef3,
                                    setSelectedFile3
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className=" col-sm-6 col-lg-6">
                            <div>
                              {selectedFile3 && (
                                <div className="imagedropcopy theme-border-color text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL?.createObjectURL(
                                          selectedFile3
                                        )}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile3)
                                        }
                                        type="button"
                                        className="btn-close theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Video Title Thumbnail</label>
                      <p className="my-2 theme-text-color opacity-75 admin-input-description">
                        Select the Video Title image ( 16:9 Ratio or 1280X720px
                        )
                      </p>

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.Video_Title_Thumbnail}
                            alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>
                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className=" col-sm-6 col-lg-6">
                            <div
                              className="imagedrop theme-border-color "
                              onClick={() => fileInputRef4.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef4}
                                onChange={(event) =>
                                  handleFileChangesss(
                                    event,
                                    fileInputRef4,
                                    setSelectedFile4
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className=" col-sm-6 col-lg-6">
                            <div>
                              {selectedFile4 && (
                                <div className="imagedropcopy text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL?.createObjectURL(
                                          selectedFile4
                                        )}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile4)
                                        }
                                        type="button"
                                        className="btn-close  theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4  theme-bg-color p-3">
                    <h6 className="theme-text-color theme-border-bottom-secondary  pb-3">Reels </h6>
                    <div className=" text-start AwesomeSlider">
                      <label className="mt-2 theme-text-color admin-input-title">
                        Reels Video <small className="d-block opacity-75 my-2">( Upload the 1 min Videos )</small>
                      </label>
                      <p className="my-2 theme-text-color opacity-75 admin-input-description">Select the Reels Video</p>
                      <div className="row m-0">
                        {loading ? (
                          <img
                            src={loadings}
                            className="thumbnails-images"
                            alt="Loading"
                          />
                        ) : (
                          <div>
                            {reelsvideoget?.map((item, key) => (
                              <div key={key}>
                                {item ? (
                                  <div className="col-lg-12 row">
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6">
                                      {/* Assuming this is a custom media player component */}
                                      <media-player
                                        title={item?.reels_videos_slug}
                                        src={item?.reels_videos_url}
                                        poster=""
                                        controls
                                      >
                                        <media-outlet></media-outlet>
                                      </media-player>
                                    </div>
                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 align-self-center">
                                      <Link className="delete ms-1">
                                        <span
                                          onClick={() =>
                                            deleteOperationreels(item?.id)
                                          }
                                        >
                                          <img
                                            src={deleteitem}
                                            alt="Delete"
                                            width={20}
                                            height={20}
                                          />
                                        </span>
                                      </Link>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className="mt-2 file-draganddrop theme-border-color">
                        <Dropzone
                          onDrop={handleDropreels}
                          accept="video/*"
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()} className="dropzone">
                              <input {...getInputProps()} />
                              <p className="p-2 theme-text-color admin-input-title">
                                Drag and drop reels videos here
                              </p>
                              {videosreels?.length > 0 && (
                                <p className=" theme-text-color admin-input-title">
                                  {videosreels?.length}{" "}
                                  {videosreels?.length == 1 ? "file" : "files"}{" "}
                                  selected
                                </p>
                              )}
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Reels Video Thumbnail</label>
                      <p className="my-2 theme-text-color opacity-75 admin-input-description">
                        Select the Reels Video image ( 16:9 Ratio or 1280X720px
                        )
                      </p>

                      <div>
                        {loading ? (
                          <img src={loadings} className="thumbnails-images" />
                        ) : (
                          <img
                            src={editUser?.Video_reels_Thumbnail}
                            alt="Image"
                            className="thumbnails-images"
                          />
                        )}
                      </div>
                      <div className="mt-2">
                        <div className="col-lg-12 row">
                          <div className=" col-sm-6 col-lg-6">
                            <div
                              className="imagedrop theme-border-color"
                              onClick={() => fileInputRef5.current.click()}
                            >
                              <input
                                type="file"
                                ref={fileInputRef5}
                                onChange={(event) =>
                                  handleFileChangesss(
                                    event,
                                    fileInputRef5,
                                    setSelectedFile5
                                  )
                                }
                                accept="image/*"
                                style={{ display: "none" }}
                              />
                              <label className=" theme-text-color">
                                <CameraRetroIcon className=" theme-text-color" />
                              </label>
                            </div>
                          </div>
                          <div className=" col-sm-6 col-lg-6">
                            <div>
                              {selectedFile5 && (
                                <div className="imagedropcopy text-end">
                                  <div className="col-lg-12 row p-0 m-0">
                                    <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                      <img
                                        src={URL?.createObjectURL(
                                          selectedFile5
                                        )}
                                        alt="Uploaded"
                                        className="thumbnails-upload-images"
                                      />
                                    </div>
                                    <div
                                      className="col-sm-2 col-lg-2 p-0 m-0"
                                      style={{ alignSelf: "center" }}
                                    >
                                      <button
                                        onClick={() =>
                                          handleDelete(setSelectedFile5)
                                        }
                                        type="button"
                                        className="btn-close theme-text-color"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"
                                      ></button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4  theme-bg-color p-3">
                    <h6 className="theme-text-color theme-border-bottom-secondary  pb-3">Trailers Upload</h6>
                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Video Trailer Type</label>
                      <p className="my-2 theme-text-color opacity-75 admin-input-description">Upload trailer video in type</p>
                      <div className="mt-2">
                        <div className="row mx-0">
                          <div className="col-12 p-0">
                            <select
                              className="form-select custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                              name="trailer_type"
                              id="trailer_type"
                              onChange={handleInput}
                              value={editUser?.trailer_type}
                              onClick={(event) => {
                                setEnable_restreamtrailer(event.target.value);
                              }}
                            >
                              <option value="null" className="theme-bg-color theme-text-color">
                                {" "}
                                Select the Video Trailer Type{" "}
                              </option>
                              <option value="1" className="theme-bg-color theme-text-color">Video Upload </option>
                              <option value="m3u8_url" className="theme-bg-color theme-text-color">M3u8 Url </option>
                              <option value="mp4_url" className="theme-bg-color theme-text-color">Mp4 Url</option>
                              <option value="embed_url" className="theme-bg-color theme-text-color">Embed Code</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className=" text-start">
                      <div
                        className=""
                        onClick={(event) => {
                          setEnablestreamvisibletrailer(event.target.value);
                        }}
                      >
                        {proceednexttrailer()}
                      </div>
                    </div>

                    <div className=" text-start mt-2">
                      <label className="mt-2 theme-text-color admin-input-title">Trailer Description</label>

                      <div className="mt-2">
                        <JoditEditor
                          className="rs-input theme-bg-color-secondary theme-text-color border-0"
                          value={editUser?.trailer_description}
                          onChange={(e) => {
                            handleChangeFirstdes(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4  theme-bg-color p-3">
                    <h6 className=" theme-text-color theme-border-bottom-secondary  pb-3">Visibility</h6>
                    <div className="col-sm-12">
                      <label className="mt-3 theme-text-color admin-input-title">Publish Type</label>
                      <div>
                        <input
                          type="radio"
                          id="publish_now"
                          name="publish_type"
                          value="publish_now"
                          checked={publishType == "publish_now"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_now" className="mt-2 ms-1 theme-text-color admin-input-title">
                          Publish Now
                        </label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          id="publish_later"
                          name="publish_type"
                          value="publish_later"
                          checked={publishType == "publish_later"}
                          onChange={handlePublishTypeChange}
                        />
                        <label htmlFor="publish_later" className="mt-2 ms-1 theme-text-color admin-input-title">
                          Publish Later
                        </label>
                        {publishType == "publish_later" && (
                          <div className="mb-3">
                            <label className="mt-2 theme-text-color admin-input-title">Publish Time</label>
                            <input
                              type="datetime-local"
                              className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0 mt-2"
                              id="publish_time"
                              name="publish_time"
                              value={publishTime}
                              onChange={handlePublishTimeChange}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="rounded-3 mb-4  theme-bg-color p-3">
                    <h6 className=" theme-text-color theme-border-bottom-secondary  pb-3">Access</h6>
                    <div className="col-sm-12 mt-3">
                      <label className="m-0 theme-text-color admin-input-title">User Access</label>
                      <p className="my-2 theme-text-color opacity-75 admin-input-description">
                        Who Is Allowed To View This Video ?
                      </p>
                      <select
                        onChange={handleInput}
                        value={editUser?.access}
                        name="access"
                        id="access"
                        className="form-select custom-placeholder  theme-bg-color-secondary theme-text-color border-0"
                        onClick={(event) => {
                          setallliveuseraccess(event.target.value);
                        }}
                      >
                        <option value="" className="theme-bg-color theme-text-color">Select a User Access</option>
                        {user_access?.map((item, key) => (
                          <option value={item?.role} className="theme-bg-color theme-text-color">{item?.name}</option>
                        ))}
                      </select>
                    </div>

                    <div>{alllive_streamuseraccess()}</div>
                  </div>

                  <div className="rounded-3 mb-4 theme-bg-color p-3">
                    <label className=" w-100  d-block theme-text-color theme-text-color theme-border-bottom-secondary  pb-3 ">
                      Subtitles <small className=" opacity-75">(srt)</small>
                    </label>

                    <div className="mt-3">
                      {Subtitlesmap?.map((item, index) => (
                        <div className="col-sm-12 mt-2" key={index}>
                          <label className=" theme-text-color admin-input-title mb-2 ">{item?.language}</label>

                          <div className="row">
                            {item?.subtitle_url ? (
                              <>
                                <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                                  <Link
                                    to={item?.subtitle_url}
                                    target="_blank"
                                    download
                                  >
                                    <div className="text-center file-e-paper p-2 mt-2">
                                      <i
                                        className="fa fa-file-pdf-o"
                                        aria-hidden="true"
                                      ></i>
                                      <div className=" theme-text-color admin-input-title">Download</div>
                                    </div>
                                  </Link>
                                </div>
                                <div className="col-5 col-sm-5 col-md-5 col-lg-5  align-self-center">
                                  <Link to="" className="delete ms-1">
                                    <span
                                      onClick={() =>
                                        deleteOperationsubtitle(
                                          item?.subtitles_id
                                        )
                                      }
                                    >
                                      <img
                                        src={deleteitem}
                                        alt="flogo"
                                        width={20}
                                        height={20}
                                      />
                                    </span>
                                  </Link>
                                </div>
                              </>
                            ) : null}
                          </div>

                          <div className="row">
                            <div className="col-5 col-sm-5 col-md-5 col-lg-5">
                              {editUser?.subtitle_url ? (
                                <Link
                                  to={item?.subtitle_url}
                                  target="_blank"
                                  download
                                >
                                  <div className="text-center file-e-paper p-2 mt-2">
                                    <i
                                      className="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                    <div className=" theme-text-color admin-input-title">Download</div>
                                  </div>
                                </Link>
                              ) : null}
                            </div>
                          </div>

                          <div className="file-draganddrop mt-2 theme-border-color">
                            <Dropzone
                              onDrop={(acceptedFiles) =>
                                handleDropsubtitles(
                                  item.short_code,
                                  acceptedFiles
                                )
                              }
                              accept="video/*"
                              multiple={false}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className="dropzone">
                                  <input {...getInputProps()} />
                                  <p className="p-3 theme-text-color admin-input-title">
                                    Drag and drop subtitles videos here
                                  </p>
                                  {videosubtitles[item?.short_code]?.length >
                                    0 && (
                                      <p className=" theme-text-color admin-input-title">
                                        {
                                          videosubtitles[item?.short_code]
                                            .length
                                        }{" "}
                                        {videosubtitles[item?.short_code]
                                          .length == 1
                                          ? "file"
                                          : "files"}{" "}
                                        selected
                                      </p>
                                    )}
                                </div>
                              )}
                            </Dropzone>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="col-12 text-end">
                  <AdminCustomButton saveMethod={handleUpdate} saveText="Update Video" />
                </div>
              </div>
            </section>
          </>
        </div>
      ),
    },
    {
      label: "Video Details",
      description: (
        <div className="">

        </div>
      ),
    },
    {
      label: "Category",
      description: (
        <div className="container-fluid mt-4">

        </div>
      ),
    },
    {
      label: "User Video Access",
      description: (<></>
      ),
    },
    {
      label: "Upload Image & Trailer",
      description: (
        <></>
      ),
    },
    {
      label: "ADS Management",
      description: (
        <></>
      ),
    },
  ];

  return (
    <>
      {showOverlay && <ResponseLoader text={loaderText} />}
      <AdminSaveHeader heading="Edit Video" saveText="Update Video" saveMethod={handleUpdate} />
      <Box className='p-0'>
        <Stepper
          className="video p-0"
          activeStep={activeStep}
          nonLinear
        >
          {steps?.map((step, index) => (
            <Step key={step?.label} className="p-0">
              <StepContent className="p-0 m-0 border-0">
                <Typography>{step?.description}</Typography>
                <Box sx={{ mb: 2 }}>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>

        {activeStep == steps?.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>
              All steps completed - you&apos;re finished
            </Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Box>
    </>
  );
}
