// External Libraries and Packages
import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink, useParams } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";
import { useTranslation } from "react-i18next";
import { IoSearch } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { BsBoxArrowInRight, BsBoxArrowInLeft, BsTranslate } from "react-icons/bs";
import classNames from "classnames";
import axios from "axios";
// Internal Styles
import "./header.css";
// Internal Components
import { LogOutApi, useAutoLogout, displayTranslateLanguageAction, headerIndexAction, profileDetailsAction, siteThemeAction, userChangeLanguage } from "../../Redux/Actions/apiActions";
import DropDown from "../../components/Images/dropdown.png";
import LazyLoadImage from "../../Pages/HomePage/HomeSwiperComponents/LazyLoadImage";
import { themeToggleMethod } from "../../Pages/CommonMethods/CommonMethods";
import { BsCheck2Circle } from "react-icons/bs";
import HeaderLoader from "../HeaderLoader";
import LoginIndex from "../../Pages/Auth/LoginIndex";
import SignUpIndex from "../../Pages/Auth/SignUpIndex";
import { IconMyNextArrow } from "../../Images/MyIcons";

function Header({ settings, headerMenus, headerindexall, themeMode, isLoading, profileDetails, siteTheme, languages, languageDetailsData, props }) {

  const { lang } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState({ signUpModal: false, signInModal: false, })

  const access_token = localStorage.getItem("access_token");
  const expires_in = localStorage.getItem("expires_in");
  const image_url = headerindexall?.user_details?.user_avatar;
  const savedTheme = localStorage.getItem("site-theme");
  const [isScrolled, setIsScrolled] = useState(false);
  const user_role = localStorage.getItem("role");
  const [toggle, setToggle] = useState("");
  const [themeData, setThemeData] = useState(null)


  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    dispatch(headerIndexAction());
  }, [themeData]);

  useEffect(() => {
    if (user_role) {
      dispatch(profileDetailsAction());
    }
  }, [settings, user_role])

  useEffect(() => {
    dispatch(siteThemeAction());
    dispatch(displayTranslateLanguageAction());
  }, []);

  const toggleTheme = async () => {
    try {
      const data = await themeToggleMethod(themeMode?.currentMode == "dark" ? 1 : 0);
      setThemeData(data)
    } catch (error) {
      console.error("Error toggling theme:", error);
    }
  };
  const handleLogout = () => {
    dispatch(LogOutApi(navigate)); // Manually log the user out
  };

  // Set up auto-logout when the component mounts
  useAutoLogout(expires_in, navigate);
  // Set up auto-logout on component mount
  // useAutoLogout(expires_in, navigate);

  // useEffect(() => {
  //   const loginTime = localStorage.getItem('loginTime');
  //   let remainingTime = expires_in * 1000; // Convert expires_in to milliseconds if in seconds

  //   if (loginTime) {
  //     const elapsedTime = Date.now() - new Date(loginTime).getTime();
  //     remainingTime = remainingTime - elapsedTime; // Calculate remaining time based on elapsed time

  //     // If the token has already expired
  //     if (remainingTime <= 0) {
  //       dispatch(LogOutApi(navigate));
  //       // navigate('/'); // Log out immediately if token expired
  //     } else {
  //       // Set a timeout to log the user out when the token expires
  //       const expirationTimeout = setTimeout(() => {
  //         dispatch(LogOutApi(navigate));
  //         // navigate('/'); // Log out when remaining time ends
  //       }, remainingTime);

  //       // Clear the timeout if the component unmounts
  //       return () => clearTimeout(expirationTimeout);
  //     }
  //   } else {
  //     // Store the current time as the login time if not already stored
  //     localStorage.setItem('loginTime', new Date().toISOString());
  //   }
  // }, [expires_in, dispatch, navigate]);
  // useEffect(() => {
  //   const loginTime = localStorage.getItem('loginTime');
  //   let remainingTime = expires_in;

  //   if (loginTime) {
  //     const elapsedTime = Date.now() - new Date(loginTime).getTime();
  //     remainingTime = expires_in - elapsedTime;
  //     // If the token has already expired
  //     if (remainingTime <= 0) {
  //       handleLogout();
  //     } else {
  //       // Set a timeout to log the user out when the token expires
  //       const expirationTimeout = setTimeout(() => {
  //         handleLogout();
  //       }, remainingTime);
  //       // Clear the timeout if the component unmounts
  //       return () => clearTimeout(expirationTimeout);
  //     }
  //   } else {
  //     // Store the current time as the login time if not already stored
  //     localStorage.setItem('loginTime', new Date().toISOString());
  //   }
  // }, [expires_in]);

  // useEffect(() => {
  //   const loginTime = localStorage.getItem('loginTime');
  //   let remainingTime = expires_in;

  //   if (loginTime) {
  //     const elapsedTime = Date.now() - new Date(loginTime).getTime();
  //     remainingTime = expires_in - elapsedTime;
  //   } else {
  //     localStorage.setItem('loginTime', new Date().toISOString());
  //   }

  //   if (remainingTime <= 0) {
  //     logout();
  //   } else {
  //     const expirationTimeout = setTimeout(() => {
  //       logout();
  //     }, remainingTime);

  //     return () => clearTimeout(expirationTimeout);
  //   }
  // }, []);

  // function logout() {
  //   localStorage.removeItem('expires_in');
  //   localStorage.clear(); // Clear expiry time on logout
  //   window.location.href = '/';
  // }

  // function checkLogout() {
  //   const expiresIn = localStorage.getItem('expires_in');
  //   if (expiresIn) {
  //     const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
  //     if (currentTime >= parseInt(expiresIn)) {
  //       logout(); // Logout if expiry time has passed
  //     }
  //   }
  // }

  // // Call checkLogout periodically, for example every hour
  // setInterval(checkLogout, 1000 * 60 * 60); // Check every hour


  const renderNavLink = (link) => (
    <NavLink
      to={
        link.url === "site_url"
          ? `/${lang}${link.select_url.replace(/ /g, "_").toLowerCase()}`
          : link.url === "custom_url"
            ? link.custom_url
            : `/${lang}${link.url}`
      }
      key={link.url}
      onClick={() => setToggle(!toggle)}
      className="homeNavLink d-flex align-items-center gap-3"
    >
      <img src={link.menu_image_url} className="homeNavImage" />
      <span className="theme-text-color">{t(link.name)}</span>
    </NavLink>
  );

  const renderDropdown = (links) => (
    <li className="homeNavLink ">
      <span className="mb-0 d-flex align-items-center gap-3">
        <img src={links.menu_image_url} className="homeNavImage" />
        <span className="theme-text-color">{t(links.name)}</span>
        <IconMyNextArrow styled={{ className: "homeNavImageArrow" }} />
      </span>
      <ul className="list-unstyled  homeNavDropDown d-none pt-2 pb-3 theme-bg-color">
        {links?.dropdown?.map((link) => (
          <li onClick={() => setToggle(!toggle)} key={link.slug}>
            <NavLink
              to={`${links?.type
                ?.replace(/_/g, "-")
                .replace(/\s/g, "-")
                .toLowerCase()}/${link?.slug}`}
              key={link.slug}
              className=" d-block px-3 mt-2 homeNavLink theme-text-color"
            >
              {t(link.name)}
            </NavLink>
          </li>
        ))}
      </ul>
    </li>
  );

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleSignInModal = async () => {
    setModalOpen((prevState) => ({ ...prevState, signInModal: !prevState.signInModal }));
    document.body.classList.remove('overflow-hidden');
  }

  const handleSignUpModal = async () => {
    setModalOpen((prevState) => ({ ...prevState, signUpModal: !prevState.signUpModal }));
    document.body.classList.remove('overflow-hidden');
  }

  const handleSignInSwitchModal = async () => {
    setModalOpen((prevState) => ({ ...prevState, signInModal: !prevState.signInModal, signUpModal: !prevState.signUpModal }));
  }

  const handleSignUpSwitchModal = async () => {
    setModalOpen((prevState) => ({ ...prevState, signUpModal: !prevState.signUpModal, signInModal: !prevState.signInModal, }));
  }

  const firstName = profileDetails?.Profile_details?.username || "";
  const lastName = profileDetails?.Profile_details?.last_name || "";

  const initialsname = firstName && lastName
    ? firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase()
    : "";

  // const avatarUrl = image_url;
  const [avatarUrl, setAvatarUrl] = useState(image_url);
  // const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setAvatarUrl(image_url); // Update avatarUrl if image_url changes
  }, [image_url]);

  const handleImageLoad = () => {
    // setIsLoading(false); // Set loading to false once image has loaded
  };

  const handleImageError = () => {
    setAvatarUrl(null);
  };
  const themeStyle = {
    left: `${themeMode?.currentMode === "light" ? "3px" : "24px"}`,
    backgroundColor: `${themeMode?.currentMode === "light" ? "#fff" : "#fff"}`,
  };


  // const [editcppchannel, setEditcppchannel] = useState({
  //   cpp_portal: 0,
  //   channel_portal: 0,
  // });

  // const [inputFieldcpp, setinputFieldcpp] = useState({
  //   email: "admin@webnexs.in",
  //   password: "Webnexs123!@#",
  // });

  // const handleChangecpp = () => {
  //   if (localStorage.getItem("email") !== inputFieldcpp?.email) {
  //     window.location.href = "/cpp/login";
  //     return;
  //   } else {
  //     logincpp();
  //   }
  // }

  // async function logincpp() {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/CPP-auth/sign-in`,
  //       { email: inputFieldcpp?.email, password: inputFieldcpp?.password },
  //       { cors: "no-cors" },
  //       { credentials: "include" },
  //       { credentials: "same-origin" },
  //     )
  //     .then((res) => {
  //       // console.log(res)
  //       let access_token = res.data.access_token;
  //       let expires_in = res.data.expires_in;
  //       let username = res.data.CPP_user.username;
  //       let user_id = res.data.CPP_user.id;
  //       // let role = res.data.cpp_user.role;
  //       let cpp_image = res.data.CPP_user.cpp_image;
  //       localStorage.setItem("access_token_cpp", access_token);
  //       localStorage.setItem("cpp_Partner_id", user_id);
  //       // localStorage.setItem("role", role);
  //       localStorage.setItem("cpp_name", username);
  //       localStorage.setItem("cpp_image", cpp_image);
  //       localStorage.setItem("cpp_expires_in", expires_in);
  //       // localStorage.setItem("user", inputFieldcpp)
  //       setTimeout(() => {
  //         window.location.href = "/cpp/home";
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       // alert("Please Enter Correct Details");
  //       // console.log(err);
  //       window.location.href = "/cpp/login";
  //     });
  // }

  // const [inputFieldchannel, setinputFieldchannel] = useState({
  //   email: "mega@gmail.com",
  //   password: "Webnexs123!@#",
  // });

  // const handleChangechannel = () => {
  //   if (localStorage.getItem("email") !== headerindexall?.email) {
  //     window.location.href = `${lang}/channel/login`;
  //     return;
  //   } else {
  //     loginchannel();
  //   }
  // }

  // Function to perform login
  // async function loginchannel() {
  //   axios
  //     .post(
  //       `${process.env.REACT_APP_Baseurl}/channel-auth/sign-in`,
  //       { email: headerindexall?.email, password: headerindexall?.password },
  //       { cors: "no-cors" },
  //       { credentials: "include" },
  //       { credentials: "same-origin" },
  //     )
  //     .then((res) => {
  //       let access_token = res.data.access_token;
  //       let expires_in = res.data.expires_in;
  //       let channel_name = res.data.Channel_user.channel_name;
  //       let user_id = res.data.Channel_user.id;
  //       // let role = res.data.Channel_user.role;
  //       let channel_image = res.data.Channel_user.channel_image;
  //       localStorage.setItem("access_token_channel", access_token);
  //       localStorage.setItem("Channel_Partner_id", user_id);
  //       // localStorage.setItem("role", role);
  //       localStorage.setItem("channel_name", channel_name);
  //       localStorage.setItem("channel_image", channel_image);
  //       localStorage.setItem("channel_expires_in", expires_in);
  //       // localStorage.setItem("user", inputField)
  //       setTimeout(() => {
  //         window.location.href = `${lang}/channel`;
  //       }, 1000);
  //     })
  //     .catch((err) => {
  //       // window.location.href = `${lang}/channel/login`;
  //       // console.log(err);
  //     });
  // }
  useEffect(() => {
    const handleClick = () => {
      if (modalOpen?.signInModal || modalOpen?.signUpModal) {
        document.body.classList.add('overflow-hidden'); // Add class when modal is open
      } else {
        document.body.classList.remove('overflow-hidden'); // Remove class when modal is closed
      }
    };

    // Attach event listener
    document.addEventListener('click', handleClick);

    // Cleanup function to remove event listener when component unmounts or modal state changes
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [modalOpen?.signInModal, modalOpen?.signUpModal]);



  async function loginchannel() {
    const formData = new FormData();

    formData.append("email", headerindexall?.channel_partner_exists?.email);
    formData.append("password", headerindexall?.channel_partner_exists?.password);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    // setProcessing(true);
    // setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/channel-auth/sign-in`,
        formData,
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
        let access_token = resultapi.access_token;
        let expires_in = resultapi.expires_in;
        let channel_name = resultapi.Channel_user.channel_name;
        let user_id = resultapi.Channel_user.id;
        let role = resultapi.Channel_user.role;
        let channel_image = resultapi.Channel_user.channel_image;
        localStorage.setItem("access_token_channel", access_token);
        localStorage.setItem("Channel_Partner_id", user_id);
        localStorage.setItem("Channel_role", role);
        localStorage.setItem("channel_name", channel_name);
        localStorage.setItem("channel_image", channel_image);
        localStorage.setItem("channel_expires_in", expires_in);
        toast.success(t(resultapi?.message));
      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
        toast.error(t("These credentials do not match our records"));
      }
    } catch (error) { }

    if (firstApiStatus) {
      setTimeout(() => {
        window.location.href = `/${lang}/channel`
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.href = `/${lang}/channel/login`
      }, 3000);
      // setProcessing(false);
      // setShowOverlay(false);
    }
  }


  async function logincontent() {
    const formData = new FormData();

    formData.append("email", headerindexall?.content_partner_exists?.email);
    formData.append("password", headerindexall?.content_partner_exists?.password);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let resultapi = null;
    let resultapifalse = null;
    // setProcessing(true);
    // setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/CPP-auth/sign-in`,
        formData,
      );

      if (response?.data?.status === true) {
        firstApiStatus = true;
        resultapi = response?.data;
        let access_token = resultapi.access_token;
        let expires_in = resultapi.expires_in;
        let username = resultapi.CPP_user.username;
        let user_id = resultapi.CPP_user.id;
        let role = resultapi.CPP_user.role;
        let cpp_image = resultapi.CPP_user.cpp_image;
        localStorage.setItem("access_token_cpp", access_token);
        localStorage.setItem("cpp_Partner_id", user_id);
        localStorage.setItem("cpp_role", role);
        localStorage.setItem("cpp_name", username);
        localStorage.setItem("cpp_image", cpp_image);
        localStorage.setItem("cpp_expires_in", expires_in);


        toast.success(t(resultapi?.message));

      } else if (response?.data?.status === false) {
        // Handle the case when the first API response status is false
        resultapifalse = response?.data;
        toast.error(t("These credentials do not match our records"));
      }
    } catch (error) { }

    if (firstApiStatus) {
      setTimeout(() => {
        window.location.href = `/${lang}/cpp/home`
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.href = `/${lang}/cpp/login`
      }, 3000);
      // setProcessing(false);
      // setShowOverlay(false);
    }
  }

  return (
    <>
      {modalOpen?.signInModal && <LoginIndex popUpClass="position-fixed  popupModal d-flex align-items-center justify-content-center" headerDisable modalClose={handleSignInModal} signUpModal={handleSignUpSwitchModal} signInModal={handleSignInSwitchModal} />}
      {modalOpen?.signUpModal && <SignUpIndex popUpClass="position-fixed h-100 popupModal d-flex align-items-center justify-content-center" headerDisable modalClose={handleSignUpModal} signUpModal={handleSignUpSwitchModal} signInModal={handleSignInSwitchModal} />}

      {isLoading ? <HeaderLoader /> :
        <header className={`homePageNavbar  d-flex fixed-top justify-content-between align-items-center px-2 px-md-4 px-lg-2  ${isScrolled ? "scrolled" : ""}`}>
          <GiHamburgerMenu className="d-none toggleIconHome" onClick={handleToggle} />
          <Link to={`/${languageDetailsData?.Language_Code}`} className="py-1 position-relative homeNavbarBrand" style={{ width: "171px", height: "42px", objectFit: "contain" }}>
            <LazyLoadImage src={themeMode?.logoUrl} alt="header-logo" imageSize="loaderMain object-fit-contain " classNameOptional="rounded-2" />
          </Link>
          <div className={`homeNavbarMobileFixed ${toggle ? "active" : ""} theme-bg-color`}>
            <ul className={`d-flex justify-content-between align-items-center list-unstyled mb-0 homeNavSlide `} >
              <li className="d-none homeNavList closeIcons border-bottom border-secondary theme-text-color theme-bg-color" onClick={() => setToggle(!toggle)} >
                <IoClose style={{ width: "35px", height: "35px" }} />
              </li>
              {access_token !== null &&
                <li className="d-flex gap-2 flex-wrap align-items-center portalBlockMobile p-3">

                  <div className="d-flex gap-2 align-items-center portalBlock">
                    {headerindexall?.channel_partner_exists ? (
                      <button className="theme-button-bg-color accessButton" onClick={loginchannel}>
                        <span>{t("Channel Portal")}</span>
                      </button>
                    ) : null}
                    {headerindexall?.content_partner_exists ? (
                      <button className="theme-button-bg-color accessButton" onClick={logincontent}>
                        <span>{t("CPP Portal")}</span>
                      </button>
                    ) : null}
                  </div>
                  {siteTheme?.translate_checkout === 1 ? (
                    <div className="dropdown">
                      <button
                        className="bg-transparent"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <BsTranslate
                          style={{ width: "25px", height: "25px" }}
                          className="theme-text-color"
                        />
                      </button>
                      <ul className="dropdown-menu theme-bg-color theme-text-color languageDrop" style={{ maxWidth: "5rem" }} aria-labelledby="dropdownMenuButton1" >
                        {languages?.map(({ code, name }) => (
                          <li key={code} className={classNames("theme-text-color dropdown-item d-flex align-items-center gap-2 ", { disabled: languageDetailsData?.Language_Code === code, })} style={{ cursor: languageDetailsData?.Language_Code === code ? "" : "pointer", opacity: languageDetailsData?.Language_Code === code ? 1 : 0.7 }} onClick={() => dispatch(userChangeLanguage(code, navigate))}>
                            <span>{name}</span>
                            {languageDetailsData?.Language_Code === code && (<BsCheck2Circle className="ml-2 theme-text-color" style={{ fontSize: "20px" }} />)}
                          </li>
                        ))}
                      </ul>
                    </div>
                  )
                    : null}
                </li>
              }
              {headerMenus?.filter((url) => url?.active).map((url) => (
                <li className="homeNavList p-3" key={url.name}>
                  {url.dropdown.length > 0
                    ? renderDropdown(url)
                    : renderNavLink(url)}
                </li>
              ))}
            </ul>
          </div>
          <div className="d-flex align-items-center gap-3">
            {access_token !== null ? (
              <>
                <div className="d-flex gap-2 align-items-center portalBlock">
                  {headerindexall?.channel_partner_exists ? (
                    <button className="theme-button-bg-color accessButton" onClick={loginchannel}>
                      <span>{t("Channel Portal")}</span>
                    </button>
                  ) : null}
                  {headerindexall?.content_partner_exists ? (
                    <button className="theme-button-bg-color accessButton" onClick={logincontent}>
                      <span>{t("CPP Portal")}</span>
                    </button>
                  ) : null}
                </div>


                {/* <div class="searchBox ms-0 ms-md-2 position-relative">
                  <input
                    type="search"
                    className="theme-button-bg-color  theme-text-color searchInputHeader border-0 rounded-3 outline-0 px-3 py-2"
                    placeholder="Search"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  <i class="fa fa-search"></i>
                  <IoSearch className="icon-search" style={{ width: "40px", height: "62px" }} />
                </div> */}
                <Link to={`/${languageDetailsData?.Language_Code}/search`}> <IoSearch className=" theme-text-color" style={{ width: "25px", height: "25px" }} /></Link>
                {siteTheme?.translate_checkout === 1 ? (
                  <div className="align-items-center mx-0 mx-md-3 d-none d-md-flex dropdown">
                    <button className="btn languageBtn bg-transparent theme-text-color" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <BsTranslate style={{ width: "25px", height: "25px" }} className="" />
                    </button>
                    <ul className="dropdown-menu theme-bg-color theme-text-color languageDrop" style={{ maxWidth: "5rem" }} aria-labelledby="dropdownMenuButton1" >
                      {languages.map(({ code, name }) => (
                        <li key={code} className={classNames("theme-text-color dropdown-item d-flex align-items-center gap-2 ", { disabled: languageDetailsData?.Language_Code === code, })} style={{ cursor: languageDetailsData?.Language_Code === code ? "" : "pointer", opacity: languageDetailsData?.Language_Code === code ? 1 : 0.7 }} onClick={() => dispatch(userChangeLanguage(code, navigate))}>
                          <span>{name}</span>
                          {languageDetailsData?.Language_Code === code && (<BsCheck2Circle className="ml-2 theme-text-color" style={{ fontSize: "20px" }} />)}
                        </li>
                      ))}
                    </ul>
                  </div>)
                  : null}

                <div className="d-flex justify-content-between align-items-center  bg-transparent userContainer  position-relative avatarUrlinitialsname">
                  {/* <span className="profile-image bgButton p-1"> */}
                  {isLoading && (
                    <div className="loading-spinner">Loading...</div>
                  )}
                  {!isLoading && avatarUrl === null ? (
                    // If avatarUrl is null after loading, show the initials
                    <span className="profile-image bgButton p-1">
                      <div className="initials-circle">
                        {initialsname}
                      </div>
                    </span>
                  ) : (
                    // For a valid URL, show the image
                    <img
                      src={avatarUrl || ''}
                      alt={initialsname}
                      onLoad={handleImageLoad}
                      onError={handleImageError}
                      style={{ display: avatarUrl ? 'block' : 'none' }} // Hide image if avatarUrl is null
                    />
                  )}


                  {/* </span> */}
                  <div className="userListBox d-none  ">
                    <div className="theme-bg-color p-3 shadowHeaderMenu rounded-2">
                      {/* <div className="d-flex justify-content-between align-items-center" onClick={toggleTheme} >
                        <p className="headerSubLink theme-text-color  d-block mt-2 mb-0">
                          {themeMode?.currentMode == "light" ? t("Light Mode") : t("Dark Mode")}
                        </p>
                        <div className="position-relative toggleButtonTheme toggleButton-border">
                          <div
                            className="toggleCircleButton bgButton"
                            style={themeStyle}
                          ></div>
                        </div>
                      </div> */}
                      <Link className="headerSubLink d-block mt-2" to={`/${lang}/myprofile`}>
                        {t("My Profile")}
                      </Link>
                      <Link className="headerSubLink d-block mt-2" to={`/${lang}/choose-profile`}>
                        {t("Change Profile")}
                      </Link>
                      <Link className="headerSubLink d-block  mt-2" to={`/${lang}/watchlater`}>
                        {t("Watch Later")}
                      </Link>
                      <Link className="headerSubLink d-block  mt-2" to={`/${lang}/mywishlists`}>
                        {t("Completed")}
                      </Link>
                      {/* <Link className="headerSubLink d-block  mt-2" to={`/${lang}/purchase-media`}>
                        {t("Purchase Media")}
                      </Link> */}
                      {user_role == 1
                        ? <Link
                          className="headerSubLink d-block  mt-2"
                          to={`${process.env.REACT_APP_API_PATHss_admin_page}`}
                          target="_self"
                          rel="noreferrer"
                        >{t("Admin")}
                        </Link>
                        : null}
                      <Link
                        className="headerSubLink d-block  mt-2"
                        to="#"
                        onClick={handleLogout}
                      >
                        {t("Logout")}
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <Link to={`/${languageDetailsData?.Language_Code}/search`}> <IoSearch className=" theme-text-color" style={{ width: "25px", height: "25px" }} /></Link>
                {siteTheme?.translate_checkout === 1 ? (
                  <div className="d-flex gap-2 align-items-center dropdown">
                    <button className="btn btn-link p-0 me-lg-2 me-0 bg-transparent" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                      <BsTranslate style={{ width: "25px", height: "25px" }} className="theme-text-color" />
                    </button>
                    <ul className="dropdown-menu theme-bg-color theme-text-color languageDrop" style={{ maxWidth: "5rem" }} aria-labelledby="dropdownMenuButton1" >
                      {languages.map(({ code, name }) => (
                        <li key={code} className={classNames("theme-text-color dropdown-item d-flex align-items-center gap-2 ", { disabled: languageDetailsData?.Language_Code === code, })} style={{ cursor: languageDetailsData?.Language_Code === code ? "" : "pointer", opacity: languageDetailsData?.Language_Code === code ? 1 : 0.7 }} onClick={() => dispatch(userChangeLanguage(code, navigate))}>
                          <span>{name}</span>
                          {languageDetailsData?.Language_Code === code && (<BsCheck2Circle className="ml-2 theme-text-color" style={{ fontSize: "25px" }} />)}
                        </li>
                      ))}
                    </ul>
                  </div>)
                  : null}
                <div className="d-flex align-items-center gap-md-5 gap-2">
                  <div className="headerSubLink">
                    <button type="button" className="theme-text-color p-0 bg-transparent" onClick={handleSignInModal}  >
                      <BsBoxArrowInRight className="highlightfillIcon" style={{ fontSize: "24px", }} />
                      <span className="d-none d-sm-inline ms-lg-3 ms-0"> {t("Signin")} </span>
                    </button>
                  </div>
                  <div className="headerSubLink">
                    <button type="button" className="theme-text-color p-0 bg-transparent" onClick={handleSignUpModal} >
                      <BsBoxArrowInLeft className="highlightfillIcon" style={{ fontSize: "24px", }} />
                      <span className="d-none d-sm-inline ms-lg-3 ms-0"> {t("Signup")} </span>
                    </button>
                  </div>
                </div>

              </>
            )}
          </div>
        </header>
      }
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  props: ownProps,
  settings: state.get_setting_Reducer.settings,
  headerMenus: state.get_headerIndex_Reducer.headerMenus,
  isLoading: state.get_headerIndex_Reducer.isLoading,
  themeMode: state.get_headerIndex_Reducer.themeMode,
  profileDetails: state.get_profileDetails_Reducer.profileDetails,
  siteTheme: state.get_siteTheme_Reducer.siteTheme,
  languages: state.get_displayTranslateLanguage_Reducer.languages,
  languageDetailsData: state.get_getUserTranslateLanguage_Reducer.languageDetailsData,
  headerindexall: state.get_headerIndex_Reducer.headerindexall,

});
export default connect(mapStateToProps)(Header);