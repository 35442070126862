import { useNavigate, useParams } from "react-router-dom";
import JoditEditor from "jodit-react";
import React, { useState, useRef, useEffect, useCallback, } from "react";
import axios from "axios";
import { ProgressBar } from "react-bootstrap";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import Select from "react-select";

import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import "./AddNewVideo.css";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify"
import { TagsInput } from "react-tag-input-component";
import CameraRetroIcon from "@rsuite/icons/legacy/CameraRetro";
import { useDropzone } from "react-dropzone";
import ResponseLoader from "../../../components/Others/ResponseLoader";
import MessageBox from "../../../components/Others/MessageBox";
import { IoIosArrowForward } from "react-icons/io";
import AdminSaveHeader from "../../../components/Others/adminCommonComponents/AdminSaveHeader";
import Wrapper from "../../CommonComponents/Wrapper";
import MessageLabel from "../../CommonComponents/MessageLabel";
import { useTranslation } from "react-i18next";


export default function AddNewVideo() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const [file, setFile] = useState("");
  const [video_id, setVideo_id] = useState("");

  const [video_type, setVideo_type] = useState("");
  const [m3u8_url, setM3u8_url] = useState("");
  const [mp4_url, setMp4_url] = useState("");
  const [embed_code, setEmbed_code] = useState("");
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [slug, setSlug] = useState("");
  const [type, setType] = useState("");
  const [active, setActive] = useState("0");
  const [status, setStatus] = useState("0");
  const [draft, setDraft] = useState("");
  const [rating, setRating] = useState("");
  const [duration, setDuration] = useState("");
  const [details, setDetails] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [player_image, setPlayer_image] = useState("");
  const [video_tv_image, setVideo_tv_image] = useState("");
  const [mobile_image, setMobile_image] = useState("");
  const [tablet_image, setTablet_image] = useState("");
  const [enable_video_title_image, setEnable_video_title_image] = useState("");
  const [video_title_image, setVideo_title_image] = useState("");
  const [android_tv, setAndroid_tv] = useState("");
  const [video_gif, setVideo_gif] = useState("");
  const [searchTags, setSearchTags] = useState([]);
  const [access, setAccess] = useState("");
  const [global_ppv, setGlobal_ppv] = useState("");
  const [ppv_price, setPpv_price] = useState("");
  const [ios_ppv_price, setIos_ppv_price] = useState("");
  const [featured, setFeatured] = useState("");
  const [banner, setBanner] = useState("");
  const [enable, setEnable] = useState("");
  const [webm_url, setWebm_url] = useState("");
  const [ogg_url, setOgg_url] = useState("");
  const [disk, setDisk] = useState("");
  const [original_name, setOriginal_name] = useState("");
  const [path, setPath] = useState("");
  const [stream_path, setStream_path] = useState("");
  const [processed_low, setProcessed_low] = useState("");
  const [converted_for_streaming_at, setConverted_for_streaming_at] =
    useState("");
  const [views, setViews] = useState("");
  const [year, setYear] = useState("");
  const [Recommendation, setRecommendation] = useState("");
  const [country, setCountry] = useState("");
  const [publish_status, setPublish_status] = useState("");
  const [publish_time, setPublish_time] = useState("");
  const [skip_recap, setSkip_recap] = useState("");
  const [skip_intro, setSkip_intro] = useState("");
  const [recap_start_time, setRecap_start_time] = useState("");
  const [recap_end_time, setRecap_end_time] = useState("");
  const [intro_start_time, setIntro_start_time] = useState("");
  const [intro_end_time, setIntro_end_time] = useState("");
  const [pdf_files, setPdf_files] = useState("");
  const [reels_thumbnail, setReels_thumbnail] = useState("");
  const [reelvideo, setReelvideo] = useState("");
  const [url, setUrl] = useState("");
  const [url_link, setUrl_link] = useState("");
  const [url_linktym, setUrl_linktym] = useState("");
  const [url_linksec, setUrl_linksec] = useState("");
  const [urlEnd_linksec, setUrlEnd_linksec] = useState("");
  const [trailer_type, setTrailer_type] = useState("");
  const [trailer, setTrailer] = useState("");
  const [trailer_filename, setTrailer_filename] = useState("");
  const [trailer_description, setTrailer_description] = useState("");
  const [ads_status, setAds_status] = useState("");
  const [free_duration_status, setFree_duration_status] = useState("");
  const [default_ads, setDefault_ads] = useState("");
  const [pre_ads_category, setPre_ads_category] = useState("");
  const [mid_ads_category, setMid_ads_category] = useState("");
  const [post_ads_category, setPost_ads_category] = useState("");
  const [pre_ads, setPre_ads] = useState("");
  const [mid_ads, setMid_ads] = useState("");
  const [post_ads, setPost_ads] = useState("");
  const [time_zone, setTime_zone] = useState("");
  const [home_genre, setHome_genre] = useState("");
  const [in_menu, setIn_menu] = useState("");
  const [footer, setFooter] = useState("");
  const [uploaded_by, setUploaded_by] = useState("");
  const [user_id, setUser_id] = useState("");
  const [video_category_id, setVideo_category_id] = useState("");
  const [artist, setArtist] = useState("");
  const [ads_tag_url_id, setAds_tag_url_id] = useState("");

  const [websitetitle, setWebsitetitle] = useState("");
  const [metadescription, setMetadescription] = useState("");
  const [websiteurl, setWebsiteurl] = useState("");
  const [Subtitlesmap, setSubtitlesmap] = useState([]);

  const [message, setMessage] = useState("");

  const [publishType, setPublishType] = useState("publish_now");
  const [publishTime, setPublishTime] = useState("");

  const access_token_channel = localStorage.getItem("access_token_channel");
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + access_token_channel,
    Accept: "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  const handlePublishTypeChange = (event) => {
    const selectedValue = event.target.value;
    setPublishType(selectedValue);

    if (selectedValue == "publish_later") {
      setPublishTime(""); // Reset the publish time when switching to "Publish Later"
    }
  };

  const handlePublishTimeChange = (event) => {
    const timeValue = event.target.value;
    setPublishTime(timeValue);
  };

  const [videosreels, setVideosreels] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState([]);

  const handleDropreels = (acceptedFiles) => {
    setVideosreels(acceptedFiles);
  };

  const [videosubtitles, setVideoSubtitles] = useState({});

  const handleDropsubtitles = (language, acceptedFiles) => {
    // Handle dropped files here
    setVideoSubtitles((prevState) => ({
      ...prevState,
      [language]: acceptedFiles,
    }));
  };

  const [autoFillValuetitle, setAutoFillValuetitle] = useState("");

  const handleInputKeyDowntitle = (event) => {
    if (event.key == "Enter" || event.key == "Tab") {
      generateSlug(editUser?.title);
    }
  };
  const handleBlurtitle = () => {
    generateSlug(editUser?.title);
  };

  const handleAutoFillChangetitle = (event) => {
    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setAutoFillValuetitle(event.target.value);
  };

  const generateSlug = (title) => {
    title = title.trim();
    const slug = title.toLowerCase().replace(/\s+/g, "-");
    setAutoFillValuetitle(slug);
  };

  const [publish_type, setPublish_type] = useState("");
  const [user_access, setUser_access] = useState([]);
  const [useraccess, setUserAccess] = useState("");
  const [inapppurchase, setInappPurchase] = useState("");
  const [age_restrictdata, setAge_restrictdata] = useState([]);
  const [age_restrict, setAge_restrict] = useState([]);

  const [adsPositions, setAdsPositions] = useState([]);
  const [selectedAdsPosition, setSelectedAdsPosition] = useState(null);
  const [secondSelectOptions, setSecondSelectOptions] = useState([]);

  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValueAPI, setInputValueAPI] = useState("");

  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
    if (!checkboxChecked) {
      setInputValue(inputValueAPI);
    } else {
      setInputValue("");
    }
  };

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
  const navigate = useNavigate();

  const [editUser, setEditUser] = useState({
    title: "",
    duration: "",
    slug: "",
    free_duration_time: "",
    free_duration_status: "",
    active: "",
    year: "",
    ppv_price: "",
    source_type: "",
    PPVType: "",
    global_ppv: "",
    ios_ppv_price: "",

  });
  // console.log(editUser)

  const [selectedFiles, setSelectedFiles] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFiles(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "video/*",
  });

  const [inputValueyear, setInputValueyear] = useState("");

  // const handleChangeyear = (event) => {
  //   const value = event.target.value;
  //   if (/^\d{0,4}$/.test(value)) {
  //     setInputValueyear(value);
  //   }
  // };

  const handleChangeyear = (event) => {
    setValidationMessageyear("");
    setIsValidationHiddenyear(true);
    const value = event.target.value;
    setEditUser({ ...editUser, year: value });
  };

  const handleInputdata = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    setValidationMessageslug("");
    setIsValidationHiddenslug(true);
    setValidationMessagetitle("");
    setIsValidationHiddentitle(true);

    setEditUser({ ...editUser, title: e.target.value });
    setEditUser({ ...editUser, [e.target.name]: e.target.value });

    if (e.target.checked == true) {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 1 });
      setFeatured({ ...featured, [e.target.name]: 1 });
      setBanner({ ...banner, [e.target.name]: 1 });
      setActive({ ...active, [e.target.name]: 1 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 1,
      });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 1,
      });
    } else {
      setGlobal_ppv({ ...global_ppv, [e.target.name]: 0 });
      setFeatured({ ...featured, [e.target.name]: 0 });
      setBanner({ ...banner, [e.target.name]: 0 });
      setActive({ ...active, [e.target.name]: 0 });
      setEnable_video_title_image({
        ...enable_video_title_image,
        [e.target.name]: 0,
      });
      setFree_duration_status({
        ...free_duration_status,
        [e.target.name]: 0,
      });
    }
  };

  const [userTime, setUserTime] = useState({
    skip_start_time: "",
    skip_end_time: "",
    skip_start_session: "",
    recap_start_time: "",
    recap_end_time: "",
    recap_start_session: "",
    url_linktym: "",
  });

  const insertColons = (time) => {
    if (time?.length >= 6) {
      const hours = time?.substr(0, 2);
      const minutes = time?.substr(2, 2);
      const seconds = time?.substr(4, 2);

      return `${hours}:${minutes}:${seconds}`;
    }
    return time;
  };

  const [errors, setErrors] = useState({
    error_skip_start_time: "",
    error_skip_end_time: "",
    error_skip_start_session: "",
    error_recap_start_time: "",
    error_recap_end_time: "",
    error_recap_start_session: "",
  });

  const handleInputintrotime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setUserTime({
      ...userTime,
      [inputName]: formattedTime,
    });

    validateInput(inputName, formattedTime);
  };

  const validateInput = (inputName, formattedTime) => {
    const errorsCopy = { ...errors };

    switch (inputName) {
      case "skip_start_time":
        if (formattedTime == "") {
          errorsCopy.error_skip_start_time = "";
        } else {
          errorsCopy.error_skip_start_time = "";
        }
        break;

      case "skip_end_time":
        if (formattedTime == "") {
          errorsCopy.error_skip_end_time = "";
        } else if (formattedTime <= userTime.skip_start_time) {
          errorsCopy.error_skip_end_time =
            "Skip End time must be greater than Skip Start time";
        } else {
          errorsCopy.error_skip_end_time = "";
        }
        break;

      case "skip_start_session":
        if (formattedTime == "") {
          errorsCopy.error_skip_start_session = "";
        } else if (formattedTime <= userTime.skip_end_time) {
          errorsCopy.error_skip_start_session =
            "Skip Start Session must be greater than Skip End time";
        } else {
          errorsCopy.error_skip_start_session = "";
        }
        break;

      case "recap_start_time":
        if (formattedTime == "") {
          errorsCopy.error_recap_start_time = "";
        } else {
          errorsCopy.error_recap_start_time = "";
        }
        break;

      case "recap_end_time":
        if (formattedTime == "") {
          errorsCopy.error_recap_end_time = "";
        } else if (formattedTime <= userTime.recap_start_time) {
          errorsCopy.error_recap_end_time =
            "Recap End time must be greater than Recap Start time";
        } else {
          errorsCopy.error_recap_end_time = "";
        }
        break;

      case "recap_start_session":
        if (formattedTime == "") {
          errorsCopy.error_recap_start_session = "";
        } else if (formattedTime <= userTime.recap_end_time) {
          errorsCopy.error_recap_start_session =
            "Recap Start Session must be greater than Recap End time";
        } else {
          errorsCopy.error_recap_start_session = "";
        }
        break;

      default:
        break;
    }

    setErrors(errorsCopy);
  };

  const handleAdsPositionChange = (selectedValue5) => {
    setSelectedAdsPosition(selectedValue5);
  };

  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setSelectedFile(selectedFile);
    uploadFile(selectedFile);
  };

  const uploadFile = (selectedFile) => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const xhr = new XMLHttpRequest();
      xhr.open(
        "POST",
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-upload-file`,
        { headers: headers }
      );
      xhr.setRequestHeader("Authorization", "Bearer " + access_token_channel);

      xhr.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const progress = Math.round((event.loaded / event.total) * 100);
          setUploadProgress(progress);
        }
      });

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {
          // File upload completed
          alert("Added successfully  !");

          toast.success(t("Added successfully"));
          // console.log("Upload completed");
        }
      };

      xhr.send(formData);
    }
  };

  const getFileSizeInMB = () => {
    if (selectedFile) {
      const fileSizeInBytes = selectedFile.size;
      const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
      return fileSizeInMB.toFixed(2);
    }
    return null;
  };

  const [uploadPercentage, setUploadPercentage] = useState(0);
  const cancelFileUpload = useRef(null);

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has canceled the file upload.");
  };

  const [uploadCompleteddata, setUploadCompleteddata] = useState(false);

  async function urlsupload() {
    const formData = new FormData();

    formData.append("video_type", selectedOption);
    formData.append("m3u8_url", m3u8_url);
    formData.append("mp4_url", mp4_url);
    formData.append("embed_code", embed_code);
    // console.log('append data', video_type,)

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-upload-url`,
        formData,
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log('api' , response);
        if (response?.data?.status == true) {
          var resultapi = response.data;
          var result = response?.data?.video_id;
          setVideo_id(result);
          setUploadCompleteddata(true);
          toast.success(t(resultapi));
        } else {
          if (response?.data?.status == false) {
            var resultError = response.data;
            toast.success(t(resultError));
            // console.log(resultError)
          }
        }
      });
  }

  const contentFieldChanaged = (data) => {
    setDetails(data);
    // console.log("datasssssss", data);
  };
  const contentFieldChanagedtraildes = (data) => {
    setTrailer_description(data);
    // console.log("datasssssss", data);
  };

  const [selectedValue, setSelectedValue] = useState("");
  const [apiResponse, setApiResponse] = useState("");

  const handleSelectChanges = async (event) => {
    const { value } = event.target;
    setSelectedValue(value);

    const formData = new FormData();

    formData.append("value", value);

    await axios
      .post(
        `${process.env.REACT_APP_Baseurl}/admin/advertisement-category-depends`,
        formData,
        { headers: headers }
      )
      .then((response) => {
        // console.log("apiasas", response);
        if (response?.data?.status == true) {
          var result = response.data;

          // console.log("api", result);
        } else {
          console.log("Error");
          alert("Enter Correct Details");
        }
      })
      .catch((err) => {
        console.log("Error");
        alert("Enter Correct Details");
      });
  };

  const fileInputRef1 = useRef(null);
  const fileInputRef2 = useRef(null);
  const fileInputRef3 = useRef(null);
  const fileInputRef4 = useRef(null);
  const fileInputRef5 = useRef(null);

  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedFile4, setSelectedFile4] = useState(null);
  const [selectedFile5, setSelectedFile5] = useState(null);

  const handleFileChangesss = (event, fileInputRef, setSelectedFile) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleDelete = (
    setSelectedFile1,
    setSelectedFile2,
    setSelectedFile3,
    setSelectedFile4,
    setSelectedFile5
  ) => {
    setSelectedFile1(null);
    if (fileInputRef1.current) {
      fileInputRef1.current.value = null;
    }
    setSelectedFile2(null);
    if (fileInputRef2.current) {
      fileInputRef2.current.value = null;
    }
    setSelectedFile3(null);
    if (fileInputRef3.current) {
      fileInputRef3.current.value = null;
    }
    setSelectedFile4(null);
    if (fileInputRef4.current) {
      fileInputRef4.current.value = null;
    }
    setSelectedFile5(null);
    if (fileInputRef5.current) {
      fileInputRef5.current.value = null;
    }
  };

  // Multiselect
  const [block_country, setBlock_Country] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [available_country, setAvailable_country] = useState([]);
  const [
    selectedOptionsAvailable_country,
    setSelectedOptionsAvailable_country,
  ] = useState([]);
  const [countryblock_country, setCountryblock_country] = useState([]);
  const [
    selectedOptionsCountryblock_country,
    setSelectedOptionsCountryblock_country,
  ] = useState([]);

  const [related_videos, setRelated_videos] = useState([]);
  const [selectedOptionsRelated_videos, setSelectedOptionsRelated_videos] =
    useState([]);

  const [video_playlist, setVideo_playlist] = useState([]);
  const [selectedOptionsvideo_playlist, setSelectedOptionsvideo_playlist] =
    useState([]);

  const [language, setLanguage] = useState([]);
  const [selectedOptionslanguage, setSelectedOptionslanguage] = useState([]);

  const [artists, setArtists] = useState([]);
  const [selectedOptionsartists, setSelectedOptionsartists] = useState([]);

  const [category, setCategory] = useState([]);
  const [selectedOptionscategory, setSelectedOptionscategory] = useState([]);

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`,
        { headers: headers }
      );
      setCountryblock_country(response?.data?.Block_Country);
      setAvailable_country(response?.data?.Available_country);
      setRelated_videos(response?.data?.Related_videos);
      setVideo_playlist(response?.data?.all_video_playlist);
      setLanguage(response?.data?.video_language);
      // setAge_Restrict(response?.data?.Age_Restrict);
      setArtists(response?.data?.video_artist);
      setCategory(response?.data?.video_category);
      setInputValueAPI(response?.data?.ppv_gobal_price);
      const subtitlesmap = response?.data?.video_subtitle;
      setSubtitlesmap(subtitlesmap);
      var age_restrictdata = response?.data?.Age_Restrict;

      setAge_restrictdata(age_restrictdata);

      var result = response?.data?.user_access;
      var InappPurchase = response?.data?.InappPurchase;
      setUser_access(result);
      setInappPurchase(InappPurchase);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };

  const handleSelectChangeRelated_videos = (selectedValues) => {
    setSelectedOptionsRelated_videos(selectedValues);
  };
  const handleSelectChangevideo_playlist = (selectedValues) => {
    setSelectedOptionsvideo_playlist(selectedValues);
  };
  const handleSelectChangeAvailable_country = (selectedValues) => {
    setSelectedOptionsAvailable_country(selectedValues);
  };
  const handleSelectChangeCountryblock_country = (selectedValues) => {
    setSelectedOptionsCountryblock_country(selectedValues);
  };

  const handleSelectChangelanguage = (selectedValues) => {
    setValidationMessagelanguage("");
    setIsValidationHiddenlanguage(true);
    setSelectedOptionslanguage(selectedValues);
  };

  const handleSelectChangeartists = (selectedValues) => {
    setSelectedOptionsartists(selectedValues);
  };

  const handleSelectChangecategory = (selectedValues) => {
    setValidationMessagecategory("");
    setIsValidationHiddencategory(true);
    setSelectedOptionscategory(selectedValues);
  };


  const handleInputenabletime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.checked ? 1 : 0;

    setEditUser({
      ...editUser,
      [inputName]: inputValue,
    });
  };

  const handleInputdatatime = (e) => {
    const inputName = e.target.name;
    const inputValue = e.target.value;

    // Remove non-digit characters from input
    const formattedInput = inputValue.replace(/\D/g, "");

    // Add colons to maintain the format HH:MM:SS
    const formattedTime = formattedInput
      .replace(/^(\d{2})(\d{2})(\d{2})$/, "$1:$2:$3")
      .substr(0, 8);

    setEditUser({
      ...editUser,
      [inputName]: formattedTime,
    });
  };



  const [validationMessagefree_duration_time, setValidationMessagefree_duration_time] = useState('');
  const [isValidationHiddenfree_duration_time, setIsValidationHiddenfree_duration_time] = useState(true);


  const free_duration_timeInputRef = useRef(null);


  // Multiselect

  const handleTagsChange = (newTags) => {
    // Convert tags to lowercase, remove duplicates, and filter out empty tags
    const cleanedTags = newTags
      .map((tag) => tag.trim().toLowerCase())
      .filter((tag) => tag !== "")
      .filter((value, index, self) => self.indexOf(value) == index);
    setSearchTags(cleanedTags);
  };

  const [validationMessagetitle, setValidationMessagetitle] = useState("");
  const [isValidationHiddentitle, setIsValidationHiddentitle] = useState(true);

  const [validationMessageyear, setValidationMessageyear] = useState("");
  const [isValidationHiddenyear, setIsValidationHiddenyear] = useState(true);

  const [validationMessageslug, setValidationMessageslug] = useState("");
  const [isValidationHiddenslug, setIsValidationHiddenslug] = useState(true);

  const [validationMessagecategory, setValidationMessagecategory] =
    useState("");
  const [isValidationHiddencategory, setIsValidationHiddencategory] =
    useState(true);

  const [validationMessagelanguage, setValidationMessagelanguage] =
    useState("");
  const [isValidationHiddenlanguage, setIsValidationHiddenlanguage] =
    useState(true);

  const [validationMessageAccess, setValidationMessageAccess] =
    useState("");
  const [isValidationHiddenAccess, setIsValidationHiddenAccess] =
    useState(true);

  const titleInputRef = useRef(null);
  const categoryInputRef = useRef(null);
  const languageInputRef = useRef(null);
  const accessInputRef = useRef(null);

  const [processing, setProcessing] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  async function videoupload() {
    let focusInputRef = null;

    if (editUser?.title == "") {
      setValidationMessagetitle("Title cannot be empty*.");
      setIsValidationHiddentitle(false);
      focusInputRef = titleInputRef;
    }
    if (selectedOptionscategory?.length == 0) {
      setValidationMessagecategory("Please select at least one Category*.");
      setIsValidationHiddencategory(false);
      focusInputRef = categoryInputRef;
    }
    if (selectedOptionslanguage?.length == 0) {
      setValidationMessagelanguage("Please select at least one Language*.");
      setIsValidationHiddenlanguage(false);
      focusInputRef = languageInputRef;
    }
    if (!access) {
      setValidationMessageAccess("Please select user access.");
      setIsValidationHiddenAccess(false);
      focusInputRef = accessInputRef;
    }


    setValidationMessagefree_duration_time("");
    setIsValidationHiddenfree_duration_time(true);

    if (editUser?.free_duration_status === 1) {
      // Validate free_duration_time only if free_duration_status is active (1)
      if (!editUser?.free_duration_time) {
        setValidationMessagefree_duration_time("If Free Duration Status is active, Free Duration Time is mandatory.");
        setIsValidationHiddenfree_duration_time(false);
        focusInputRef = free_duration_timeInputRef;
        return;
      }

      // Additional validation for free_duration_time format can be added here if needed
      // e.g., regex check for HH:MM:SS format
    }


    if (focusInputRef) {
      focusInputRef.current.focus();
      return;
    }




    // const editInputvalue = active.active;
    // var data = JSON.stringify(editInputvalue);
    // var aactivedata = data;

    const editInputfree_duration_statusdata =
      free_duration_status.free_duration_status;
    var data = JSON.stringify(editInputfree_duration_statusdata);
    var free_duration_statusdata = data;

    const editInputglobal_ppv = global_ppv.global_ppv;
    var dataglobal_ppv = JSON.stringify(editInputglobal_ppv);
    var global_ppvdata = dataglobal_ppv;

    const editInputfeatured = featured.featured;
    var datafeatured = JSON.stringify(editInputfeatured);
    var featureddata = datafeatured;

    const editInputbanner = banner.banner;
    var databanner = JSON.stringify(editInputbanner);
    var bannerdata = databanner;

    const editInputenable_video_title_image =
      enable_video_title_image.enable_video_title_image;
    var dataenable_video_title_image = JSON.stringify(
      editInputenable_video_title_image
    );
    var enable_video_title_imagedata = dataenable_video_title_image;

    const formDatastore = new FormData();

    formDatastore.append("video_id", video_id);
    formDatastore.append("title", editUser?.title);
    formDatastore.append("slug", autoFillValuetitle);
    formDatastore.append("type", type);
    formDatastore.append("active", 0);
    formDatastore.append("status", status ? parseInt(status, 10) : 0);
    // formDatastore.append("draft", draft);
    formDatastore.append("rating", rating ? parseInt(rating, 10) : 0);
    formDatastore.append("duration", editUser?.duration);
    formDatastore.append("details", details);
    formDatastore.append("description", description);
    formDatastore.append("android_tv", android_tv);
    formDatastore.append("video_gif", video_gif);
    formDatastore.append("search_tags", searchTags);
    formDatastore.append("access", access);
    formDatastore.append("global_ppv", global_ppvdata ? parseInt(global_ppvdata, 10) : 0);
    formDatastore.append("ppv_price", inputValue);
    formDatastore.append("ios_ppv_price", ios_ppv_price);
    const enablefeatureddata = Number.isNaN(parseInt(featureddata, 10)) ? 0 : parseInt(featureddata, 10);
    formDatastore.append("featured", enablefeatureddata);
    const enablebannerdata = Number.isNaN(parseInt(bannerdata, 10)) ? 0 : parseInt(bannerdata, 10);
    formDatastore.append("banner", enablebannerdata);
    const enablefooterdata = Number.isNaN(parseInt(footer, 10)) ? 0 : parseInt(footer, 10);
    formDatastore.append("footer", enablefooterdata);

    formDatastore.append("enable", enable ? parseInt(enable, 10) : 0);
    formDatastore.append("webm_url", webm_url);
    formDatastore.append("ogg_url", ogg_url);
    formDatastore.append("disk", disk);
    formDatastore.append("original_name", original_name);
    formDatastore.append("path", path);
    formDatastore.append("stream_path", stream_path);
    formDatastore.append("processed_low", processed_low);
    formDatastore.append(
      "converted_for_streaming_at",
      converted_for_streaming_at
    );
    formDatastore.append("views", views ? parseInt(views, 10) : 0);
    formDatastore.append("year", editUser?.year);

    formDatastore.append(
      "artist_id",
      JSON.stringify(selectedOptionsartists?.map((option) => option?.value))
    );
    formDatastore.append(
      "block_country_id",
      JSON.stringify(
        selectedOptionsCountryblock_country?.map((option) => option?.value)
      )
    );
    formDatastore.append(
      "available_countries_id",
      JSON.stringify(
        selectedOptionsAvailable_country?.map((option) => option?.value)
      )
    );
    formDatastore.append(
      "category_id",
      JSON.stringify(selectedOptionscategory?.map((option) => option?.value))
    );
    formDatastore.append(
      "languages_id",
      JSON.stringify(selectedOptionslanguage?.map((option) => option?.value))
    );
    formDatastore.append("age_restrict", age_restrict);
    formDatastore.append(
      "related_videos",
      JSON.stringify(
        selectedOptionsRelated_videos?.map((option) => option?.value)
      )
    );
    formDatastore.append(
      "video_playlist_id",
      JSON.stringify(
        selectedOptionsvideo_playlist?.map((option) => option?.value)
      )
    );
    // formDatastore.append(
    //   "video_playlist_id",
    //   JSON.stringify(
    //     selectedOptionsvideo_playlist?.map((option) => option?.value)
    //   )
    // );

    formDatastore.append("Recommendation", Recommendation);
    formDatastore.append("country", country);
    formDatastore.append("publish_status", publish_status ? parseInt(publish_status, 10) : 0);
    formDatastore.set("publish_type", publishType);

    formDatastore.append("skip_start_time", userTime?.skip_start_time);
    formDatastore.append("skip_end_time", userTime?.skip_end_time);
    formDatastore.append("skip_start_session", userTime?.skip_start_session);
    formDatastore.append("recap_start_time", userTime?.recap_start_time);
    formDatastore.append("recap_end_time", userTime?.recap_end_time);
    formDatastore.append("recap_start_session", userTime?.recap_start_session);

    formDatastore.append("url_linktym", userTime?.url_linktym);
    formDatastore.set("publish_time", publishTime);
    formDatastore.set("publish_type", publishType);

    formDatastore.append("pdf_files", pdf_files);
    formDatastore.append("reels_thumbnail", reels_thumbnail);
    const enableVideoTitleImageValue = Number.isNaN(parseInt(enable_video_title_imagedata, 10)) ? 0 : parseInt(enable_video_title_imagedata, 10);
    formDatastore.append("enable_video_title_image", enableVideoTitleImageValue);
    // formDatastore.append(
    //   "enable_video_title_image",
    //   enable_video_title_image ? parseInt(enable_video_title_image, 10) : 0);
    formDatastore.append("reelvideo", reelvideo);
    formDatastore.append("url", url);
    formDatastore.append("url_link", url_link);
    formDatastore.append("url_linksec", url_linksec);
    formDatastore.append("urlEnd_linksec", urlEnd_linksec);
    formDatastore.append("trailer_type", trailer_type);
    formDatastore.append("trailer", selectedFiles);
    formDatastore.append("trailer_description", trailer_description);
    formDatastore.append("ads_status", ads_status);
    formDatastore.append("free_duration_status", editUser?.free_duration_status ? parseInt(editUser?.free_duration_status, 10) : 0);
    formDatastore.append("free_duration_time", editUser?.free_duration_time);
    formDatastore.append("default_ads", default_ads);
    formDatastore.append("pre_ads_category", pre_ads_category);
    formDatastore.append("mid_ads_category", mid_ads_category);
    formDatastore.append("post_ads_category", post_ads_category);
    formDatastore.append("pre_ads", pre_ads);
    formDatastore.append("mid_ads", mid_ads);
    formDatastore.append("post_ads", post_ads);
    formDatastore.append("time_zone", time_zone);
    formDatastore.append("home_genre", home_genre);
    formDatastore.append("in_menu", in_menu);
    formDatastore.append("uploaded_by", uploaded_by);
    formDatastore.append("user_id", user_id);
    formDatastore.append("ads_tag_url_id", ads_tag_url_id ? parseInt(ads_tag_url_id, 10) : 0);
    formDatastore.append("related_videos_title", 1);
    formDatastore.append("draft", status ? parseInt(status, 10) : 1);
    formDatastore.append("ads_position", selectedAdsPosition?.value);
    formDatastore.append("live_ads", secondSelectOptions?.value);
    formDatastore.append("draft", 1);

    let firstApiStatus = false;
    let secondApiStatus = false;
    let thirdApiStatus = false;
    let fourthApiStatus = false;
    let fifthApiStatus = false;
    let sixthApiStatus = false;
    let seventhApiStatus = false;
    let eighthApiStatus = false;
    let ninethApiStatus = false;

    let resultapi = null;
    let resultapifalse = null;
    setProcessing(true);
    setShowOverlay(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-store`,
        formDatastore,
        { headers: headers }
      );

      if (response?.data?.status == true) {
        firstApiStatus = true;

        resultapi = response.data;
        var result = response?.data?.video_id;

        const imageFormData = new FormData();
        imageFormData.append("video_id", video_id);
        if (selectedFile1) {
          imageFormData.append("image", selectedFile1);
        }
        if (selectedFile2) {
          imageFormData.append("player_image", selectedFile2);
        }
        if (selectedFile3) {
          imageFormData.append("video_tv_image", selectedFile3);
        }
        if (selectedFile4) {
          imageFormData.append("video_title_image", selectedFile4);
        }

        const imageResponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-Image-upload`,
          imageFormData,
          { headers: headers }
        );

        const formData1 = new FormData();

        videosreels.forEach((video, index) => {
          formData1.append(`reels_videos[${index}]`, video);
        });

        formData1.append("video_id", video_id);

        const formData1response = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-reels-upload`,
          formData1,
          { headers: headers }
        );

        const formDatasubtitles = new FormData();
        formDatasubtitles.append("video_id", video_id);

        Object.keys(videosubtitles).forEach((language, index) => {
          formDatasubtitles.append(
            `short_code[${index}]`,
            Subtitlesmap[index].short_code
          );
          formDatasubtitles.append(
            `language[${index}]`,
            Subtitlesmap[index].language
          );
          videosubtitles[language].forEach((file) => {
            formDatasubtitles.append(`subtitles[${index}]`, file);
          });
        });

        const formDatasubtitlesresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-Subtitle`,
          formDatasubtitles,
          { headers: headers }
        );

        const formDatareel = new FormData();

        formDatareel.append("video_id", video_id);
        // formData1.append("reels_videos", reelvideo);
        if (selectedFile5) {
          formDatareel.append("reels_thumbnail", selectedFile5);
        }

        const formDatareelresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-reels-thumbnail`,
          formDatareel,
          { headers: headers }
        );

        const formDaEpaper = new FormData();

        formDaEpaper.append("video_id", video_id);
        formDaEpaper.append("pdf_file", pdf_files);

        const formDaEpaperresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-Epaper`,
          formDaEpaper,
          { headers: headers }
        );

        const formDataseo = new FormData();
        formDataseo.append("video_id", video_id);
        formDataseo.append("website_page_title", websitetitle);
        formDataseo.append("website_URL", websiteurl);
        formDataseo.append("Meta_description", metadescription);

        const formDataseoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-SEO`,
          formDataseo,
          { headers: headers }
        );

        const formDatatrailerurl = new FormData();

        formDatatrailerurl.append("video_id", video_id);

        formDatatrailerurl.append("mp4_url", trailer);
        formDatatrailerurl.append("m3u8_trailer", trailer);
        formDatatrailerurl.append("embed_url", trailer);
        formDatatrailerurl.append("trailer_type", trailer_type);

        const formDatatrailerurlresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-Trailer-url`,
          formDatatrailerurl,
          { headers: headers }
        );

        const formDtrailervideo = new FormData();

        formDtrailervideo.append("video_id", video_id);
        formDtrailervideo.append("trailer", selectedFiles);
        formDtrailervideo.append("trailer_filename", trailer_filename);

        const formDtrailervideoresponse = await axios.post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/videos-Trailer-upload`,
          formDtrailervideo,
          { headers: headers }
        );

        if (imageResponse?.data?.status == true) {
          secondApiStatus = true;
        }
        if (formData1response?.data?.status == true) {
          thirdApiStatus = true;
        }
        if (formDatasubtitlesresponse?.data?.status == true) {
          fourthApiStatus = true;
        }
        if (formDatareelresponse?.data?.status == true) {
          fifthApiStatus = true;
        }
        if (formDaEpaperresponse?.data?.status == true) {
          sixthApiStatus = true;
        }
        if (formDataseoresponse?.data?.status == true) {
          seventhApiStatus = true;
        }
        if (formDatatrailerurlresponse?.data?.status == true) {
          eighthApiStatus = true;
        }
        if (formDtrailervideoresponse?.data?.status == true) {
          ninethApiStatus = true;
        }
      } else if (response?.data?.status == false) {
        // Handle the case when the first API response status is false
        resultapifalse = response.data;
      }
    } catch (error) { }

    if (
      firstApiStatus &&
      secondApiStatus &&
      thirdApiStatus &&
      fourthApiStatus &&
      fifthApiStatus &&
      sixthApiStatus &&
      seventhApiStatus &&
      eighthApiStatus &&
      ninethApiStatus
    ) {
      setValidationMessagetitle("");
      setValidationMessagefree_duration_time("");
      setValidationMessageslug("");
      toast.success(t(resultapi?.message));
      setShowOverlay(false);
      setTimeout(async () => {
        await navigate(`/${lang}/channel/video-lists`);
      }, 3000);
    } else if (resultapifalse) {
      toast.error(t(resultapifalse?.message));
      setIsValidationHiddentitle(false);
      setIsValidationHiddenfree_duration_time(false);
      setValidationMessageslug(false);
      setProcessing(false);
      setShowOverlay(false);
    }
  }

  useEffect(() => {
    const fetchAdsPositions = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`,
          {
            headers: headers,
          }
        );

        if (response.ok) {
          const data = await response.json();
          // console.log(data);

          const adsPositionOptions = data.ads_position.map((position) => ({
            value: position.position,
            label: position.name,
          }));
          // console.log(adsPositionOptions);
          setAdsPositions(adsPositionOptions);
        } else {
          throw new Error("Request failed with status: " + response.status);
        }
      } catch (error) {
        console.log("Error fetching ads positions:", error);
      }
    };
    fetchAdsPositions();

    axios
      .get(`${process.env.REACT_APP_Baseurl}/Channel/Backend/video-create`, {
        headers: headers,
      })
      .then((response) => {
        var ads_category = response?.data?.ads_category;
        // setAge_restrictdata(age_restrictdata);
        setPre_ads_category(ads_category);
        setMid_ads_category(ads_category);
        setPost_ads_category(ads_category);

        // console.log(response)
        // console.log(age_restrictdata)
        // console.log(user_access)
        // console.log(ads_category)
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    if (selectedAdsPosition !== "") {
      // console.log(selectedAdsPosition, "adsssssssssss");
      const formData = new FormData();
      formData.append("position", selectedAdsPosition?.value);

      axios

        .post(
          `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-advertisement-tag-url`,
          formData,
          { headers: headers }
        )

        .then((response) => {
          const data = response.data;
          // console.log(data, "exceptioned");

          const adsNames = data.Advertisement.map((position) => ({
            value: position.id,
            label: position.ads_name,
          }));

          setSecondSelectOptions(adsNames);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [selectedAdsPosition]);

  const [enable_restream, setEnable_restream] = useState("");
  const [enablestreamvisible, setEnablestreamvisible] = useState("");
  const [enable_restreamtrailer, setEnable_restreamtrailer] = useState("");
  const [enablestreamvisibletrailer, setEnablestreamvisibletrailer] =
    useState("");

  function proceednext() {
    switch (enable_restream) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="mt-5 mb-5 videoupload">
            <label className=" theme-text-color">Upload Video </label>
            <div className=" text-center file-drag mb-5">
              <label id="label-file-upload" htmlFor="input-file-upload">
                <div
                  name="addvideo"
                  id="addvideo"
                  onChange={(event) => {
                    setEnable_restreamaddvideo(event.target.name);
                  }}
                >
                  <input
                    type="file"
                    accept="video/mp4"
                    id="input-file-upload"
                    name="addvideo"
                    multiple
                    className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                    onChange={handleFileChange}
                    onClick={(event) => {
                      setEnable_restreamaddvideo(event.target.name);
                    }}
                  />

                  {selectedFile && (
                    <div className=" theme-text-color admin-input-title">
                      File Size: {getFileSizeInMB()} MB
                    </div>
                  )}
                  {selectedFile && (
                    <div className=" theme-text-color mt-3">
                      Upload Progress: {uploadProgress}%
                    </div>
                  )}
                </div>
              </label>

              <div
                onClick={(event) => {
                  setEnablestreamvisibleaddvideo(event.target.value);
                }}
              >
                {proceednextaddvideo()}
              </div>
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-3 theme-text-color admin-sub-title"> M3u8 URL </label>
                  <input
                    type="text"
                    className="rs-input form-control-lg theme-bg-color-secondary theme-text-color border-0"
                    name="m3u8_trailer"
                    onChange={(e) => setM3u8_url(e.target.value)}
                    placeholder="m3u8 Trailer"
                  />
                </div>
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNext();
                  }}
                  className="btn theme-button-bg-color admin-button-text "
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-3 admin-sub-title theme-text-color">
                    {" "}
                    Mp4 File URL:{" "}
                  </label>
                  <input
                    type="text"
                    className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                    name="live_mp3_url"
                    placeholder="Mp4 File URL"
                    onChange={(e) => setMp4_url(e.target.value)}
                  />
                </div>
              </div>
              <div className="mt-4 text-center">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                    handleNext();
                  }}
                  className="btn theme-button-bg-color admin-button-text"
                >
                  Proceed to Next
                </Button>
              </div>
            </div>
          </div>
        );
      case "embed":
        return (
          <div>
            <div className="row mt-3 text-start theme-text-color">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-1 theme-text-color"> Embed URL: </label>
                  <div>
                    <p className=" theme-text-color">
                      Example URL Format : (
                      Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                    </p>
                  </div>
                  <input
                    type="text"
                    className="rs-input form-control-lg  theme-bg-color-secondary  theme-text-color border-0"
                    name="embed_trailer"
                    placeholder="Embed Trailer"
                    onChange={(e) => setEmbed_code(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mt-4 text-center">
              <Button
                variant="contained"
                onClick={() => {
                  urlsupload();
                  handleNext();
                }}
                className="btn theme-button-bg-color admin-button-text "
              >
                Proceed to Next
              </Button>
            </div>
          </div>
        );

      default:
        return null;
    }
  }
  const [enable_restreamaddvideo, setEnable_restreamaddvideo] = useState("");
  const [enablestreamvisibleaddvideo, setEnablestreamvisibleaddvideo] =
    useState("");

  function proceednextaddvideo() {
    switch (enable_restreamaddvideo) {
      case "addvideo":
        return (
          <div>
            <div className="mb-12">
              {uploadPercentage > 0 && (
                <div className="row mt-3">
                  <div className="col pt-1">
                    <ProgressBar
                      now={uploadPercentage}
                      striped={true}
                      label={`${uploadPercentage}%`}
                    />
                  </div>
                  <div className="col-auto">
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() => cancelUpload()}
                    >
                      Cancel
                    </span>
                  </div>
                </div>
              )}
            </div>

            <div className="text-center mt-2 mb-5">
              <button onClick={handleNext} className="btn btn-primary">
                Proceed to Next
              </button>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  function proceednexttrailer() {
    switch (enable_restreamtrailer) {
      case "0":
        return <div></div>;
      case "1":
        return (
          <div className="col-12">
            <label className="mt-2 mb-3 theme-text-color admin-input-title">

              Upload Trailer :
            </label>

            <div className="file-draganddrop theme-border-color rs-input">
              <div
                {...getRootProps()}
                className={`mt-3 drop-area ${isDragActive ? "active" : ""}`}
              >
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p className=" theme-text-color admin-input-title">
                    Drop the video file here...
                  </p>
                ) : (
                  <p className=" theme-text-color admin-input-title">
                    Drag and drop your video file here
                  </p>
                )}
              </div>
              {selectedFiles && (
                <div>
                  <p className=" theme-text-color">{selectedFiles.name}</p>
                </div>
              )}
            </div>
          </div>
        );
      case "m3u8_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mt-2 mb-3 admin-input-title theme-text-color">
                    Trailer M3u8 URL
                  </label>
                  <input
                    type="text"
                    className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                    name="mp3_url"
                    onChange={(e) => setTrailer(e.target.value)}
                    placeholder="M3u8 URL"
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "mp4_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mt-2 mb-3 theme-text-color admin-input-title">
                    {" "}
                    Trailer Mp4 File URL:{" "}
                  </label>
                  <input
                    type="text"
                    className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                    name="mp4_trailer"
                    placeholder="mp4 Trailer"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        );
      case "embed_url":
        return (
          <div>
            <div className="row mt-3 text-start">
              <div className="col-sm-12 ">
                <div className="">
                  <label className="mb-3 mt-2 theme-text-color admin-input-title">
                    {" "}
                    Trailer Embed URL:{" "}
                  </label>
                  <input
                    type="text"
                    className="rs-input form-control-lg theme-bg-color-secondary theme-text-color border-0"
                    name="live_mp3_url"
                    placeholder="Embed URL"
                    onChange={(e) => setTrailer(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        );

      default:
        return null;
    }
  }

  const [allliveuseraccess, setallliveuseraccess] = useState("");
  const [storeliveuseraccess, setStoreliveuseraccess] = useState("");

  function alllive_streamuseraccess() {
    switch (allliveuseraccess) {
      case "guest":
        return <div></div>;
      case "subscriber":
        return <div></div>;
      case "registered":
        return <div></div>;
      case "ppv":
        return (
          <div className="">
            <div className="mt-2">
              <label className="mt-2 theme-text-color admin-input-title">PPV Price</label>
              <div className="d-flex align-items-center justify-content-between">
                <p className="theme-text-color mt-2  mb-3  admin-input-description">
                  Apply PPV Price from Global Settings?{" "}
                </p>
                <label className="switch">
                  <input
                    name="ppv_price"
                    defaultChecked={checkboxChecked}
                    onChange={handleCheckboxChange}
                    type="checkbox"
                    className="rs-input theme-bg-color-secondary theme-text-color custom-placeholder"
                  />
                  <span
                    className="slider round"
                    name="ppv_price"
                    checked={checkboxChecked}
                    onChange={handleCheckboxChange}
                  ></span>
                </label>
              </div>
              <div className="panel-body">
                <input
                  type="number"
                  className="rs-input mt-2 theme-bg-color-secondary theme-text-color border-0"
                  placeholder="PPV Price"
                  name="ppv_price"
                  id="price"
                  // onChange={(e) => setPpv_price(e.target.value)}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="mt-2">
              <label className="mt-2 theme-text-color admin-input-title"> IOS PPV Price</label>
              <p className="mt-2  mb-3 theme-text-color opacity-75 admin-input-description ">
                Apply IOS PPV Price from Global Settings?
              </p>
              <div className="panel-body ppv_price_ios  mt-2">
                <select
                  onChange={(e) => setIos_ppv_price(e.target.value)}
                  className="form-select theme-bg-color-secondary theme-text-color border-0"
                  name="ios_ppv_price"
                  id="ios_ppv_price"
                >
                  <option value="" className="theme-bg-color theme-text-color">Choose a PPV Price</option>
                  {inapppurchase?.map((item, key) => (
                    <option value={item.id} className="theme-bg-color theme-text-color">{item.plan_price}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  }

  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadCompleted, setUploadCompleted] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);


  const handleUpload = async (files) => {
    // API endpoint for video upload
    const uploadUrl = `${process.env.REACT_APP_Baseurl}/Channel/Backend/video-upload-file`;

    const uploaders = files.map((file) => {
      const formData = new FormData();
      formData.append("file", file);

      return axios.post(uploadUrl, formData, {
        headers: headers,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(percentCompleted);
        },
      });
    });

    try {
      const responses = await Promise.all(uploaders);

      if (responses[0]?.data?.status == true) {
        responses.forEach((response, index) => {
          // Process the response data for each video
          // console.log(`Response for video ${index + 1}:`, response?.data?.Videos);
          setEditUser(response?.data?.Videos);
          setVideo_id(response?.data?.video_id);
        });

        // Create details about uploaded files
        const uploadedDetails = files.map((file, index) => ({
          name: file.name,
          size: (file.size / (1024 * 1024)).toFixed(2),
        }));

        // Update state and show success alert
        setUploadedFiles(uploadedDetails);
        setUploadCompleted(true);
        setUploadProgress(0);
        // props.setApiresponsealert(responses[0].data);
        // props.setShowalert(true);
        toast.success(t(responses[0]?.data?.message));
      } else {
        // Handle error response
        var resultError = responses[0].data?.message;
        // props.setApiresponsealert(resultError);
        // props.setShowalert(true);
        toast.success(t(resultError));
        setUploadProgress(0);
      }
    } catch (error) {
      // Handle general error case
      // console.error("Error uploading videos:", error);
    }
  };

  const resetUploadState = () => {
    setUploadProgress(0);
    setUploadCompleted(false);
    setUploadedFiles([]);
  };

  const [timeskip_intro, setTimeskip_intro] = useState("");
  const [formattedTimeskip_intro, setFormattedTimeskip_intro] = useState("");

  const formatTimeskip_intro = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangeskip_intro = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimeskip_intro(input);

    setTimeskip_intro(formattedInput);
    setFormattedTimeskip_intro(formattedInput);
  };

  const [timesintrostarttime, setTimesintrostarttime] = useState("");
  const [formattedTimesintrostarttime, setFormattedTimesintrostarttime] =
    useState("");

  const formatTimesintrostarttime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesintrostarttime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesintrostarttime(input);

    setTimesintrostarttime(formattedInput);
    setFormattedTimesintrostarttime(formattedInput);
  };

  const [timesintroendtime, setTimesintroendtime] = useState("");
  const [formattedTimesintroendtime, setFormattedTimesintroendtime] =
    useState("");

  const formatTimesintroendtime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesintroendtime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesintroendtime(input);

    setTimesintroendtime(formattedInput);
    setFormattedTimesintroendtime(formattedInput);
  };

  const [timesskiprecaptime, setTimesskiprecaptime] = useState("");
  const [formattedTimesskiprecaptime, setFormattedTimesskiprecaptime] =
    useState("");

  const formatTimesskiprecaptime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesskiprecaptime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskiprecaptime(input);

    setTimesskiprecaptime(formattedInput);
    setFormattedTimesskiprecaptime(formattedInput);
  };

  const [timesskipstarttime, setTimesskipstarttime] = useState("");
  const [formattedTimesskipstarttime, setFormattedTimesskipstarttime] =
    useState("");

  const formatTimesskipstarttime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesskipstarttime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskipstarttime(input);

    setTimesskipstarttime(formattedInput);
    setFormattedTimesskipstarttime(formattedInput);
  };

  const [timesskipendtime, setTimesskipendtime] = useState("");
  const [formattedTimesskipendtime, setFormattedTimesskipendtime] =
    useState("");

  const formatTimesskipendtime = (input) => {
    // Remove any non-digit characters from the input
    const digitsOnly = input.replace(/\D/g, "");

    // Extract hours, minutes, and seconds from the input
    const hours = digitsOnly.substring(0, 2);
    const minutes = digitsOnly.substring(2, 4);
    const seconds = digitsOnly.substring(4, 6);

    // Format the time as "HH:MM:SS"
    const formattedTime = `${hours}:${minutes}:${seconds}`;

    return formattedTime;
  };

  const handleInputChangesskipendtime = (event) => {
    const input = event.target.value;
    const formattedInput = formatTimesskipendtime(input);

    setTimesskipendtime(formattedInput);
    setFormattedTimesskipendtime(formattedInput);
  };

  const datadata = [
    {
      value: "add_video",
      label: "Video Upload",
      content: (
        <div>
          <Dropzone
            accept="video/*"
            onDrop={(acceptedFiles) => handleUpload(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone" {...getRootProps()}>
                <input {...getInputProps()} />
                <MessageBox text="Drag and drop video files here, or click to select files" classname="draganddrop theme-text-color theme-border-color" />
              </div>
            )}
          </Dropzone>
          <div className="col-10 mx-auto py-4 ">
            {uploadProgress > 0 && (
              <div>
                <MessageLabel text={`Uploading... ${uploadProgress} %`} />
                <progress value={uploadProgress} max="100" />
              </div>
            )}

            {uploadCompleted && (
              <div className="d-flex align-items-center justify-content-center">
                {uploadedFiles.length > 0 && (
                  <div className="text-start col-6">
                    <MessageLabel text="Uploaded Files:" />
                    <ul className="p-0 m-0">
                      {uploadedFiles.map((file, index) => (
                        <li key={index} className="theme-text-color admin-input-description opacity-75 my-2">
                          {file.name} - {file.size} MB
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="col-6 ">
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn theme-button-bg-color admin-button-text py-2"
                  >
                    <MessageBox text="Proceed to Next" classname='d-block '></MessageBox>
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      ),
    },
    {
      value: "m3u8_url",
      label: "M3u8 URL",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-3 admin-input-title theme-text-color"> M3u8 URL </label>
                <input
                  type="text"
                  className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                  name="m3u8_trailer"
                  onChange={(e) => setM3u8_url(e.target.value)}
                  placeholder="m3u8 Trailer"
                />
              </div>
            </div>

            <div className="mt-4 text-center">
              <div className="">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                  }}
                  className="btn theme-button-bg-color admin-button-text "
                >
                  Submit
                </Button>
                {uploadCompleteddata && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn theme-button-bg-color admin-button-text  ms-3"
                  >
                    Proceed to Next
                  </Button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: "mp4_url",
      label: "Video Mp4",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-3 admin-sub-title theme-text-color"> Mp4 File URL: </label>
                <input
                  type="text"
                  className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                  name="live_mp3_url"
                  placeholder="Mp4 File URL"
                  onChange={(e) => setMp4_url(e.target.value)}
                />
              </div>
            </div>
            <div className="mt-4 text-center">
              <div className="">
                <Button
                  variant="contained"
                  onClick={() => {
                    urlsupload();
                  }}
                  className="btn theme-button-bg-color admin-button-text "
                >
                  Submit
                </Button>
                {uploadCompleteddata && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleNext();
                    }}
                    className="btn theme-button-bg-color admin-button-text "
                  >
                    Proceed to Next
                  </Button>
                )}{" "}
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: "embed",
      label: "Embed Code",
      content: (
        <div>
          <div className="row mt-3 text-start">
            <div className="col-sm-12 ">
              <div className="">
                <label className="mb-3 admin-sub-title theme-text-color"> Embed URL: </label>
                <div>
                  <p className=" mb-3 admin-sub-title theme-text-color">
                    Example URL Format : (
                    Https://Www.Youtube.Com/Embed/*Xxxxxxxxx*/ ){" "}
                  </p>
                </div>
                <input
                  type="text"
                  className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                  name="embed_trailer"
                  placeholder="Embed Trailer"
                  onChange={(e) => setEmbed_code(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mt-4 text-center">
            <div className="">
              <Button
                variant="contained"
                onClick={() => {
                  urlsupload();
                }}
                className="btn theme-button-bg-color admin-button-text "
              >
                Submit
              </Button>
              {uploadCompleteddata && (
                <Button
                  variant="contained"
                  onClick={() => {
                    handleNext();
                  }}
                  className="btn theme-button-bg-color admin-button-text ms-3"
                >
                  Proceed to Next
                </Button>
              )}{" "}
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [selectedOption, setSelectedOption] = useState(datadata[0].value);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const [inputValuedata, setInputValuedata] = useState("");

  const handleInputChangedata = (event) => {
    const newValue = event.target.value;

    // Validate input using a regular expression
    const validInput =
      /^[-+]?(\d+|\d+\.\d*|\d*\.\d+)([eE][-+]?\d+)?[a-zA-Z]*$/.test(newValue);

    if (validInput) {
      setInputValue(newValue);
    }
  };

  const steps = [
    {
      label: "Add New Video",
      description: (
        <div>
          <Wrapper>
            <MessageBox text="Upload Video" classname='theme-text-color d-block admin-title'></MessageBox>
            <div className="row m-0 py-4 justify-content-center ">
              {datadata.map((option) => (
                <div key={option.value} className={`col-md-3 col-sm-6 col-12 mb-2 text-md-center text-start ${selectedOption == option.value ? "active" : ""}`} >
                  <div className="d-flex align-items-center justify-content-center">
                    <input
                      type="radio"
                      value={option.value}
                      checked={selectedOption == option.value}
                      onChange={handleOptionChange}
                      className="radio-option mb-2"
                    />
                    <MessageLabel text={option.label} className="ms-1 " />
                  </div>
                </div>
              ))}
            </div>

            <div className="active-div-content">
              {
                datadata?.find((option) => option.value == selectedOption)
                  ?.content
              }
            </div>
          </Wrapper>

          <div
            onClick={(event) => {
              setEnablestreamvisible(event.target.value);
            }}
          >
            {proceednext()}
          </div>
        </div>
      ),
    },
    {
      label: "Video Details",
      description: (
        <div className="">
          <section className="">
            <AdminSaveHeader heading="Add Video" saveText="Save Video" saveMethod={videoupload} />
            {showOverlay && <ResponseLoader text="Processing" />}
            <div className="row m-0">
              <div className="col-12 col-md-6 ps-md-0">
                <div className="rounded-3 mb-4  theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary  pb-3">Create Video</h6>
                  <div className="mt-3 text-start">
                    <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title"> Title </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Add the Video title in the textbox below
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="title"
                        name="title"
                        className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
                        onChange={handleInput}
                        value={editUser?.title}
                        onKeyDown={handleInputKeyDowntitle}
                        placeholder="Title"
                        onBlur={handleBlurtitle}
                        ref={titleInputRef}
                      />
                    </div>
                    <span className="errorred mt-2 d-block">
                      {!isValidationHiddentitle && (
                        <p>{validationMessagetitle}</p>
                      )}
                    </span>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="mt-2 theme-text-color admin-input-title">Slug</label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">Add the Video slug</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        id="slug"
                        name="slug"
                        className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder  theme-text-color  border-0 mb-3"
                        value={autoFillValuetitle}
                        onChange={handleAutoFillChangetitle}
                        placeholder="slug"
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="mt-2 theme-text-color admin-input-title">
                      Short Description
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Add a short description of the Video below
                    </p>
                    <div className="mt-2 ">
                      <textarea
                        className="rs-input  short-description theme-bg-color-secondary custom-placeholder theme-text-color border-0  mb-3"
                        id="description"
                        name="description"
                        onChange={(e) => setDescription(e.target.value)}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="mt-2 theme-text-color admin-input-title">
                      Video Details, Links, and Info
                    </label>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input theme-bg-color-secondary theme-text-color border-0 mb-3"
                        value={details}
                        onChange={(newContent) =>
                          contentFieldChanaged(newContent)
                        }
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="mt-2 theme-text-color admin-input-title">Duration</label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Enter the Video duration in the following format (Hours
                      : Minutes : Seconds)
                    </p>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="duration"
                        maxLength="8"
                        className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0 mb-3"
                        onChange={handleInputdata}
                        value={editUser?.duration}
                        placeholder="duration"
                      />
                    </div>
                  </div>
                  <div className=" mt-2 text-start">
                    <label className="mt-2 theme-text-color admin-input-title">
                      Publish Year
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Video Released Year
                    </p>
                    <div className="mt-2">
                      <input
                        type="date"
                        name="year"
                        id="year"
                        value={editUser?.year}
                        onChange={handleChangeyear}
                        className="rs-input form-control-lg theme-text-color  theme-bg-color-secondary custom-placeholder border-0"
                        placeholder="Year"
                      />
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 theme-bg-color p-3">
                  <h6 className=" theme-text-color theme-border-bottom-secondary  pb-3">Organize</h6>
                  <div className="mt-3 text-start">
                    <label className="mt-2 theme-text-color admin-input-title">
                      Video Ratings
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      IMDb Ratings 10 out of 10
                    </p>
                    <div className="mb-3">
                      <select
                        className="px-3 py-3 rounded-2 w-100 theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                        id="rating"
                        name="rating"
                        size="lg"
                        onChange={(e) => setRating(e.target.value)}
                      >
                        {/* <option value="0">0</option> */}
                        <option value="1" className="theme-bg-color theme-text-color">1</option>
                        <option value="2" className="theme-bg-color theme-text-color">2</option>
                        <option value="3" className="theme-bg-color theme-text-color">3</option>
                        <option value="4" className="theme-bg-color theme-text-color">4</option>
                        <option value="5" className="theme-bg-color theme-text-color">5</option>
                        <option value="6" className="theme-bg-color theme-text-color">6</option>
                        <option value="7" className="theme-bg-color theme-text-color">7</option>
                        <option value="8" className="theme-bg-color theme-text-color">8</option>
                        <option value="9" className="theme-bg-color theme-text-color">9</option>
                        <option value="10" className="theme-bg-color theme-text-color">10</option>
                      </select>
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="mt-2 theme-text-color admin-input-title">
                      Age Restrict
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Select a Video Age Below
                    </p>
                    <div className="mt-2">
                      <select
                        className="px-3 py-3 rounded-2 w-100 theme-bg-color-secondary  custom-placeholder theme-text-color border-0"
                        id="age_restrict"
                        name="age_restrict"
                        size="lg"
                        onChange={(e) => setAge_restrict(e.target.value)}
                      >
                        <option selected value="" className="theme-bg-color theme-text-color">
                          Choose an Age
                        </option>
                        {age_restrictdata?.map((item) => (
                          <option value={item?.id} className="theme-bg-color theme-text-color">{item?.slug}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="mt-2 theme-text-color admin-input-title">
                      Cast and Crew
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Add artists for the Video below
                    </p>

                    <div className="mt-2 mb-3">
                      <Select
                        options={artists?.map((option) => ({
                          value: option?.id,
                          label: option?.artist_name,
                        }))}
                        className="theme-bg-color-secondary theme-text-color"
                        isMulti
                        value={selectedOptionsartists}
                        onChange={handleSelectChangeartists}
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title">
                      Category
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">Select a Video Category Below</p>
                    <div className="mt-2">
                      <Select
                        options={category?.map((option) => ({
                          value: option?.id,
                          label: option?.name,
                        }))}
                        className="theme-bg-color-secondary custom-placeholder theme-text-color"
                        isMulti
                        value={selectedOptionscategory}
                        onChange={handleSelectChangecategory}
                        ref={categoryInputRef}
                      />
                    </div>
                    <span className="errorred mt-2 d-block">
                      {!isValidationHiddencategory && (
                        <p>{validationMessagecategory}</p>
                      )}
                    </span>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="mt-2 d-block mandatorySimple position-relative theme-text-color admin-input-title">
                      Language
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">Select a Video Language Below</p>
                    <div className="text-dark">
                      <Select
                        options={language?.map((option) => ({
                          value: option?.id,
                          label: option?.name,
                        }))}
                        className="theme-bg-color-secondary  theme-text-color"
                        isMulti
                        value={selectedOptionslanguage}
                        onChange={handleSelectChangelanguage}
                        ref={languageInputRef}
                      />
                    </div>
                    <span className="errorred mt-2 d-block">
                      {!isValidationHiddenlanguage && (
                        <p>{validationMessagelanguage}</p>
                      )}
                    </span>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="mt-2 theme-text-color admin-input-title">
                      Block Country
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Block the Video for Selected Country
                    </p>
                    <div className="mt-2 mb-3">
                      <Select
                        options={countryblock_country?.map((option) => ({
                          value: option?.id,
                          label: option?.country_name,
                        }))}
                        className="theme-bg-color-secondary  custom-placeholder  theme-text-color"
                        isMulti
                        value={selectedOptionsCountryblock_country}
                        onChange={handleSelectChangeCountryblock_country}
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="my-2 theme-text-color  admin-input-title">
                      Available Country
                    </label>
                    <p className=" mb-3 theme-text-color opacity-75 admin-input-description">
                      Block the Audio for Selected Country
                    </p>
                    <div className="mt-2 mb-3">
                      <Select
                        options={available_country?.map((option) => ({
                          value: option?.id,
                          label: option?.country_name,
                        }))}
                        className="theme-bg-color-secondary custom-placeholder theme-text-color"
                        isMulti
                        value={selectedOptionsAvailable_country}
                        onChange={handleSelectChangeAvailable_country}
                      />
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="my-2 theme-text-color  admin-input-title">
                      Related Videos
                    </label>
                    <p className=" mb-3 theme-text-color opacity-75 admin-input-description">
                      Select a Related Video Below
                    </p>
                    <div className="mt-2 mb-3">
                      <Select
                        options={related_videos?.map((option) => ({
                          value: option?.id,
                          label: option?.title,
                        }))}
                        className="theme-bg-color-secondary custom-placeholder theme-text-color"
                        isMulti
                        value={selectedOptionsRelated_videos}
                        onChange={handleSelectChangeRelated_videos}
                      />
                    </div>
                  </div>


                  {/* <div className=" text-start mt-2">
                    <label className="my-2 theme-text-color  admin-input-title">
                      Choose Playlist
                    </label>
                    <p className=" mb-3 theme-text-color opacity-75 admin-input-description">
                      Select a Playlist Below
                    </p>
                    <div className="mt-2 mb-3">
                      <Select
                        options={video_playlist?.map((option) => ({
                          value: option?.id,
                          label: option?.title,
                        }))}
                        className="theme-bg-color-secondary custom-placeholder theme-text-color"
                        isMulti
                        value={selectedOptionsvideo_playlist}
                        onChange={handleSelectChangevideo_playlist}
                      />
                    </div>
                  </div> */}
                </div>

                <div className="rounded-3 mb-4 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary pb-3">
                    Intro Time <small>(Optional)</small>
                  </h6>
                  <div className="row mx-0 mt-3">
                    <div className="col-6 ps-0 ">
                      <div className="text-start mb-2">
                        <label className="theme-text-color mt-2  admin-input-title">
                          Skip Start Time{" "}
                          <small className="opacity-75 d-block my-2">(Please Give In Seconds)</small>
                        </label>
                        <div className="panel-body">
                          <input
                            type="text"
                            value={userTime?.skip_start_time}
                            onChange={handleInputintrotime}
                            placeholder="HH:MM:SS"
                            maxLength="8"
                            className="rs-input form-control-lg theme-bg-color-secondary theme-text-color  custom-placeholder border-0"
                            name="skip_start_time"
                            id="skip_start_time"
                          />
                        </div>
                        <div>
                          <span className="intro_time_error">
                            {errors?.error_skip_start_time}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 ps-0">
                      <div className="text-start mb-2">
                        <label className="theme-text-color mt-2  admin-input-title">
                          Recap Start Time{" "}
                          <small className="opacity-75 d-block my-2">(Please Give In Seconds)</small>
                        </label>

                        <div className="panel-body">
                          <input
                            type="text"
                            value={userTime?.recap_start_time}
                            onChange={handleInputintrotime}
                            placeholder="HH:MM:SS"
                            maxLength="8"
                            className="rs-input form-control-lg theme-bg-color-secondary theme-text-color  custom-placeholder border-0"
                            name="recap_start_time"
                            id="recap_start_time"
                          />
                        </div>
                        <div>
                          <span className="intro_time_error">
                            {errors?.error_recap_start_time}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 ps-0  ">
                      {" "}
                      <div className="text-start mb-2 ">
                        <label className="theme-text-color mt-2  admin-input-title">
                          Skip End Time{" "}
                          <small className="opacity-75 d-block my-2">(Please Give In Seconds)</small>
                        </label>

                        <div className="panel-body">
                          <input
                            type="text"
                            value={userTime?.skip_end_time}
                            onChange={handleInputintrotime}
                            placeholder="HH:MM:SS"
                            maxLength="8"
                            className="rs-input form-control-lg theme-bg-color-secondary theme-text-color  custom-placeholder border-0"
                            name="skip_end_time"
                            id="skip_end_time"
                          />
                        </div>
                        <div>
                          <span className="intro_time_error">
                            {errors?.error_skip_end_time}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 ps-0">
                      <div className="text-start mb-2">
                        <label className="theme-text-color mt-2  admin-input-title">
                          Recap End Time{" "}
                          <small className="opacity-75 d-block my-2">(Please Give In Seconds)</small>
                        </label>

                        <div className="panel-body">
                          <input
                            type="text"
                            maxLength="8"
                            value={userTime?.recap_end_time}
                            onChange={handleInputintrotime}
                            placeholder="HH:MM:SS"
                            className="rs-input form-control-lg theme-bg-color-secondary theme-text-color  custom-placeholder border-0"
                            name="recap_end_time"
                            id="recap_end_time"
                          />
                        </div>
                        <div>
                          <span className="intro_time_error">
                            {errors?.error_recap_end_time}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 ps-0">
                      <div className="text-start mb-2">
                        <label className="theme-text-color mt-2  admin-input-title">
                          Skip Start Session{" "}
                          <small className="opacity-75 d-block my-2">(Please Give In Seconds)</small>
                        </label>

                        <div className="panel-body">
                          <input
                            type="text"
                            maxLength="8"
                            value={userTime?.skip_start_session}
                            onChange={handleInputintrotime}
                            placeholder="HH:MM:SS"
                            className="rs-input form-control-lg theme-bg-color-secondary theme-text-color  custom-placeholder border-0"
                            name="skip_start_session"
                            id="skip_start_session"
                          />
                        </div>
                        <div>
                          <span className="intro_time_error">
                            {errors?.error_skip_start_session}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="col-6 ps-0">
                      <div className="text-start mb-2 ">
                        <label className="theme-text-color mt-2  admin-input-title">
                          Recap Start Session{" "}
                          <small className="opacity-75 d-block my-2">(Please Give In Seconds)</small>
                        </label>

                        <div className="panel-body">
                          <input
                            type="text"
                            maxLength="8"
                            value={userTime?.recap_start_session}
                            onChange={handleInputintrotime}
                            placeholder="HH:MM:SS"
                            className="rs-input form-control-lg theme-bg-color-secondary theme-text-color  custom-placeholder border-0"
                            name="recap_start_session"
                            id="recap_start_session"
                          />
                        </div>
                        <div>
                          <span className="intro_time_error">
                            {errors?.error_recap_start_session}
                          </span>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>


                <div className="rounded-3 mb-4 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary pb-3">Information</h6>
                  <div className=" mt-3 text-start">
                    <label className="mt-2 theme-text-color  admin-input-title">E-Paper</label>
                    <p className="my-2  theme-text-color opacity-75 admin-input-description">
                      (Upload your PDF file)
                    </p>
                    <div className="mt-2 mb-3">
                      <input
                        type="file"
                        className="rs-input  form-control-lg theme-bg-color-secondary border-0 custom-placeholder theme-text-color"
                        name="pdf_file"
                        accept="application/pdf"
                        onChange={(e) => setPdf_files(e.target.files[0])}
                        multiple
                      />
                    </div>
                  </div>

                  <div className="text-start mt-2">
                    <label className="mt-2 theme-text-color  admin-input-title">URL Link</label>
                    <p className=" mb-3 theme-text-color opacity-75 admin-input-description">Select the URL Link</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg theme-bg-color-secondary  custom-placeholder theme-text-color border-0"
                        name="url_link"
                        onChange={(e) => setUrl_link(e.target.value)}
                        id="url_link"
                      />
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="mt-2 theme-text-color admin-input-title">
                      URL Start Time Format{" "}
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">(HH:MM:SS)</p>
                    <div className="mt-2">
                      <input
                        type="text"
                        className="rs-input form-control-lg theme-bg-color-secondary  custom-placeholder theme-text-color border-0"
                        name="url_linktym"
                        maxLength={8}
                        placeholder="HH:MM:SS"
                        value={userTime?.url_linktym}
                        onChange={handleInputintrotime}
                        id="url_linktym"
                      />
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary pb-3">SEO</h6>
                  <div className="mt-3 text-start">
                    <label className="mt-2 theme-text-color  admin-input-title">
                      Website Page Title
                    </label>

                    <div className=" mt-2 mb-3" >
                      <input
                        type="text"
                        className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                        onChange={(e) => setWebsitetitle(e.target.value)}
                        placeholder="Title"
                      />
                    </div>
                  </div>

                  <div className="mt-2 text-start">
                    <label className="mt-2 theme-text-color  admin-input-title">Website URL</label>
                    <div className=" mt-2 mb-3">
                      <input
                        type="text"
                        className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                        onChange={(e) => setWebsiteurl(e.target.value)}
                        placeholder="url"
                      />
                    </div>
                  </div>
                  <div className="mt-2 text-start">
                    <label className="mt-2 theme-text-color  admin-input-title">
                      Meta Description
                    </label>

                    <div className=" mt-2">
                      <textarea
                        type="text"
                        className="rs-input form-control-lg custom-placeholder theme-bg-color-secondary theme-text-color border-0"
                        onChange={(e) => setMetadescription(e.target.value)}
                        placeholder="Description"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              {/* Second Part of Section  */}
              <div className="col-12  col-md-6 ">
                <div className="rounded-3 mb-4 border-0 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary  pb-3">Thumbnails</h6>
                  <div className="text-start mt-2">
                    <label className="mt-3 theme-text-color admin-input-title">
                      Video Thumbnail
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Select the Video image ( 9:16 Ratio or 1080X1920px )
                    </p>
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="col-6 col-sm-6 col-lg-6 p-1">
                          <div className="imagedrop theme-border-color" onClick={() => fileInputRef1.current.click()}>
                            <input
                              type="file"
                              ref={fileInputRef1}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef1,
                                  setSelectedFile1
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6 p-1">
                          <div>
                            {selectedFile1 && (
                              <div className="imagedropcopy  theme-border-color text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile1)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile1)
                                      }
                                      type="button"
                                      className="btn-close  theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="mt-3 theme-text-color  admin-input-title">
                      Player Thumbnail
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Select the Video image ( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="col-6 col-sm-6 col-lg-6 p-1">
                          <div className="imagedrop theme-border-color" onClick={() => fileInputRef2.current.click()}>
                            <input
                              type="file"
                              ref={fileInputRef2}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef2,
                                  setSelectedFile2
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile2 && (
                              <div className="imagedropcopy theme-border-color text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile2)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile2)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className=" text-start mt-2">
                    <label className="mt-3 theme-text-color  admin-input-title">
                      Video TV Thumbnail
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Select the Video image ( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="col-6 col-sm-6 col-lg-6 p-1">
                          <div className="imagedrop theme-border-color" onClick={() => fileInputRef3.current.click()}>

                            <input
                              type="file"
                              ref={fileInputRef3}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef3,
                                  setSelectedFile3
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile3 && (
                              <div className="imagedropcopy theme-border-color text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile3)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile3)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" text-start mt-2">
                    <label className="mt-3 theme-text-color  admin-input-title">
                      Video Title Thumbnail
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Select the Video Title image ( 16:9 Ratio or 1280X720px )
                    </p>
                    <div className="mt-2">
                      <div className="d-flex align-items-center">
                        <div className="col-6 col-sm-6 col-lg-6 p-1">
                          <div className="imagedrop theme-border-color"
                            onClick={() => fileInputRef4.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef4}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef4,
                                  setSelectedFile4
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile4 && (
                              <div className="imagedropcopy  theme-border-color text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile4)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile4)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary pb-3">Trailers Upload</h6>
                  <div className="mt-2 text-start">
                    <label className="mt-2 theme-text-color  admin-input-title">
                      Video Trailer Type
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description ">
                      Upload trailer video in type
                    </p>
                    <div className="mt-2">
                      <select
                        className="form-select theme-bg-color-secondary theme-text-color border-0"
                        name="trailer_type"
                        id="trailer_type"
                        onChange={(e) => setTrailer_type(e.target.value)}
                        onClick={(event) => {
                          setEnable_restreamtrailer(event.target.value);
                        }}
                      >
                        <option value="null" className="theme-bg-color theme-text-color">
                          {" "}
                          Select the Video Trailer Type{" "}
                        </option>
                        <option value="1" className="theme-bg-color theme-text-color">Video Upload </option>
                        <option value="m3u8_url" className="theme-bg-color theme-text-color">M3u8 URL </option>
                        <option value="mp4_url" className="theme-bg-color theme-text-color">Mp4 Url</option>
                        <option value="embed_url" className="theme-bg-color theme-text-color">Embed Code</option>
                      </select>
                    </div>
                  </div>
                  <div className="text-start">
                    <div className="mt-3" onClick={(event) => { setEnablestreamvisibletrailer(event.target.value); }} > {proceednexttrailer()}
                    </div>
                  </div>
                  <div className=" text-start mt-3 ">
                    <label className="theme-text-color admin-input-title">Trailer Description</label>
                    <div className="mt-2">
                      <JoditEditor
                        className="rs-input theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                        value={trailer_description}
                        onChange={(newContent) =>
                          contentFieldChanagedtraildes(newContent)
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 theme-bg-color p-3">
                  <h6 className=" theme-text-color theme-border-bottom-secondary pb-3">Visibility</h6>
                  <div className="text-start mt-3">
                    <label className="mt-2 theme-text-color  admin-input-title">
                      Publish Type
                    </label>
                    <div className="mt-2">
                      <input
                        type="radio"
                        id="publish_now"
                        name="publish_type"
                        value="publish_now"
                        checked={publishType == "publish_now"}
                        onChange={handlePublishTypeChange}
                        className="theme-bg-color-secondary custom-placeholder theme-text-color border-0 "
                      />
                      <label
                        htmlFor="publish_now"
                        className="mt-2 ms-2 theme-text-color admin-input-title"
                      >
                        Publish Now
                      </label>
                    </div>
                    <div className="mt-2">
                      <input
                        type="radio"
                        id="publish_later"
                        name="publish_type"
                        value="publish_later"
                        checked={publishType == "publish_later"}
                        onChange={handlePublishTypeChange}
                      />
                      <label
                        htmlFor="publish_later"
                        className="mt-2 ms-2 theme-text-color admin-input-title "
                      >
                        Publish Later
                      </label>
                      {publishType == "publish_later" && (
                        <div className="mb-3 mt-2">
                          <label className="mt-3 theme-text-color admin-input-title">
                            Publish Time
                          </label>
                          <input
                            type="datetime-local"
                            className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder theme-text-color mt-2"
                            id="publish_time"
                            name="publish_time"
                            value={publishTime}
                            onChange={handlePublishTimeChange}
                          />
                        </div>
                      )}
                    </div>
                    {/* <button onClick={handleFormSubmit}>Submit</button> */}
                  </div>
                </div>
                <div className="rounded-3 mb-4 border-0 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary pb-3">Free Duration</h6>
                  <div className="row mt-3 mx-0">
                    <div className="col-9 ps-0">
                      <p className=" theme-text-color admin-input-title ">
                        Enable Free Duration Status
                      </p>
                    </div>
                    <div className="col-3">
                      <label className="switch">
                        <input
                          name="free_duration_status"
                          id="free_duration_status"
                          onChange={handleInputenabletime}
                          defaultChecked={editUser?.free_duration_status === 1}
                          checked={editUser?.free_duration_status === 1}
                          type="checkbox"
                        />
                        <span
                          className="slider round"
                          id="free_duration_status"
                          name="free_duration_status"
                          value={editUser?.free_duration_status === 1 ? "1" : "0"}
                        ></span>
                      </label>
                    </div>
                    <div className="col-12 ps-0">
                      <label className="mt-2 theme-text-color admin-input-title">
                        Free Duration Time
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="free_duration_time"
                          id="free_duration_time"
                          value={editUser?.free_duration_time}
                          maxLength="8"
                          placeholder="HH:MM:SS"
                          onChange={handleInputdatatime}
                          ref={free_duration_timeInputRef}
                          className="rs-input form-control-lg theme-bg-color-secondary custom-placeholder  theme-text-color border-0"
                        />
                        {!isValidationHiddenfree_duration_time && (
                          <div className="mandatory">
                            {validationMessagefree_duration_time}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rounded-3 mb-4 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary pb-3">Access</h6>
                  <div className="mt-3">
                    <label className="mt-2 theme-text-color  mandatorySimple admin-input-title">
                      User Access
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Who Is Allowed To View This Video ?
                    </p>
                    <select
                      onChange={(e) => setAccess(e.target.value)}
                      className="px-3 py-3 mt-2 rounded-2 w-100 theme-bg-color-secondary custom-placeholder theme-text-color border-0"
                      id="access"
                      name="access"
                      ref={accessInputRef}
                      onClick={(event) => {
                        setallliveuseraccess(event.target.value);
                      }}
                    >
                      <option value="0">Choose an User Access</option>
                      {user_access?.map((item, key) => (
                        <option value={item?.role} className="theme-text-color theme-bg-color" >{item?.name}</option>
                      ))}
                    </select>
                    <div>{alllive_streamuseraccess()}</div>

                    <span className="errorred mt-2 d-block">
                      {!isValidationHiddenAccess && (
                        <p>{validationMessageAccess}</p>
                      )}
                    </span>
                  </div>
                </div>
                <div className="rounded-3 mb-4 border-0 theme-bg-color p-3">
                  <h6 className=" theme-text-color theme-border-bottom-secondary pb-3">Status Settings</h6>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="theme-text-color  admin-input-title ">
                      Enable this video as Featured
                    </p>
                    <label className="switch">
                      <input
                        name="featured"
                        onChange={handleInput}
                        className="rs-input"
                        defaultChecked={
                          featured?.featured == 1 ? true : false
                        }
                        checked={featured?.featured == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="featured"
                        onChange={handleInput}
                        value={featured?.featured == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                  {/* <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="theme-text-color  admin-input-title">Enable this Video</p>
                    <label className="switch">
                      <input
                        name="active"
                        onChange={handleInput}
                        defaultChecked={active?.active == 1 ? true : false}
                        checked={active?.active == 1 ? true : false}
                        className="rs-input"
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="active"
                        onChange={handleInput}
                        value={active?.active == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div> */}
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="theme-text-color  admin-input-title">
                      Enable this Video as Slider
                    </p>

                    <label className="switch">
                      <input
                        name="banner"
                        onChange={handleInput}
                        className="r-input"
                        defaultChecked={banner?.banner == 1 ? true : false}
                        checked={banner?.banner == 1 ? true : false}
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="banner"
                        onChange={handleInput}
                        value={banner?.banner == 1 ? "1" : "0"}
                      ></span>
                    </label>
                  </div>
                  <div className="d-flex align-items-center justify-content-between mt-3">
                    <p className="theme-text-color  admin-input-title">
                      Enable Video Title Thumbnail
                    </p>
                    <label className="switch">
                      <input
                        name="enable_video_title_image"
                        onChange={handleInput}
                        defaultChecked={
                          enable_video_title_image?.enable_video_title_image ==
                            1
                            ? true
                            : false
                        }
                        checked={
                          enable_video_title_image?.enable_video_title_image ==
                            1
                            ? true
                            : false
                        }
                        type="checkbox"
                      />
                      <span
                        className="slider round"
                        name="enable_video_title_image"
                        onChange={handleInput}
                        value={
                          enable_video_title_image?.enable_video_title_image ==
                            1
                            ? "1"
                            : "0"
                        }
                      ></span>
                    </label>
                  </div>
                </div>

                <div className="rounded-3 mb-4 border-0 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary pb-3">
                    Reels <small className="opacity-75">(Optional)</small>
                  </h6>
                  <div className=" mt-3 text-start">
                    <label className="mt-2 admin-input-title theme-text-color">
                      Reels Video
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Select the Reels Video
                    </p>
                    <div className="mt-2 file-draganddrop theme-border-color">
                      <Dropzone
                        onDrop={handleDropreels}
                        accept="video/*"
                        multiple={false}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div {...getRootProps()} className="dropzone">
                            <input {...getInputProps()} />
                            <p className="p-2  theme-text-color">
                              Drag and drop reels videos here
                            </p>
                            {videosreels.length > 0 && (
                              <p className=" theme-text-color">
                                {videosreels.length}{" "}
                                {videosreels.length == 1 ? "file" : "files"}{" "}
                                selected
                              </p>
                            )}
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>

                  <div className="text-start mt-2">
                    <label className="mt-2 admin-input-title theme-text-color">
                      Reels Video Thumbnail
                    </label>
                    <p className="my-2 theme-text-color opacity-75 admin-input-description">
                      Select the Reels Video image ( 16:9 Ratio or 1280X720px)
                    </p>
                    <div className="mt-2">
                      <div className="row">
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div
                            className="imagedrop theme-border-color"
                            onClick={() => fileInputRef5.current.click()}
                          >
                            <input
                              type="file"
                              ref={fileInputRef5}
                              onChange={(event) =>
                                handleFileChangesss(
                                  event,
                                  fileInputRef5,
                                  setSelectedFile5
                                )
                              }
                              accept="image/*"
                              style={{ display: "none" }}
                            />
                            <label className=" theme-text-color">
                              <CameraRetroIcon className=" theme-text-color" />
                            </label>
                          </div>
                        </div>
                        <div className="col-6 col-sm-6 col-lg-6">
                          <div>
                            {selectedFile5 && (
                              <div className="imagedropcopy text-end">
                                <div className="col-lg-12 row p-0 m-0">
                                  <div className=" col-sm-10 col-lg-10 p-0 m-0">
                                    <img
                                      src={URL.createObjectURL(selectedFile5)}
                                      alt="Uploaded"
                                      className="thumbnails-upload-images"
                                    />
                                  </div>
                                  <div
                                    className="col-sm-2 col-lg-2 p-0 m-0"
                                    style={{ alignSelf: "center" }}
                                  >
                                    <button
                                      onClick={() =>
                                        handleDelete(setSelectedFile5)
                                      }
                                      type="button"
                                      className="btn-close theme-text-color"
                                      data-bs-dismiss="modal"
                                      aria-label="Close"
                                    ></button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="rounded-3 mb-4 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary  pb-3 ">Subtitles (srt)</h6>
                  <div className="mt-3">
                    {Subtitlesmap?.map((item, index) => (
                      <div className="mt-2" key={index}>
                        <label className=" theme-text-color admin-input-title">
                          {item.language}
                        </label>
                        <div className="file-draganddrop theme-border-color mt-2">
                          <Dropzone
                            onDrop={(acceptedFiles) =>
                              handleDropsubtitles(
                                item.short_code,
                                acceptedFiles
                              )
                            }
                            accept="video/*"
                            multiple={false}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()} className="dropzone">
                                <input {...getInputProps()} />
                                <p className="p-3 theme-text-color admin-input-title">
                                  Drag and drop subtitles here
                                </p>
                                {videosubtitles[item.short_code]?.length >
                                  0 && (
                                    <p className=" theme-text-color">
                                      {videosubtitles[item?.short_code].length}{" "}
                                      {videosubtitles[item?.short_code]
                                        .length == 1
                                        ? "file"
                                        : "files"}{" "}
                                      selected
                                    </p>
                                  )}
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="rounded-3 mb-4 theme-bg-color p-3">
                  <h6 className="theme-text-color theme-border-bottom-secondary pb-3">Search Tags</h6>
                  <div className="mt-2">
                    <p className="theme-text-color mt-2  admin-input-title">
                      You don't have any search keywords.
                    </p>

                    <div className="mt-2">
                      <TagsInput
                        value={searchTags}
                        className="rs-input theme-text-color theme-bg-color-secondary custom-placeholder border-0"
                        onChange={handleTagsChange}
                        name="search_tags"
                      />
                    </div>
                  </div>
                </div>

              </div>
              <div className="text-end mb-3 col-12 d-flex justify-content-end">
                <button onClick={videoupload} className="btn theme-button-bg-color admin-button-text">
                  Save Video
                </button>
              </div>
            </div>
          </section>
        </div >
      ),
    },
    {
      label: "Category",
      description: <></>,
    },
    {
      label: "User Video Access",
      description: <></>,
    },
    {
      label: "Upload Image & Trailer",
      description: <></>,
    },
    {
      label: "ADS Management",
      description: <></>,
    },
  ];

  return (
    <Box className="stepLineNone addvideo">
      <Stepper
        className="video"
        activeStep={activeStep}
        nonLinear
      >
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepContent>
              <Typography>{step.description}</Typography>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep == steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>
            All steps completed - you&apos;re finished
          </Typography>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
    </Box>
  );
}
